import React from 'react';
import { useEffect, useState } from 'react';
import { Maybe } from '../types';

export const useAudio = (url: Maybe<string>) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  function toggle() {
    setPlaying(!playing);
    return void 0;
  }

  useEffect(() => {
      playing ? audio.play() : audio.pause();
    },
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};