import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#002e30',
    },
    secondary: {
      main: '#454545',
    },
    error: {
      main: '#e30b17',
    },
    warning: {
      main: '#dc5800',
    },
    info: {
      main: '#3e77b0',
    },
    success: {
      main: '#25ae88',
    },
    text: {
      secondary: '#454545',
      primary: '#002e30',
      disabled: '#a9aaa9',
    },
    background: {
      default: '#FFFFFF',
      paper: '#fff',
    },
  },
  typography: {
    h1: {
      fontSize: '1.25rem',
      lineHeight: '1.6',
      fontFamily: 'Arial',
      fontWeight: 500,
    },
    h3: {
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Arial',
      fontWeight: 700,
    },
    h4: {
      fontSize: '32px',
      lineHeight: '40px',
      fontFamily: 'Sofia Pro',
      fontWeight: 700,
    },
    h5: {
      fontSize: '24px',
      lineHeight: '32px',
      fontFamily: 'Sofia Pro',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '20px',
      lineHeight: '28px',
      fontFamily: 'Sofia Pro',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '15px',
      lineHeight: '20px',
      fontFamily: 'Sofia Pro',
      fontWeight: 500,
    },
    body1: {
      fontSize: '16px',
      lineHeight: '29px',
      fontFamily: 'Arial',
      fontWeight: 300,
    },
    button: {
      fontSize: '14px',
      lineHeight: '18px',
      textTransform: 'capitalize',
      fontFamily: 'Sofia Pro',
      fontWeight: 600,
    },
    body2: {
      fontSize: '12px',
      lineHeight: '28px',
      fontFamily: 'Arial',
      fontWeight: 300,
    },
    caption: {
      fontSize: '12px',
      lineHeight: '20px',
      fontFamily: 'Sofia Pro',
      fontWeight: 250,
    },
  },
});