import React, { MouseEventHandler, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { isUndefined, map, includes, get } from 'lodash';
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Snackbar,
  Tooltip,
  Typography
} from '@mui/material';
import { Link, PlayArrow, Telegram, Pause, Palette } from '@mui/icons-material';

import { AUTHOR, PoemAd, PoemContent } from 'src/services/resources';
import { Maybe } from 'src/types';
import { theme } from 'src/styles/theme';
import { composePoemLink, composeTitle, composeTwitterContent, utf8format } from 'src/utils/content';
import { openUrl, copyUrl } from 'src/utils/window';
import { useAudio } from '../../../hooks/audioHook';
import { EMPTY_ELEMENT } from 'src/utils/react';
import { ModalContext } from '../../../context/ModalContext';
import { AdLaDart } from '../../ad/la-dart';
import { TransitionProps } from '@mui/material/transitions';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: '100%',
    overflowY: 'auto',
  },
  title: {
    paddingLeft: '70px',
  },
  stanzaList: {

  },
  stanza: {
    paddingBottom: '20px',
    '&:last-child': {
      paddingBottom: '0',
    },
  },
  stanzaRow: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
    },
  },
  epilogue: {
    display: 'flex',
    flexDirection: 'row',
  },
  shareTelegram: {
    marginTop: "-4px !important",
    marginLeft: "4px !important",
  },
  shareLink: {
    marginTop: '-4px !important',
  },
  divider: {
    width: '50%',
    marginLeft: '0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0',
    },
  },
  dialogContent: {
    paddingBottom: '0 !important',
  },
  dialogActions: {
    fontFamily: 'Arial !important',
    // marginTop: '-20px',
    marginRight: '30px',
  },
  dialogActionsButton: {
    fontFamily: 'Arial !important',
    fontSize: '17px !important',
  },
});

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {
  title: Maybe<string>;
  content: PoemContent;
  date: Date;
  code: string;
  locale: string;
  url: Maybe<string>;
  ad: Maybe<PoemAd>;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const emptyTitle = (
  <Typography variant={'body1'}>***</Typography>
);

const USER_ACTION_TIMEOUT = 2000;

export const PoemContentSection: React.FC<Props> = ({
  content,
  title,
  date,
  code,
  locale,
  url,
  ad,
}) => {
  const classes = useStyles();

  const containerPadding = 150; // @todo: calculate container padding

  const [playing, toggle] = useAudio(url);

  const { handleOpen, open, setBody } = useContext(ModalContext);

  const [copyOpen, setCopyOpen] = useState(false);
  const [isTwitted, setTwitted] = useState(false);
  const [adDialogOpen, setAdDialogOpen] = useState<boolean>(false);

  /**
   * Composed poem title
   */
  const poemTitle = !isUndefined(title) ? title : composeTitle(content);

  return (
    <div className={classes.root}>
      <>
        <Dialog
          open={adDialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setAdDialogOpen(false)}
          aria-describedby="Instagram la_dart"
        >
          <DialogContent className={classes.dialogContent}>
            <AdLaDart link={ad ? ad.link : void 0} />
          </DialogContent>
        </Dialog>
      </>
      <Grid
        container
        className={classes.container}
        sx={{ paddingLeft: `calc(50% - ${containerPadding}px)`, }}
      >
        <Grid item xs={12}>
          <div className={classes.title}>
            {
              isUndefined(title)
                ? emptyTitle
                : <Typography variant={'body1'} sx={{ color: 'text.secondary' }}>{title}</Typography>
            }
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.stanzaList}>
            {map(content, (stanzaContent: string[], key: number) => {
              const stanza = map(stanzaContent, (row: string, rowKey: number) => {
                return (
                  <Typography
                    variant={'body1'}
                    sx={{ color: 'text.secondary' }}
                    className={classes.stanzaRow}
                    key={rowKey}
                  >
                    {row}
                  </Typography>
                );
              });
              return (
                <Grid item className={classes.stanza} xs={12} key={key}>
                  {stanza}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.epilogue}>
            <div>
              <Typography variant={'body1'} sx={{ color: 'text.secondary' }}>
                {AUTHOR}, {date.getFullYear()}
              </Typography>
            </div>
            <div>
              <Tooltip
                title={"Share"}
                arrow
              >
                <IconButton
                  className={classes.shareTelegram}
                  onClick={() => {
                    openUrl(`https://t.me/share/url?url=${encodeURIComponent(composePoemLink(code))}&text=${`${isUndefined(title) ? composeTitle(content) : title}${encodeURIComponent('\n')}Etc.`}`);
                  }}
                  aria-label={isUndefined(title) ? composeTitle(content) : title}
                >
                  <Telegram />
                </IconButton>
              </Tooltip>
            </div>
            <div>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={copyOpen}
                ContentProps={{
                  sx: {
                    display: 'block',
                    textAlign: "center"
                  }
                }}
                TransitionComponent={(props) =>
                  <Slide {...props} direction="down" />}
                message={
                  includes(locale, 'en')
                    ? 'Poem link is copied...'
                    : "Ссылка на стихотворение скопирована..."
                }
                key={code}
              />
              <Tooltip
                title={copyOpen ? "Copied" : "Poem link"}
                arrow
              >
                <IconButton
                  className={classes.shareLink}
                  onClick={() => {
                    copyUrl(composePoemLink(code));
                    setCopyOpen(true);
                    setTimeout(() => {
                      setCopyOpen(false);
                    }, USER_ACTION_TIMEOUT);
                  }}
                  aria-label={'copy'}
                >
                  <Link />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={isTwitted ? "Twitted" : "Share Poem"}
                arrow
              >
                <IconButton
                  className={classes.shareLink}
                  onClick={() => {
                    setTwitted(true);
                    openUrl(
                      `https://twitter.com/intent/tweet?text=${utf8format(composeTwitterContent(poemTitle))}&url=${utf8format(composePoemLink(code))}&hashtags=etc,life,science,poetry&via=etcsci`
                    );
                    setTimeout(() => {
                      setTwitted(false);
                    }, USER_ACTION_TIMEOUT);
                  }}
                  aria-label={'tweet'}
                >
                  <img
                    src={'/img/twitter.png'}
                    height={20}
                    width={20}
                    alt={`Twitter ${poemTitle}`}
                  />
                </IconButton>
              </Tooltip>
              {
                !isUndefined(url)
                  ? (
                    <Tooltip
                      title={playing ? 'Stop' : 'Play'}
                      arrow
                    >
                      <IconButton
                        className={classes.shareLink}
                        onClick={toggle as MouseEventHandler<any>}
                        aria-label={'play'}
                      >
                        {playing ? <Pause /> : <PlayArrow />}
                      </IconButton>
                    </Tooltip>
                  )
                  : EMPTY_ELEMENT
              }
              {
                !isUndefined(ad)
                  ? (
                    <Tooltip
                      title={'See art'}
                      arrow
                    >
                      <IconButton
                        className={classes.shareLink}
                        onClick={() => {
                          // console.log('ad: ', ad.link);
                          // setBody(ad ? <AdLaDart link={ad.link}/> : EMPTY_ELEMENT);
                          // handleOpen()
                          setAdDialogOpen(true);
                        }}
                        aria-label={'art'}
                      >
                        {<Palette />}
                      </IconButton>
                    </Tooltip>
                  )
                  : EMPTY_ELEMENT
              }
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}