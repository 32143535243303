import { join, split } from 'lodash';

import { COMMA, SPACE } from './common';

const DEFAULT_LOCALE = 'en-US';

export function localeDate(date: Date, locale: string = navigator.language): string {
  const defaultLocalDate = date.toLocaleDateString(
    DEFAULT_LOCALE,
    {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  const currentLocaleDate = date.toLocaleDateString(
    locale,
    {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  const enDateFormat = join([
    split(currentLocaleDate, `${COMMA}${SPACE}`)[1],
    split(currentLocaleDate, `${COMMA}${SPACE}`)[2]
  ], `${COMMA}${SPACE}`);
  const ruDateFormat = split(currentLocaleDate, `${COMMA}${SPACE}`)[1];

  // @note: compose the full list formatter through the list of format locales
  const dateFormat: string = {
    ['en-US']: enDateFormat,
    ['en-AU']: ruDateFormat,
    ['ru-RU']: ruDateFormat,
  }[locale] || defaultLocalDate;

  return dateFormat;
}