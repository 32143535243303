import React, { Context, createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compact, concat, get, join, lowerCase, map, merge, noop } from 'lodash';

import { EMPTY_STRING, SPACE } from 'src/utils/common';
import { Poem, POEM_LIST, Uid } from 'src/services/resources';

export const DEFAULT_POEM_YEAR = 2021;

export type Expand = { [uid: Uid]: boolean };

type PoemContextValue = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  list: Poem[];
  search: string;
  setSearch: (search: string) => void;
  year: number;
  setYear: (year: number) => void;
  isExpanded: Expand,
  expand: (code: Uid) => void;
  setExpanded: (expand: Expand) => void;
};

export const PoemContext: Context<PoemContextValue> =
  createContext<PoemContextValue>({
    loading: false,
    setLoading: (loading: boolean) => void 0,
    list: [],
    search: EMPTY_STRING,
    setSearch: (search: string) => void 0,
    year: DEFAULT_POEM_YEAR,
    setYear: (year: number) => void 0,
    isExpanded: {},
    expand: (code: Uid) => void 0,
    setExpanded: (expand: Expand) => void 0,
  });

export const PoemProvider: React.FC<any> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>(EMPTY_STRING);
  const [list, setList] = useState<Poem[]>(POEM_LIST);
  const [year, setYear] = useState<number>(DEFAULT_POEM_YEAR);
  const [isExpanded, setExpanded] = useState<Expand>({});

  function expand(code: Uid) {
    setExpanded(merge(isExpanded, {
      [code]: !get(isExpanded, 'code', false),
    }));
    // setExpanded({
    //   [code]: !get(isExpanded, 'code', false),
    // });
  }

  useEffect(() => {
    setExpanded({});
    const poemList = map(POEM_LIST, (poem: Poem, key: number) => {
      const contentValue = join(
        concat(
          map(
            get(poem, 'content', []),
            (stanza: string[], stanzaKey: number) => join(stanza, SPACE)
          ),
          get(poem, 'title', void 0),
        ),
        SPACE
      );
      const searchPosition = contentValue
        .search(new RegExp(`${lowerCase(search)}`, 'i'));
      return searchPosition === -1 ? void 0 : poem;
    });
    setList(compact(poemList));
  }, [search]);

  useEffect(() => {
    setList(POEM_LIST);
  }, []);

  return (
    <PoemContext.Provider value={{
      loading,
      setLoading,
      list,
      search,
      year,
      setYear,
      setSearch,
      expand,
      isExpanded,
      setExpanded,
    }}>
      {children}
    </PoemContext.Provider>
  );
};

PoemProvider.propTypes = {
  children: PropTypes.oneOf([
    PropTypes.any,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};
