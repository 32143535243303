import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, LinearProgress, Typography } from '@mui/material';

import { config } from 'src/config';
import { theme } from 'src/styles/theme';

import { Search } from './search';
import { EMPTY_ELEMENT } from 'src/utils/react';
import { PoemContext } from 'src/context/PoemContext';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'fixed',
    width: '100%',
    zIndex: '10',
    background: '#fff',
  },
  loading: {
    position: 'fixed',
    top: 0,
    width: '100%',
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  title: {
    paddingTop: '10px',
    '&:link': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '&:visited': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '&:active': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  search: {
    paddingLeft: '20px',
    // paddingBottom: '20px',
    // background: theme.palette.primary.main,
    // background: `linear-gradient(180deg, rgba(0,46,48,1) 0%, rgba(0,0,0,0) 50%, rgba(255,255,255,1) 100%)`,
    // backgroundPosition: '5px 5px, 0 0',
    width: '100%',
  }
});

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export const Header: React.FC<Props> = () => {
  const classes = useStyles();

  const { loading } = useContext(PoemContext);

  return (
    <div className={classes.root}>
      <div className={classes.loading}>
        { loading ? <LinearProgress /> : EMPTY_ELEMENT }
      </div>
      <Grid container sx={{ padding: 0 }}>
        <Grid item xs={12} md={12}>
          <div className={classes.logo}>
            <a
              href={`${config.protocol}://www.${config.domain}`}
              className={classes.title}
            >
              <Typography variant={'h1'}>
                Etc.
              </Typography>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className={classes.search}>
            <Search />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}