import React, { useContext, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@mui/styles';
import {
  eq,
  find,
  findKey,
  first,
  get,
  isNull,
  isUndefined,
  map,
  orderBy,
  replace,
  size,
  split,
  toNumber
} from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { ExpandMore, Instagram, Tag, Twitter } from '@mui/icons-material';

import { config } from 'src/config';
import { PoemContext } from 'src/context/PoemContext';
import { Poem, Uid, PoemContent } from 'src/services/resources';
import { COMMA, EMPTY_ARRAY, EMPTY_BLOCK, EMPTY_STRING, HASH, SPACE } from 'src/utils/common';
import { Maybe, Nullable } from 'src/types';
import { theme } from 'src/styles/theme';

import { PoemContentSection } from './poem-content-section';
import { ModalContext } from '../../context/ModalContext';
import { composeTitle } from '../../utils/content';
import { localeDate } from '../../utils/date';
import { openUrl } from '../../utils/window';
import { color } from '../../styles/color';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    paddingTop: '105px',
    flexDirection: 'column',
    width: '100%',
    position: 'absolute',
  },
  accordion: {
    width: '100%',
  },
  accordionExpanded: {
    // margin: '0 auto !important',
  },
  accordionSummaryExpanded: {
    borderBottom: `1px solid ${theme.palette.primary.main} !important`,
  },
  dateSection: {
    height: '21px',
    [theme.breakpoints.down('sm')]: {
      height: '20px',
    },
  },
  dateValue: {
    marginTop: '-3px !important',
  },
  emptySection: {
    textAlign: 'center',
    marginLeft: '10px !important',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
  },
  summaryContainer: {
    paddingLeft: '4px',
  },
  actionItem: {
    position: 'absolute',
    top: '-8px',
    right: 14,
    [theme.breakpoints.down('sm')]: {
      top: '-15px',
      right: '5px',
    }
  },
  actionGrid: {
    position: 'relative',
  }
})

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export const PoemSection: React.FC<Props> = () => {
  const classes = useStyles();

  const { list, isExpanded, expand } = useContext(PoemContext);
  const { setBody, handleOpen } = useContext(ModalContext);

  const accordionRef = useRef<Nullable<HTMLDivElement>[]>(EMPTY_ARRAY);

  function sortList(list: Poem[]) {
    return orderBy(list, [({ date }: Poem) => date], 'desc');
  }

  const emptySection = (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={'body2'} className={classes.emptySection}>
          Empty...
        </Typography>
      </Grid>
    </Grid>
  );

  function getHashUid(): Maybe<Uid> {
    const hash = window.location.hash;
    return !isUndefined(hash)
      ? get(find(list, ({ code }: Poem) => eq(code, replace(hash, HASH, EMPTY_STRING))), 'uid', void 0)
      : void 0;
  }

  useEffect(() => {
    const hashUid = getHashUid();
    if (!isUndefined(hashUid)) {
      const key = sortList(list).findIndex(({ uid }: Poem) => eq(uid, hashUid));
      if (!isUndefined(key) && !isNull(key)) {
        const element = accordionRef.current[key];
        if (!isNull(element)) {
          const scrollTop = element.offsetTop;
          window.scrollTo({
            top: scrollTop - 100,
          });
        }
      }
    }
  }, []);

  return (
    <div className={classes.root}>
      {eq(size(list), 0) ? emptySection : EMPTY_BLOCK }
      {map(sortList(list), ({ uid, title, content, date, code, locale, url, ad, twitter }: Poem, key: number) => {
        const hashUid = getHashUid();
        return (
          <Accordion
            disableGutters
            ref={(element: Nullable<HTMLDivElement>) => accordionRef.current[key] = element}
            defaultExpanded={!isUndefined(hashUid) && eq(uid, hashUid)}
            expanded={isExpanded[uid]}
            onChange={(event: React.SyntheticEvent, _: boolean) => {
              if (!eq(get(event, 'target.innerText', void 0), HASH)) {
                expand(uid);
              }
            }}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              id={uid}
            >
              <Grid
                container
                className={classes.summaryContainer}
              >
                <Grid item xs={12} sm={4}>
                  <div className={classes.title}>
                    <Typography sx={{ color: !isUndefined(twitter) ? color.twitter : 'text.primary' }} variant={'h3'}>
                      {isUndefined(title) ? composeTitle(content) : title}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} className={classes.actionGrid}>
                  <Stack
                    style={{ marginRight: '20px', padding: '5px 0' }}
                    direction="row"
                    spacing={5}
                  >
                    {
                      !isUndefined(get(ad, 'link', void 0))
                        ? (
                          <div className={classes.actionItem}>
                            <Tooltip title={'See art'}>
                              <IconButton
                                onClick={() => {
                                  const link = ad ? ad.link : void 0;
                                  if (link) {
                                    openUrl(link)
                                  }
                                }}
                              >
                                <Instagram />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )
                        : void 0
                    }
                    {
                      !isUndefined(get(twitter, 'id', void 0))
                        ? (
                          <div className={classes.actionItem}>
                            <Tooltip title={'Twitter Announce'}>
                              <IconButton
                                onClick={() => {
                                  const link = twitter
                                    ? `https://twitter.com/etcsci/status/${twitter.id}?s=20`
                                    : void 0;
                                  if (link) {
                                    openUrl(link)
                                  }
                                }}
                              >
                                <Twitter />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )
                        : void 0
                    }
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.dateSection}>
                  <Typography
                    sx={{ color: 'text.secondary' }}
                    variant={'body2'}
                    className={classes.dateValue}
                  >
                    {localeDate(date, locale)}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <PoemContentSection
                title={title}
                content={content}
                date={date}
                code={code}
                locale={locale}
                url={url}
                ad={ad}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}