import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { theme } from '../../../styles/theme';
import { openUrl } from '../../../utils/window';
import { composePoemLink, composeTitle } from '../../../utils/content';
import { isUndefined } from 'lodash';
import { Instagram, Telegram } from '@mui/icons-material';
import { Maybe } from '../../../types';

const useStyle = makeStyles({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  adBlock: {
    color: '#fff',
    padding: '3px',
    fontWeight: 'normal',
    fontSize: '10px !important',
    background: '#002e3082',
    fontFamily: 'Arial !important',
    lineHeight: '13px !important',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
    borderTop: '1px solid #ccc',
    paddingBottom: '20px',
  },
  image: {
    height: '80%',
    width: '100%',
    cursor: 'pointer',
  },
  imageGrid: {
    height: '100%',
  },
  contactGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '-30px !important',
    borderBottom: '1px solid #002e30',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-20px !important',
    },
  },
  contact: {},
})

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {
  link?: Maybe<string>;
};

export const AdLaDart: React.FC<Props> = ({ link }) => {
  const classes = useStyle();

  const tmpLink = 'https://www.instagram.com/la_dart';

  function openInstagram(link: string) {
    if (!isUndefined(link)) {
      openUrl(link)
    }
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>

        </Grid>
        <Grid item xs={12} className={classes.imageGrid}>
          <Tooltip
            title={'Click to see art...'}
            arrow
            followCursor
          >
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img
              src={'/img/ad-la-dart.jpeg'}
              alt={'la_dart'}
              className={classes.image}
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              onClick={() => openInstagram(tmpLink)}
              onKeyPress={() => openInstagram(tmpLink)}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={12} className={classes.contactGrid}>
          <Tooltip
            title={'Instagram'}
            arrow
          >
            <Link href={tmpLink} target={'_blank'}>
              <IconButton
                className={classes.contact}
                onClick={() => openInstagram(tmpLink)}
              >
                <Instagram />
              </IconButton>
            </Link>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.footer}>
            <Typography variant={'body1'} className={classes.adBlock}>
              Not an Ad
            </Typography>
          </div>
        </Grid>

      </Grid>
    </div>
  );
}