import { size } from 'lodash';

import { config } from 'src/config';
import { PoemContent } from 'src/services/resources';

export function composePoemLink(hash: string): string {
  return `${config.protocol}://www.${config.domain}#${hash}`;
}

export function composeTitle(content: PoemContent): string {
  const nativeTitle = content[0][0];
  const cleanTitleEnd = nativeTitle[size(nativeTitle) - 1]
    .replace(/[.,\/#$%\^&\*;:{}=\-_`~()]/g,''); // !
  return nativeTitle.slice(0, -1) + cleanTitleEnd;
}

/**
 * Twitter Poem Content
 * @param title
 */
export function composeTwitterContent(title: string): string {
  return `Hey! Read a new Etc. poem!\r\n${title}`;
}

/**
 * Format string UTF-8
 * @param str
 */
export function utf8format(str: string): string {
  return encodeURIComponent(str);
}