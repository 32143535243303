import { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Maybe } from 'src/types';
import { AdLaDart } from '../../components/ad/la-dart';

export const AUTHOR = 'Etc.';

export type Uid = string;
export type PoemContent = string[][];

export type PoemAd = {
  link: string;
  content: ReactElement
};

export type Twitter = {
  id: string;
};

export type Poem = {
  title: Maybe<string>;
  content: PoemContent;
  uid: Uid;
  date: Date;
  code: string;
  locale: string;
  url?: Maybe<string>;
  ad?: Maybe<PoemAd>;
  twitter?: Maybe<Twitter>;
}

export const POEM_LIST: Poem[] = [
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2019, 2, 12),
    code: 'stupaem-po-zemljam-kultur-neizvestnyh',
    content: [
      [
        'Ступаем по землям культур неизвестных',
        'Вливаясь в игристую песню людей.',
        'Какими мы были вчера интересно?',
        'И влились в четверку новых друзей!',
      ],
      [
        'Театр, искусство мы любим напрасно.',
        'Напрасно для всех кто его не видал.',
        'Природа колышет леса неподвластно…',
        'Немецкий актер нам вчера рассказал.',
      ],
      [
        'И гул самолета нам шепчет под солнцем',
        'Друзья навсегда… Замолкает на миг.',
        'На звездах летим мы во сне вслед за горцем!',
        'Мой дом это там, где ты рядом. Возник.',
      ]
    ]
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2019, 10, 10),
    code: 'znanija-v-vechnosti-shagajut-nelepo',
    content: [
      [
        'Знания в вечности шагают нелепо',
        'Не зная что делать среди нас людей.',
        'Да забей ты на них, отдохни ты под небом,',
        'Где живет столько добрых, насущных идей.',

      ],
    ]
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2019, 6, 12),
    code: 'vsegda-idem-my-za-mechtami',
    content: [
      [
        'Всегда идем мы за мечтами,',
        'Какие нас не ждут ни дня.',
        'И вот увидел меж цветами',
        'Я солнце розового дня.',
      ],
      [
        'Никто не ждал в день изменения,',
        'Никто не видел нас тогда…',
        'Напало чувство невезения',
        'Сквозь призрак завтрашнего дня.',
      ],
      [
        'Повсюду в рань вдруг появился',
        'Весенний сон. Пришла тайга,',
        'Где я вдруг тут же очутился,',
        'Уж не расскажет мне она.',
      ],
      [
        'Враз пляшет мудрая лисица.',
        'Пусть радуга в ночи полна.',
        'Где, с кем же в день мне веселиться?',
        'Она потухла. Не гроза...',
      ],
      [
        'А вьюга, пестрая снежинка',
        'Всем дарит полюс, времена.',
        'Друг, где же очутиться,',
        'Что б обняла она меня.',
      ],
    ]
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2019, 11, 15),
    code: 'proletaet-skvoz-zanaves-chutkaja-pesnja',
    content: [
      [
        'Пролетает сквозь занавес чуткая песня…',
        'Забвения дней ты в дневник запиши...',
        'Смогу ли найти? Не знаешь ты где я.',
        'Никто не поймет речь тайной души.',
      ],
      [
        'Ну а кто же грустить выбирает в мгновение?',
        'В тот момент когда отдано все госпоже?',
        'Только тот кто не знает своё нетерпение.',
        'Ведь оно пригодится в ночном багаже.',
      ],
      [
        'И когда обнимаю друзей поколения',
        'Уж никто не представит порядок затей.',
        'Что свернули на право, глядя в отражения',
        'Тех забавных девчонок, заветных идей.',
      ],
      [
        'Сколько лет же пройти нам за руку с искусством,',
        'Как в отчаянный миг ритма дружбы детей,',
        'Дабы жизнь обернулась путем, где не грустно.',
        'Ибо мы в эту вечность ждём много гостей.',
      ],
      [
        'Созерцание неба столь о многом заводит',
        'Наш разговор о ведении лет,',
        'Что луна, улыбаясь, мигнет и воротит',
        'Наш дневник пустоты и не знания бед.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2019, 4, 5),
    code: 'kamennyj-vek-my-vstretili-rano',
    content: [
      [
        'Каменный век мы встретили рано,',
        'Не знали тогда и слова Привет.',
        'Но вот уже строятся рядом вокзалы,',
        'За кадром танцует прекрасный балет!',
      ],
      [
        'Уж где времена? Написаны в книгах.',
        'В тех книгах что с детства для нас прочтены.',
        'Кому ж рассказать, что было в мотивах,',
        'Тех песен, что в рань нас сюда привели?',
      ],
      [
        'И вот человек, который не знает,',
        'Что рисовать, что уверенность есть.',
        'Лишь пару недель свою жизнь отрицает.',
        'И вот через год - ля-ля-ля-три-рубля.',
        `Ыц. Эксперт уже здесь.`, // @todo: initial space
      ],
      [
        'Но что я про маленьких, мы ведь дедули.',
        'Поём под окном вот, чайник свистит…',
        'Как закроешь окошко - так все пошатнули.',
        'Уж лучше открою - Пуск повисит.',
      ],
      [
        'Весна за горами, а значит в сознании.',
        'В представлении том, что создание есть.',
        'Dream Team ведь не даром есть напоминание.',
        'О том, что мечтать и есть наша честь.',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2019, 10, 5),
    code: 'sasha-krasavitsa-s-vetrom-guljaet',
    content: [
      [
        'Саша красавица с ветром гуляет',
        'Вдоль прекрасных лугов в ночной тишине.',
        'Со словами той песни  в душе все сияет.',
        'И никто не поймёт тайну гор при луне.',
      ],
      [
        'Саша, красавица, взгляни на ладони',
        'Тех призрачных рук касаясь во мгле.',
        'Не забудет никто тех девиц в унисоне,',
        'Тех прекрасных цветов средь утра на росе.',
      ],
      [
        'Саша, красавица, будь же собою.',
        'Только это даётся таким людям как ты.',
        'И забудь все что было нелепо с тобою.',
        'Всегда была луна. Всегда были мы.',
      ],
      [
        'Саша, кудесница, тот бес за тобою,',
        'Который резвится в часы, в забытие',
        'Лишь упрямится в ночь ответить покою,',
        'Что лишь мы подойдём на перрон в бытие.',
      ],
      [
        'Саша... Красавица... Час... Или вечность.',
        'Мне не важно что думать, с кем общаться в ночи.',
        'Только дай мне завет, что нашу беспечность',
        'Не забудем на миг. Дай дорогу пройти.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2020, 3, 5),
    code: 'kogda-to-vecherom-v-companii-druzej',
    content: [
      [
        'Когда-то вечером в компании друзей',
        'О туризме болтали, впечатлениях лета.',
        'Всем знакомы те чувства когда ты живей,',
        'Но лишь избранные чувствуют это.',
      ],
      [
        'Да про что ты вдруг начал свой рассказ здесь для всех?',
        'Честно, сам же не знаю, вроде про лето.',
        'Так тепло на душе в этой пору утех.',
        'Кто скучает по нем? Здесь нету секрета.',
      ],
      [
        'Как же этой зимой, или прошлой, ты скажешь',
        'Наполниться силой, терпением, улыбкой. И вдруг',
        'Солнце рано, спросонья, ты в миг взбудоражишь,',
        'Никогда не забудешь что рядом твой друг.',
      ],
      [
        'Друзей мы находим раз, два и обчелся.',
        'Помнишь то чувство когда ты нашел?',
        'Друзей? Нет не помню. Но когда ты боролся,',
        'Сумасбродный сюжет режиссер приобрел.',
      ],
      [
        'Ну а фильм показали? Увы я не знаю.',
        'Была ли премьера? Я уехал тогда.',
        'Друзья рассказали, что я замечаю',
        'Какая прекрасная лето пора.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2020, 5, 10),
    code: 'napishu-stihotvorenije-o-prekrasnoj-lubvi',
    content: [
      [
        'Напишу стихотворение о прекрасной любви.',
        'Подумал артист и с маху вдруг выпил.',
        'Он забыл что такое кусочки халвы.',
        'Ибо в сказке своей потерялся на сгибе.',
      ],
      [
        'Мы когда уплываем за нашей мечтой.',
        'А мечтой ли зовем? Это мы потом спросим.',
        'Не заметен тот мир что узрел ты впервой',
        'До поры когда счастье мы так ввысь превозносим.',
      ],
      [
        'Камень не видишь? Вон, он же большой!',
        'Да чего сразу камни, вон скалы заметил.',
        'Мы пройдемся по ним, в сказке тронем рукой',
        'Все что мы наживем на дороге столетий.',
      ],
      [
        'Ух парень, артист, а ты все же с нами?',
        'Давно ты читал газеты? Ведь Буш',
        'Стал президентом, с его голосами',
        'Молодой Гор из Теннесси стал неуклюж.',
      ],
      [
        'Газеты я выкинул, на них две снежинки',
        'Напомнили зимний тот вечер, о том,',
        'Что лишь жизнь преподносит нам радость тропинки,',
        'Так прекрасно по ней бежать с ветерком.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2020, 9, 27),
    code: 'rjadom-kto-to-vdrug-prosnulsja',
    content: [
      [
        'Рядом кто-то вдруг проснулся.',
        'Тот, кто миром обманулся.',
        'Не сто лет им изучать,',
        'Как с нуля весь мир начать.',
      ],
      [
        'Мир он длинный, яркий, быстрый.',
        'Это пылкий звон игристый.',
        'Кто же сможет рассказать',
        'Как два кончика связать?',
      ],
      [
        'Этих кончиков мильоны:',
        'Машины, поезда, перроны.',
        'Два ехидных дурака',
        'И блестящая река.',
      ],
      [
        'Та река, что жизнью грезит.',
        'Та, где рыбки ум наш взвесят.',
        'Ты проснись же наконец',
        'И будь учителем, малец.',
      ],
      [
        'Обучить не так уж просто.',
        'Не даром ты удался ростом.',
        'Тем, кто пел всем свысока',
        'Не нужна была река.',
      ],
      [
        'Ну а мы, в плоту игривом',
        'Знаем что нам делать с миром.',
        'Нужно жить, дружить и знать,',
        'Что реку не угадать.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2021, 2, 5),
    code: 'eh',
    content: [
      [
        'Эх…',
        'Запамятные встречи с нею,',
        'В мечтаниях являясь днем,',
        'Я ветром как ячмень рассею',
        'И подожгу бесчувственным огнем.',
      ],
      [
        'И прорастут на землях бесконечных',
        'Ростки заботливой корысти и любви.',
        'Ведь как иначе звездам игр беспечных',
        'Познать о жизни вновь и вспомнить визави?',
      ],
      [
        'Проходит год, за ним еще столетие…',
        'Пылает лес на землях тех в который раз.',
        'Ведь все, что преподносит искр в наследие,',
        'Как не смеялся я, но был лишь это джаз.',
      ],
      [
        'Тот джаз что в мире грез и света',
        'Играет постоянно в сердце у меня.',
        'Ведь та девчонка, что из университета,',
        'Однажды глянула, мою душу пленя.',
      ]
    ],
  },
  {
    title: 'Диалог',
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2021, 2, 4),
    code: 'dialog',
    url:  `${process.env.PUBLIC_URL}/poem.dialog.quick-analysis.wav`,
    content: [
      [
        'Подошел к нему сенсей',
        'И задал вдруг простой вопрос.',
        'Что Вам нравится, Морфей?',
        'Он ответил без угроз.',
      ],
      [
        'Дико нравится общаться',
        'О погоде, временах.',
        'И до сути всем добраться',
        'Будь то в клубах, во дворах.',
      ],
      [
        'Что же вы сидите дома,',
        'Не выходите во двор?',
        'Боюсь я все же тут излома,',
        'Что будет надо мной прикол.',
      ],
      [
        'Прикол лишь в том, что я не правый,',
        'Я не продолжу разговор.',
        'Имеет смысл ли это здравый?',
        'Мухамед Вы ль, Аллах, сеньор?',
      ],
      [
        'Не все так просто, мой учитель...',
        'Ведь мне послушать не дано.',
        'До середины я есть зритель,',
        'Что было дальше - все равно.',
      ],
      [
        'Запомни, Мор - что говорили',
        'И заключает общий смысл.',
        'Чем больше сказано, и стили,',
        'Тем чище твой же чистый лист.',
      ],
      [
        'Секунду, гуру, как же чистым',
        'Ему в века со мною быть?',
        'А ты не будь таким иглистым.',
        'Попробуй все в миг отпустить.',
      ],
      [
        'Забудь про счастье и погоду,',
        'Про ель за домом ты забудь,',
        'Любую ты не трожь невзгоду.',
        'Пройди по мысли тонкий путь...',
      ],
      [
        'Я все так делаю, учитель,',
        'Но не могу сообразить,',
        'Как чувствовать себя как зритель',
        'И автомат остановить.',
      ],
      [
        'Конечно практика поможет.',
        'Но как же нам ее достичь?',
        'Лишь жить всего необходимо может,',
        'А то сейчас ты, Мор, как бич.',
      ],
      [
        'Ну что ты сразу обзываться?',
        'Я задаю же ведь вопрос.',
        'Как можно с миром пообщаться,',
        'Чтоб разум мой чуть-чуть подрос.',
      ],
      [
        'Уж если ты пришел к вопросам,',
        'Что мы с тобою развели,',
        'Забудь что было же с тобою.',
        'Все понял, дальше ты плыви.',
      ],
      [
        'Но помни что не ты лишь в мире',
        'И мыслей просто океан.',
        'Иль космос, сам представь в трактире,',
        'И не войди в самообман.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2021, 8, 8),
    code: 'vot-byl-ja-kak-to-v-odnom-bare-vmeste',
    content: [
      [
        'Вот был я как-то в одном баре вместе',
        'С одним прекрасным человеком лет.',
        'Тех лет, что обрели свой знак на месте',
        'Загадочной загадки - красный цвет.',
      ],
      [
        'Он да, согласен, отвергает все же в миг…',
        'И в незапамятный момент тебя приводит.',
        'Но в те секунды, что прошли для них двоих,',
        'Душа как солнце на рассвет восходит.',
      ],
      [
        'И в поисках той скромной, но разумной Саши,',
        'Несется каждый день как пыль ветров.',
        'А вдруг, она протянет руку с яркой чашей,',
        'Дабы узреть то чудо облаков.',
      ],
      [
        'В которых ты летал все те моменты,',
        'Всё говоря о жизни из чудес.',
        'Но проходили рядом звонкие студенты,',
        'Не понимая, что был я балбес.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2021, 8, 18),
    code: 'prosnuvshis-rano-utrom-v-polden',
    content: [
      [
        'Проснувшись рано утром в полдень,',
        'Я вспомнил, что забыл свой гимн сказать.',
        'Пред ужином свой стиль рассмотрен',
        'Дабы реальность людям показать.',
      ],
      [
        'Так что же твой реальность, брат?',
        'Авган игриво вслух бормочет.',
        'Гадаешь ты ведь наугад,',
        'Дней тикет на всю жизнь просрочен.',
      ],
      [
        'Так я его не брал тогда пред полдень.',
        'Все в миг летело рядом быстро,',
        'Опять же, стиль свой был рассмотрен.',
        'Не зря ж когда-то был магистром.',
      ],
      [
        'И как ты в руки, брат, берешь с собою',
        'То настоящее всю жизнь? Для славы?',
        'Я поиграл бы, дорогой, с тобою.',
        'Но нет такого смысла в абстракции начала.',
      ],
      [
        'И хочешь этим ты сказать, брат солнца мамы,',
        'Игриво, как-то криво улыбаясь в кресле желтом,',
        'Что говорил тогда игриво выдавая ритма гаммы,',
        'Ведь Ты сейчас и есть. Прям вкручен каждый раз, прям болтом.',
      ],
      [
        'Такие брат дела, когда по миру колесил я помню,',
        'Ты как перо рисующее, сканнер в сих планетах.',
        'Лишь сбоку своего болта, опять тебе напомню...',
        'Понятно было в пятом классе. Я не летал в кометах.',
      ],
      [
        'Ну как, понятно? Все дословно, лишь про то, что я',
        'Подумал в миг процесса за рефлексией.',
        'Была задумка в миг моя.',
        'Давай, не опоздай на сессию.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2021, 8, 19),
    code: 'vsem-rasskazy-vpechatlenija-snov',
    content: [
      [
        'Всем рассказы, впечатления снов',
        'Я преподнес вот в тех моментах.',
        'Не оценил лишь все Петров.',
        'Он вычислял все в градиентах.',
      ],
      [
        'Да я шучу, резвясь в полетах красок.',
        'Вот в том прикол, да нечего сказать.',
        'Язык вот слаб в наращивании масок,',
        'Что б весь тот мир картиной показать.',
      ],
      [
        'Расслабься, да и будь ты мире сём',
        'Простым, игривым, интересным.',
        'На завтра будешь ты втроем',
        'Петь песни на сейчас чудесный.',
      ],
      [
        'Ничто, никак ты здесь не можешь',
        'Передавать как электроны грез.',
        'Все то что сделано, как гвоздь заложишь,',
        'Не трогая забытый всеми мигами колхоз.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2021, 5, 6),
    code: 'pishi-stihi-mechtaj-i-zhdi-momenta',
    content: [
      [
        'Пиши стихи, мечтай и жди момента,',
        'Когда напротив будет милый лик,',
        'Когда всплывет в сознании легенда,',
        'Написанная в море книг.',
      ],
      [
        'Да, в честь людей подобных книги пишут,',
        'И замки строили в былые времена.',
        'Философы для них хосты свои испишут,',
        'Дабы представить Вашу жизнь сполна.',
      ],
      [
        'Ведь в ней же явно нет невзгод и сказок,',
        'В которых предают красавицу чудес.',
        'Она полна цветочных лент раскрасок',
        'И милых о любви вечерних пьес.',
      ],
      [
        'И в представлении все будет как по маслу:',
        'Ты как орел взлетишь узря тот жданный лик…',
        'Эх, где же книги те, в которых ты был счастлив,',
        'Ведь человек-герой - прекрасный озорник.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2021, 3, 11),
    code: 'solce-pogoda-teplo-i-veselije',
    content: [
      [
        'Солнце, погода, тепло и веселие',
        'Обретают мотивы встречи друзей.',
        'Так приятно нестись в незабвенном течении,',
        'Гулять по Берлину, узреть Колизей.',
      ],
      [
        'Вот помнишь? Приехал и вмиг растворился',
        'Средь жизни лихих, интересных людей.',
        'Прошло пару дней и ты вдруг очутился',
        'На стыке ветров, бесконечных морей.',
      ],
      [
        'А помнишь как днем не находишь покоя',
        'О том, что не все рядом строят сюжет?',
        'Но вот ты присядешь послушать прибоя,',
        'И дух твой увидит песни всех твоих лет.',
      ],
      [
        'И день начинается ярким рассветом.',
        'Не терпиться броситься просто идти!',
        'Ведь парки, прилавки, бордюры, концерты,',
        'Культуры другие... Встретишь всё на пути.',
      ],
      [
        'Ох да, путешествия, что же с них спросишь?',
        'Смешной ты, зачем же вопрос задаешь?',
        'Живи и резвись как монетку забросишь',
        'В смешной автомат и путь свой найдешь.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2021, 9, 28),
    code: 'the-day-of-automation-science',
    content: [
      [
        'The day of automation science',
        'Comes through the rainbow interaction,',
        'There are the some moments of alliance',
        'Which nobody can set to fraction.',
      ],
      [
        'About what am I tell story…',
        'How can I say, that’s nothing to explain',
        'By just a thinking that there is some glory...',
        'It will repeat, relax, please be a brain.',
      ],
      [
        'Not brain which every person comes through,',
        'Not entity that’ll be endless abstract.',
        'Just live with second of the clue',
        'Of way that is the Universe contract.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2021, 11, 23),
    code: 'merry-christmas-from-the-big-suggestion',
    content: [
      [
        'Merry Christmas from the big suggestion',
        'Comes through the imagination all of us,',
        'But movement has again notification',
        'About schema, that is telling interesting discuss...',
      ],
      [
        'Discussion will be done in year',
        'Of every one of us can speak again.',
        'There is some moments, they are clear,',
        'That have the grade, of course - the ten!',
      ],
      [
        'So, what I mean, hey July, just a progress',
        'Comes through the social interaction week and week.',
        'I wish to be so strong and flex in rock dress',
        'To show what is the real life technique!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2021, 4, 20),
    code: 'vecherom-prosnulsa-ja',
    content: [
      [
        'Вечером проснулся я',
        'И глянул на браслеты.',
        'Представилась вокруг семья,',
        'Играли рядом сеты.',
      ],
      [
        'Задумался в который раз:',
        'А что же есть сознание?',
        'Тот бесконечный наш каркас',
        'Для самооправдания.',
      ],
      [
        'А что вот, если интеллект',
        'Разумным все же будет.',
        'Пройдет немало ярких лет,',
        'И все он позабудет.',
      ],
      [
        'Но как же он забудет всё',
        'Коль цифра бесконечна?',
        'И человек придумает то-сё',
        'И будет знать извечно.',
      ],
      [
        'А как же это интеллекту',
        'Вертеть в загадках бытия?',
        'Или отдать все знания проекту',
        'Где точка зрения своя?',
      ],
      [
        'Неплохо ты здесь рассуждаешь.',
        'Проектом всё назвать могу',
        'Коль цель конечна. Понимаешь?',
        'Иначе я тебе солгу.',
      ],
      [
        'Так что же мы про лист мечтаний?',
        'Вот как бы ты его назвал?',
        'Проект? Душевных созерцаний…',
        'А нет, здесь предложу “Астрал”.',
      ],
      [
        'Астрал из физики берет немного,',
        'Да и от химии черпает чуть.',
        'Нам нужно просто более людского,',
        'Что б дверь загадки распахнуть.',
      ],
      [
        'Берем из общих интересов.',
        'Давай “Искусный интеллект”.',
        'Составь картину из прогрессов,',
        'Но будет там один дефект.',
      ],
      [
        'Ведь невозможно в куче данных',
        'Категоризации достичь.',
        'Лишь можно оценить незваных,',
        'И все ошибки в раз постичь.',
      ],
      [
        'Ведь так и можно научиться,',
        'Отбросив в стороны прогресс,',
        'Коль на ошибках все таиться...',
        'Покличем все это ликбез.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2021, 5, 18),
    code: 'rassuzhdenija-o-nashih-vpechatlenijah',
    content: [
      [
        'Рассуждения о наших впечатлениях',
        'Повсюду вновь картину создают.',
        'И как же нам держать в своих секретах',
        'То, что в нас и так живут.',
      ],
      [
        'Постой-ка, хлопец, мы живые,',
        'И в нас есть жизнь, бактерии зовут.',
        'Но ты сравнения всем внёс лихие,',
        'Как будто целый мир при нас, уют.',
      ],
      [
        'Довольно правильно поставлена задача.',
        'Создание мира лишь с теплом верно.',
        'Верно в том ракурсе, что у тепла отдача.',
        'Ответ заветный всем познать дано.',
      ],
      [
        'Это ты про лето что-то здесь городишь.',
        'В раздумье все немного вширь...',
        'Иль ты меня к тому сюжету и приводишь,',
        'Чтоб подойти к моменту без ненужных гирь?',
      ],
      [
        'А ты со спортом почему сама играешь?',
        'Проехали... О чем я говорю с собой.',
        'Теплом, уютом ты огонь узнаешь',
        'И главное играть с искрой.',
      ],
      [
        'Уж как-то слабо ты мотив свой строишь',
        'Игра с искрою кроет тот момент сейчас.',
        'И ты есть человек, все уровни освоишь,',
        'Как наблюдать за миром, что вокруг, для нас.',
      ],
      [
        'Ага, постой-ка, ты про внешний мир ведёшь рассудок?',
        'Который строится… Картина здесь, сейчас?',
        'И что влияет там вот твой поступок',
        'На то что будет дальше в час?',
      ],
      [
        'Ты верно, знаешь, подмечаешь.',
        'Как ты зовёшь? Планирванье затей?',
        'Стабильные ты акты отмечаешь',
        'Я про этап создания идей.',
      ],
      [
        'Ага, так что же вот они так грубо?',
        'Резвятся только в тот момент сейчас?',
        'Не думаю им классно, я сугубо.',
        'Ведь ты кайфуешь и играешь джаз.',
      ],
      [
        'Действительно, так нету здесь системы...',
        'Есть только то, что происходит здесь, сейчас...',
        'Да, и планирование отчасти смелым',
        'Ибо настанет тот момент прям в час',
      ],
      [
        'Да брось, красавица, здесь нет уж слова сложно,',
        'Ведь каждый в своём мире там летит.',
        'На то и диалог для нас возможность',
        'Нагнать разумный аппетит.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2021, 7, 28),
    code: 'prohodil-kotoryj-god',
    content: [
      [
        'Проходил который год,',
        'Когда рядом нет забвения.',
        'Был выбран истинный подход:',
        'Прогресса мира изучение.',
      ],
      [
        'Да как ты сам определишь',
        'Что в результате будет ярким?',
        'А по ошибкам ты узнаешь лишь,',
        'Не по концовке с пылом жарким.',
      ],
      [
        'И коль ошибки есть эпитет,',
        'Несущий же ответ порой,',
        'То глаз твой заново увидит',
        'То, что пропустил впервой.',
      ],
      [
        'Ты знаешь, я чуть чуть в конфузе...',
        'Мне что, везде нестись ценя?',
        'Ценя ошибки лишь в моей уж музе',
        'И вечером исход браня?',
      ],
      [
        'Исход не трогай, то про Бога,',
        'Про то что не дано познать.',
        'Ты в ночь подумай лишь немного',
        'И сможешь действия узнать.',
      ],
      [
        'Узнать по тем же отражениям,',
        'Тем образам что ты познал',
        'В своём же личном окружении.',
        'Смотришь... И себя узнал.',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2021, 8, 9),
    code: 'razmyshlaja-o-super-cheloveke',
    content: [
      [
        'Размышляя о супер человеке,',
        'Я к выводу в сей миг пришёл,',
        'Что есть усталость в нашем летнем веке',
        'Того, кто всю науку изобрёл.',
      ],
      [
        'Но как остаться может личность?',
        'Ведь неизвестно ты о чем.',
        'Вот в том прикол, что вся публичность',
        'Расскажет вам о том о сём.',
      ],
      [
        'О чем ты бредишь всю дорогу?',
        'Ты лучше вон кувшин купи,',
        'А то устроил здесь такую подоплеку,',
        'Что хоть в лесу ты с ней кричи.',
      ],
      [
        'Так вот же, люди, ведь недаром',
        'Никто не может все понять.',
        'Все спустится как будто паром,',
        'И ты не сможешь разогнать.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 0, 3),
    code: 'dobryj-vecher-pahnet-dramoj',
    content: [
      [
        'Добрый вечер… Пахнет драмой',
        'О низкорослых лошадях.',
        'Украсили их все приправой',
        'Да бы и принесли их на гвоздях.',
      ],
      [
        'Прекрасный ужин, молвил парень,',
        'Перебирая наугад талон',
        'На то, чтобы прекрасный государынь',
        'Включил все же уже шансон.',
      ],
      [
        'Ведь музыка, как мила птаха,',
        'Рассказ ведёт о жизни тех людей,',
        'Что на зарплату не было отваги.',
        'Пойду за рюмку я продам блядей.',
      ],
      [
        'Ох государынь, ох ты человече,',
        'Да расскажи же нам свой истинный секрет!',
        'Отдайте вы себя тому как размышляет грече,',
        'Но не забудь проверить, включен ль интернет.',
      ]
    ],
  },
  {
    title: 'Вопрос',
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 0, 4),
    code: 'vopros',
    content: [
      [
        'Есть один вопрос ой знатный сей Вселенной,',
        'Тот, что каждый разгадать стремится в жизни сей,',
        'Но задать его друг другу существует риск отменный.',
        'Но ты не бойся, ты спроси, посмей.',
      ],
      [
        'Ведь все, что ты узрел в суждениях летом,',
        'Своих суждениях о мире сём',
        'Несут отменный факт и шлют приветом',
        'То, что и описано в моём.',
      ],
      [
        'Я не скажу, что материалы века всем',
        'Несут несказанный предлог продлить разгадки.',
        'Не строил я там всяких красочных блок-схем',
        'Ведь изначально это лишь догадки…',
      ],
      [
        'Догадки до тех фактов окружения,',
        'Что внутренний, что внешний ты смотри.',
        'Несут ведь истинный характер рассуждения,',
        'Чтобы понять - ты все свое сотри.',
      ],
      [
        'Сотри не в тех лихих значениях мира же сего,',
        'Где пропадает то, что ты так строил,',
        'Так несся ты лишь только уж вперед,',
        'И ведь казалось ты всю жизнь освоил.',
      ],
      [
        'Так вот тебе скажу я лишь однажды,',
        'Когда наш диалог зайдет для нас.',
        'На что ответишь, ведь всё это дважды.',
        'А я тебе скажу одно лишь слово - ты сейчас.',
      ],
      [
        'Тогда, возможно, в твоих мыслях дерзких',
        'Проснется лишь росток корней,',
        'Которые несут в себе загадки светских,',
        'Но с объяснением твоих же, брат, затей.',
      ],
      [
        'Затей о данном мире счастья и единства,',
        'Затей объединения мира же сего.',
        'Не строй лишь всякого бесчинства.',
        'Послушай ты без реплики всего.',
      ],
      [
        'Ведь реплики, что вставлены как гвозди,',
        'Порочат искренний мотив загадки века сих же лет.',
        'Ты просто позови друзей уж в гости',
        'И спой загадочный, доброжелательный куплет.',
      ],
      [
        'Куплет о жизни в данной, брат, Вселенной,',
        'Вот в том пространстве, что дано познать.',
        'Все остальное так и называется, да, сценой,',
        'Да бы себя как личность показать.',
      ],
      [
        'И ты мне скажешь, так а ты что делаешь прям в данный миг,',
        'Ты хочешь мне сказать что этим ты не занят?',
        'В ответ услышишь ты лишь радости приятный, зрительский уж крик',
        'Такие как и я, да, хулиганят.',
      ],
      [
        'Ведь радость невозможно удержать в предплечье.',
        'Она во всем, что происходит здесь, сейчас.',
        'В одно мгновение я расправил плечи',
        'И попросил уж фото прям в анфас.',
      ],
      [
        'Неважно что ты делаешь в мгновение мира же сего',
        'Оно несет мотив, и ты, будь здрав, поверь мне в это',
        'Ты не изменишь то, как мир хотела поменять Марго',
        'Я ж говорил, подумай о суждениях летом',
      ],
      [
        'Ах время… Доносится суждение с дальнего стола.',
        'Я подойду и приобняв шепну на ухо.',
        'Ты знаешь, как ты верно здесь подобрала.',
        'Об этом даже знает та же муха.',
      ],
      [
        'Да, это классика в толковых знаниях Вселенной',
        'Ведь каждый хочет все познать.',
        'Глянь все же время на запястье летом',
        'И постарайся все признать.',
      ],
      [
        'Признать, что данном мире, что в вокруг тебя',
        'Куда не кинь все есть реальность.',
        'Почувствуй в ней же ты себя...',
        'Забудь, все это лишь формальность.',
      ],
      [
        'Формаль, я выражусь, в движении диалога',
        'Лишь только в данном направлении бытия:',
        'Все что ты знаешь криво, прямо, с боку',
        'Все совпадает с символом жития.',
      ],
      [
        'А символ это лишь стремление.',
        'Все остальное ты придумай сам.',
        'Раскрой свое же самовыражение,',
        'И мир научит чудесам.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 0, 15),
    code: 'neskazannyj-predlog-neset-zagadki-veka',
    content: [
      [
        'Несказанный предлог несет загадки века',
        'О небывалых и прекрасных снах,',
        'В которых те глаза смешного человека',
        'Дают понять весь мир в мечтах.',
      ],
      [
        'Ведь те миры из грез сердечных',
        'Несут разгадки небывалой красоты,',
        'В которых нарисованы два лика безупречных,',
        'Но почему-то приоткрыты их зонты.',
      ],
      [
        'Ведь столько общих представлений мира',
        'Лишь настигают в этот день всех вас.',
        'Вкуси, попробуй как листы Шекспира,',
        'Создай все в том же образе, сейчас.',
      ],
      [
        'Твой зонт начнет шептать, как-то виляя',
        'Своим блаженным изобилием снов,',
        'Начнет, уж тихо дивный мир твой расширяя,',
        'Подмигивать цветам изящных облаков.',
      ],
      [
        'И дождь, что был в округе милых всем гостей,',
        'С секретом в образы людей, что подле с радостю проник…',
        'Но та девчонка, что была для всех милей,',
        'Лишь подмигнула и улыбнулась от интриг.',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 0, 12),
    code: 'na-samom-dele-sushestvujet',
    content: [
      [
        'На самом деле существует…',
        'Донесся сонный чей-то глас.',
        'Вот что-то явно согласует',
        'То, что и дано для нас.',
      ],
      [
        'Вот именно в таком абстрактном смысле',
        'И существует миллион понятий.',
        'Мол кто-то для кого-то как чародей читает мысли…',
        'Но все зависит лишь от восприятий.',
      ],
      [
        'Тех восприятий что даны от Бога',
        'Или от космоса - Вселенной ты зови.',
        'Ведь не хватает нам всем просто диалога',
        'О той лишь самой уж любви.',
      ],
      [
        'Любви ко всем, кто в данном мире существует,',
        'Но не творит уж всяких непонятных дел',
        'После которых он опять же согласует…',
        'Концы с концами, смотришь - постарел.',
      ],
      [
        'А в старости рассказы всем в округе',
        'Он преподносит лишь для мига и для нас,',
        'Чтобы как радуга цвета узрел в заслуге,',
        'Но жизнь его прошла уж криво, он погас.',
      ],
      [
        'Так вот желаю я для будущих понятий,',
        'Понятий в Вашей голове,',
        'Быть в верном ракурсе всех восприятий,',
        'Творить добро в том волшебстве.',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2021, 9, 25),
    code: 'kogda-rano-prosypajus',
    content: [
      [
        'Когда рано просыпаюсь,',
        'И глаза чуть-чуть открыв,',
        'В данный мир я окунаюсь',
        'Про вчерашний день забыв.',
      ],
      [
        'Погоди, но как Исторья',
        'Тебе тогда подмиг прислал?',
        'Ведь я тогда лишь на задворье',
        'Слово жизнь во всю красу сплял.',
      ],
      [
        'Все сейчас лишь только летом,',
        'Или осенью, сейчас?',
        'Этот мир нам шлёт приветом',
        'То, что забирает в нас.',
      ],
      [
        'Так он, серьезный этот парень,',
        'Ещё и руки развязал?',
        'Скажу я, брат, он легендарен.',
        'Вселенной я его назвал.',
      ],
      [
        'Так что, же он в нас забирает?',
        'Расскажешь ты же мне сейчас!',
        'Что в этот миг нам он же дарит',
        'Три мига, восхищения час…',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 1, 10),
    code: 'vot-to-chto-v-dannyi-mig-tvoritsja',
    content: [
      [
        'Вот то, что в данный миг творится',
        'Вокруг тебя в изысках, предвкушениях,',
        'Есть то, что в разуме твоем таится,',
        'В картине мира, проявлениях…',
      ],
      [
        'Твоих же собственных затей, желаниях,',
        'Лишь обрести все знания планет.',
        'Ты сам не видишь это всё в молчаниях',
        'Людей вокруг на протяжении многих лет.',
      ],
      [
        'Но каждый сам того не зная,',
        'Стремится вдаль согласно знаниям миров.',
        'Уж как то я назвал, весь мир считая,',
        'Собранием несказанных грехов.',
      ],
      [
        'Но в самом деле все так просто, парень,',
        'Или ты девушка, лишь в миг смотри.',
        'Придай себе же обаяния',
        'И выиграй свое же ты пари.',
      ],
      [
        'Пари, что завтра, скажешь ты в упреке,',
        'Все будет лучше чем сейчас…',
        'Ну все, подруга, всем привет и чмоки!',
        'Ты пропустила все тотчас.',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 1, 11),
    code: 'volnitelnyi-moment-ja-nachinaju',
    content: [
      [
        'Волнительный момент… Я начинаю.',
        'Не знаю даже что же здесь сказать.',
        'Но все придёт, ведь ты же размышляешь,',
        'Не говори мол Бог тебе указкой должен показать.',
      ],
      [
        'Ведь ты же уже начал ход своей же мысли.',
        'Она идёт и разветвляется в обед.',
        'Все потому как в воздухе с тобой зависли',
        'Те размышления вне грёз бесед.',
      ],
      [
        'Ведь тот ржаной, по вкусу ярко вспоминая,',
        'Раскрепощен в объятиях мира твоего.',
        'И вот Италия для всех как слог внедряя,',
        'Мечта единства, добивается всего.',
      ],
      [
        'Ведь гость и называется по сути добрым.',
        'У всех народов гость есть как кумир бытия.',
        'Для сей минуты, поверь мне, он подобран',
        'Дабы петь песни твоего жития.',
      ],
      [
        'Жития неисполнимого желания века,',
        'Которое лишь мчится меж дубов проклятых лет.',
        'Но в настоящем есть два ярких человека…',
        'Вопроса не было… Не будет и ответ.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 0, 29),
    code: 'chto-skazat-podumal-paren-utrom',
    content: [
      [
        'Что сказать, подумал парень утром,',
        'И постарался воссоздать',
        'Свой образ, что считал он мудрым,',
        'Но остался лишь дремать.',
      ],
      [
        'Дремать ведь утром так прекрасно,',
        'Когда нет нужных уж забот,',
        'И сам себе ты бредишь гласно',
        'Да бы все стало как тот слог - наоборот.',
      ],
      [
        'Ведь как-то слабо представляя мир,',
        'Ты в силах лишь стремиться к мигу.',
        'Тому, который не протер тебя до дыр,',
        'А лишь придумал для тебя интригу.',
      ],
      [
        'Она лишь выражается изгибом',
        'В твоих понятиях бытия,',
        'Которые ты изобрел всё с тем же прототипом,',
        'Да бы и объяснить весь смысл жития.',
      ],
      [
        'Так все ведь так и есть, как говорил ты в прошлом.',
        'Проснись, подумай и сложи лишь два плюс два.',
        'А то, как парень, что ходил лишь в позапрошлом,',
        'Не мог понять весь мир учась едва.',
      ],
      [
        'Так вот при тех прекрасных мыслях,',
        'Что пролетают в Вашей голове,',
        'Составь картину из потребных смыслов,',
        'Очнись и будь ты в торжестве!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 0, 14),
    code: 'nikogda-ty-mne-ne-veril',
    content: [
      [
        'Никогда ты мне не верил!',
        'Донёсся как печаль тот крик.',
        'Содрогаясь он в себя поверил,',
        'Всё говорил он для двоих.',
      ],
      [
        'Так каждый может рассуждая',
        'К себе как сказка подойти,',
        'Но что он точно прогадает,',
        'То ожидает на его пути.',
      ],
      [
        'Пути длиною пять столетий,',
        'В которых будут вспоминать,',
        'Как он лепил цветки соцветий,',
        'Да бы урок той даме преподать.',
      ],
      [
        'Он как учитель нарядился ночью,',
        'Рассказ он начал не свет не заря…',
        'Но вдруг разбила его в миг на клочья',
        'Сигаретку, подмигнув ух мило, прикуря.',
      ],
      [
        'Дорогой, ты как младенец утром,',
        'Что плачет дабы братика позвать.',
        'Найди свой слог во всем премудром',
        'Дабы любовь мне показать.',
      ],
      [
        'Подумал, стоя у окна в ночи,',
        'Вообразил картину в сновидениях,',
        'И вдруг он в миг разоблачил',
        'Все то, что и нашёл в значениях.',
      ],
      [
        'Значениях мира же сего тем летом,',
        'Когда цветут вокруг все липы и цветы,',
        'И выразил одним лихим куплетом,',
        'В котором звал ее на ты.',
      ],
      [
        'Забудь что было летом иль весною,',
        'Все это рассуждения снов.',
        'Поверь, ведь ты пойдешь гулять со мною,',
        'Я покажу тебе всех дураков.',
      ],
      [
        'Опять ты в сказку углубился',
        'Мой дорогой, присядь ты рядом уж со мною,',
        'Не в деньгах дело, мне приснился',
        'Ответ. Я назвала его едою.',
      ],
      [
        'Едой не той, что люди к завтраку приносят',
        'В вагоне что до Питера идёт.',
        'Еда вся та, что вклад свой вносит',
        'Для всех людей. Вперёд зовёт.',
      ],
      [
        'Понапридумают те дамы откровений,',
        'Дабы мужчину гордо мастерить.',
        'Хотя… Здесь здравый смысл тех рассуждений,',
        'Всем интересно лишь друг друга подразнить.',
      ],
    ],
  },
  // {
  //   title: void 0,
  //   uid: uuidv4(),
  //   locale: 'ru-RU',
  //   date: new Date(2022, 0, 21),
  //   code: 'na-rassvete-prosypajas-bodro',
  //   content: [
  //     [
  //       'На рассвете просыпаясь бодро',
  //       'Сей человек не мог понять,',
  //       'Что весь выбор предусмотрен,',
  //       'Но осталось все решать.',
  //     ],
  //     [
  //       'Что решать коль предусмотрен',
  //       'Весь твой путь на сей же день?',
  //       'Пусть твой шаг будет осмотрен,',
  //       'Пусть не будет тебе лень.',
  //     ],
  //     [
  //       'Лень анализа стремления,',
  //       'Лень красы всего вокруг.',
  //       'И получишь удивления,',
  //       'Нарисуешь полукруг.',
  //     ],
  //     [
  //       'Все что он лишь означает',
  //       'В эти миги красоты,',
  //       'Красоты души игривой',
  //       'Есть Вселенная как ты.',
  //     ],
  //     [
  //       'Но той части не хватает',
  //       'Что порой рассказ ведет...',
  //       'О заветном дух узнает,',
  //       'Силу, сказку обретет.',
  //     ],
  //   ],
  // },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 2, 23),
    code: 'vot-vstretil-ja-v-kofeine-lady',
    content: [
      [
        'Вот встретил я в кофейне леди,',
        'Что говорила так, как я смотрю,',
        'Смотрю на мир с его наследием,',
        'И создавал я мельком ту зарю.',
      ],
      [
        'Рассказы длились, рассуждения,',
        'О тех бескрайних смыслах наших дней.',
        'Вы знаете, и это было то стремление,',
        'Про что я говорил в своих же ракурсах затей.',
      ],
      [
        'Так вот о чем я все же здесь поведать',
        'Хочу дабы весь смысл передать,',
        'Так то, что независимых культур проведать',
        'Всегда прекрасно, что бы смысл сказать.',
      ],
      [
        'Ведь смысл лишь будет заключаться',
        'В общении, что Аристотель говорил.',
        'Все что вокруг него лишь будет изучаться…',
        'Ну все, свои слова я завершил.',
      ],
      [
        'Ведь те идеи на восходе солнца',
        'Передают три тысячи цветных лучей,',
        'Что светят на душе, где все сольется',
        'В единый ракурс общих всем идей.',
      ],
    ],
  },
  {
    title: 'Джигит',
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 3, 1),
    code: 'jigit',
    content: [
      [
        'Интересно. Интересно…',
        'Как-то я вдруг поманил',
        'Свои догадки для прогресса…',
        'Я где-то ценность удалил.',
      ],
      [
        'Ведь ценность незабытых мигов',
        'Хранит обзор лихих коней,',
        'Что наш Есенин описал для стыков',
        'Своих же, брат, несобранных идей.',
      ],
      [
        'Но вот когда ты начинаешь стыки',
        'Сводить между собою в грань,',
        'То всё равно хранятся все загадки в миге,',
        'Что за тобою, вот сейчас. Застань…',
      ],
      [
        'Своих приятелей за данным рассуждением,',
        'В котором победитель будет брит…',
        'Ведь всё, поверь мне, милый со своим значением,',
        'Проснется в сердце, где поёт джигит.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 3, 17),
    code: 'koroche-gusi-nochevali',
    content: [
      [
        'Короче, гуси ночевали',
        'И танцы в своём сне плясали.',
        'Никто им всем рассказ не вёл,',
        'Но вдруг Володька кепку сплёл.',
      ],
      [
        'Она смотрелась очнь игриво,',
        'Как сказочной лошадки грива,',
        'Что развевалась с ветерком,',
        'Отображая всем тот гимн тайком.',
      ],
      [
        'Ведь никому та грива не расскажет,',
        'Что есть волшебность в дымной саже.',
        'Ведь сажу Вовка очнь любил,',
        'И сажу в ручки он вложил.',
      ],
      [
        'Потом помылся Вовка близко',
        'К одной девице, взяв ириску.',
        'Потряс рукою вправо, вдаль.',
        'Эх кепка! Прочитай ты всем мораль.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 3, 4),
    code: 'den-rozhdenia-tolko-raz-v-godu',
    content: [
      [
        'День рождения только раз в году.',
        'И эти даты забывают',
        'Лишь пообщавшись раз в бреду.',
        'Все это просто называют…',
      ],
      [
        'Тем самым непонятным делом,',
        'Что лишь для нескольких людей',
        'Начертано на том заборе мелом',
        'Дабы позвать безудержных друзей.',
      ],
      [
        'Но ты же впредь уже не видишь,',
        'Как пролетает милый звездолет,',
        'Лишь сквозь века ты все увидишь',
        'О том, что именно твоя душа поет.',
      ],
      [
        'Но я не знаю этой песни буйной,',
        'Да и не знал ведь ты сама жила.',
        'Лишь будь ты дамою разумной',
        'И пусть б вся жизнь тебя в зарю вела.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 3, 1),
    code: 'vasilkov-i-dostoevsky',
    content: [
      [
        'Васильков и Достоевский',
        'Несравнимые персоны…',
        'Вдруг раздался голос детский.',
        'Заметны рядом все перроны.',
      ],
      [
        'Вот кто такой ваш Васильков?',
        'Ты с кем сравнить его позволил?',
        'Ведь он всего как тот Петров,',
        'Что музыке дары изволил.',
      ],
      [
        'Ага, вы не ослышались, ребята,',
        'Подарки слуху он подносит.',
        'Мол так и нужно, будь проклята',
        'Она сама… Лишь звук уносит...',
      ],
      [
        'Тем хулиганом за просторы дней',
        'И, думая о мире в том единстве,',
        'Когда все станут своим разумом умней,',
        'И танец резвый не найдут по-украински.',
      ],
      [
        'Ведь только слово танец, глядя',
        'На мир вокруг с игривым удивлением,',
        'Все выражает, как тот милый дядя',
        'Забыл, что значит Достоевский.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 2, 26),
    code: 'dzu-privet-kak-mir-povsjudu',
    content: [
      [
        'Юль, привет! Как мир повсюду?',
        'Тот мир что видишь ты с изыском бытия?',
        'Ты знаешь, мне сказали как простому люду,',
        'Мол мне не стоит всех учить смея…',
      ],
      [
        'Ведь просто мало кто что понимает…',
        'Вот ты скажи: как просто социум постичь?',
        'Здесь ключевое слово «просто» отнимает',
        'Всю ту загадку, что ведет свой клич.',
      ],
      [
        'Весь клич лишь заключается в манере',
        'Как ты с людьми тот самый диалог ведешь.',
        'Да просто повернись, да и скажи ты все Валере,',
        'И путь искристых реплик поведешь.',
      ],
      [
        'И реплики все те могут достигнуть',
        'Глубоких размышлений мироздания дней.',
        'На что твой собеседник все жаждет подхихикнуть',
        'Ибо не знает всех простых вещей.',
      ],
      [
        'Ой понесло меня для общества повсюду',
        'Столь много неизвестных точек приносить,',
        'Когда изобретаю я для общества свою валюту',
        'Да бы за строку сто тыщ графиков чертить.',
      ],
      [
        'Так вот в тех чертежах будет изведан',
        'Весь смысл той одной строки.',
        'Заключена она в словах, где культ изведан.',
        'Что есть культура, к чему протянута твоя рука зари?',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 3, 28),
    code: 'ty-znaesh-gde-to-samolet-letaet',
    content: [
      [
        'Ты знаешь, где-то самолет летает,',
        'В котором люди эликсир куют.',
        'Ведь да, поверь мне, он из стали,',
        'Но почему-то всем несет уют…',
      ],
      [
        'И образ у него такой игривый,',
        'Как северный и красочный медведь,',
        'Который средь своих больн далеко фальшивый,',
        'Но будет он как сказочный кузнец звенеть…',
      ],
      [
        'Лишь призывая к людям небоскребы мыслей,',
        'В которых так приятно у камина посидеть,',
        'И пообщаться, вновь найти инакомыслий,',
        'Узреть и в небо к радуге лететь!',
      ],
      [
        'И в облаках, где-то в дали, среди забвений',
        'Ведь можно и пилота разглядеть,',
        'Который с добрым взглядом всех значений',
        'Протянет молот кузнеца узреть.',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 4, 4),
    code: 'prohodja-vdol-treh-parnei',
    content: [
      [
        'Проходя вдоль трёх парней,',
        'Ты как лозунг хитрый проворонишь',
        'Все что ты так строил среди явных дней,',
        'От себя же ты возьмёшь, уронишь.',
      ],
      [
        'А лихие парни все же будут наблюдать',
        'Как ты вновь да встрял в своей загадке.',
        'Ведь так изящно в ней же можно танц плясать',
        'И все по прежнему идти к разгадке.',
      ],
      [
        'Сокрывается она среди ночей,',
        'Тех ночей что пред рассветом, зная',
        'Будешь сподвигать ты ракурсы цветных затей,',
        'Взглядом космонавта как кошка исчезая.',
      ],
      [
        'Но вот природа все же даст поговорить',
        'О беглом предрассудке края.',
        'Лишь помни, что в философии нельзя забыть',
        'Того, где не стоит вверх запятая.',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 5, 3),
    code: 'v-golove-net-mysley-chto-podelat',
    content: [
      [
        'В голове нет мыслей. Что поделать…',
        'Такой вот день сегодня в час',
        'Тот наступил да бы ведь новое изведать,',
        'И я пишу весь этот стих сейчас.',
      ],
      [
        'Что рассказать? Подумал я вот в то мгновение,',
        'Когда прилег в своей же комнате на плед.',
        'Давайте будет стих про то мышление,',
        'Которое несет прогресс для наших лет.',
      ],
      [
        'Но как конкретизировать все то видение,',
        'Что пролетает в голове, где мыслей нет?',
        'Ведь ракурсы, как в том же сновидении,',
        'Плывут сквозь облака насущных бед.',
      ],
      [
        'Вот беды почему-то начал трогать…',
        'Да ты скажи, что хочешь написать?',
        'Быть может ты, как тот солдат, все ж начал окать?',
        'Иль ты с друзьями позабыл как танц плясать?',
      ],
      [
        'Не знаю я что здесь тебе ответить.',
        'Давай порассуждаем прямо, в даль.',
        'Я попрошу тебя одну деталь заметить,',
        'Которая несёт в твой дом печаль.',
      ],
      [
        'Печаль лишь эта выражаться будет',
        'В твоих нелепых взысках бытия,',
        'Что ты с собою тащишь, и она разбудит',
        'Твой жанр мол в этом мире всё ничья.',
      ],
      [
        'Так каждый, стоит здесь подметить,',
        'О мире внешнем да и внутреннем творит,',
        'Мол всё куда не кинь ты будешь бредить,',
        'И ведь у каждого своя душа горит.',
      ],
      [
        'Пылает, мол, сознание в том пространстве,',
        'Что не дано познать для искренних затей.',
        'Ты знаешь, да и оставайся ты в своем же государстве.',
        'Когда-нибудь поймешь ты пару знатных сих вещей.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 5, 8),
    code: 'prosnutsja-rano-utrom-doma',
    content: [
      [
        'Проснуться рано утром дома…',
        'Никто не помнит тех времен,',
        'Когда возле тебя знакома',
        'Та личность, что проходит за углом.',
      ],
      [
        'Все коридоры были разноцветны,',
        'Карнизы, стены - всё куда ни глянь.',
        'И обучения для нас секретны',
        'Ибо семья хранит лишь свой Инь Янь.',
      ],
      [
        'Но с возрастом твои воспоминания летом',
        'Лишь обретают настоящий жанр,',
        'Что весь характер твой цветным букетом',
        'Нам преподносит как цветной пожар.',
      ],
      [
        'Его ты вряд ли как-то будешь трогать',
        'Да бы людей построить в грань.',
        'Ты будешь им из дня работать',
        'Для вознесения себя в ту рань…',
      ],
      [
        'Когда с тем самым человеком',
        'Проснёшься ты опять в домах,',
        'Что называются твоим лишь веком.',
        'Рисуй как режиссер в своих прекрасных снах.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 5, 17),
    code: 'posledniy-den-lihih-srazheniy',
    content: [
      [
        'Последний день лихих сражений',
        'Проходит, как любви тот лик,',
        'Что узреваешь ты в изыске всех волнений,',
        'Противоречащих исходу, как старик…',
      ],
      [
        'Что понимает больно много',
        'Для той улыбки на заре.',
        'Но не хватает жаль лишь просто диалога',
        'Для расширения затей в волне…',
      ],
      [
        'Для океана мыслей мира,',
        'В которых каждый как болван',
        'Стремится к радости турнира,',
        'Мол человек не есть карман…',
      ],
      [
        'В который все кладут не глядя',
        'В тот милый лик, что превзошёл друзей.',
        'Ведь это классика, когда пришёл тот дядя',
        'И положил в карман затей…',
      ],
      [
        'Что превосходят поколение наше',
        'Во всех тех ракурсах примет',
        'О том отважном ералаше,',
        'Преобразованном в букет…',
      ],
      [
        'Для той же самой леди счастья,',
        'Что я в прогулках повстречал.',
        'Весь мир несёт одно несчастье.',
        'Ведь каждый знает то, что ты не угадал.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 5, 23),
    code: 'neispolnimyje-zhelanija-veka',
    content: [
      [
        'Неисполнимые желания века',
        'Всем преподносят жизни цвет,',
        'Обогащая дальний взор для человека,',
        'Что и несёт порой с собой букет.',
      ],
      [
        'Он думает годами над теорией,',
        'Взобравшись, как матрос, наверх.',
        'И вдруг сегодня он расскажет пред той же аудиторией,',
        'Как превзошёл людей на данный век.',
      ],
      [
        'Все просто, братья, помолчите,',
        'Да и придумайте сюжет',
        'О светлых радостях. Внесите',
        'Свой вклад в безудержный куплет.',
      ],
      [
        'В той песне, что для вас играет,',
        'Как только солнце за окном',
        'Вам подмигнёт, усы поправит,',
        'И заберёт с собой в дурдом…',
      ],
      [
        'Что миром всех людей мы называем.',
        'Никто не может без себя',
        'Всё ж разобраться. Все вместе мы киваем,',
        'А бы не трогали меня.',
      ],
      [
        'Забудь, что есть ты и сомнения',
        'Свои разоблачи в тот миг,',
        'Когда покажешь все свои умения,',
        'Поймёшь, что возглас для троих.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2022, 6, 8),
    code: 'what-about-pretty-station',
    content: [
      [
        'What about pretty station',
        'Going on as planet Earth?',
        'Just give me some navigation',
        'Speak as child goes to the berth.',
      ],
      [
        'Current ocean’s dream and travel',
        'Proves some like as abstract bing,',
        'Takes one peace of sun bit ravel',
        'To improve your own ding.',
      ],
      [
        'That’s bell sound of mum gladness',
        'Cross the planet’s county side',
        'Wishes to check in case the madness',
        'How the people think much wide.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 6, 20),
    code: 'proidet-za-godom-god-stoletia',
    content: [
      [
        'Пройдёт за годом год, столетия,',
        'И мир все будет соблюдать',
        'То правило, где только междометия',
        'Передают, как нам себя же изучать.',
      ],
      [
        'Так вот себя, поверь мне, очень просто',
        'Лишь обучить движению грёз',
        'В том ракурсе, где все непросто…',
        'На самом деле я всерьёз.',
      ],
      [
        'Про мир игривый, знатный, долгий,',
        'С набором искренних забот.',
        'О той прекрасной леди с толком,',
        'Достигшей враз бескрайних всех высот!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 6, 20),
    code: 'prosnus-i-polechu-za-ptitsej',
    content: [
      [
        'Проснусь и полечу за птицей,',
        'Что сквозь дожди себя найдёт,',
        'А всё ж я думаю про мастерицу,',
        'Что мир меняет, как студент зачёт…',
      ],
      [
        'Свой получает мигом',
        'Для радости цветных забот,',
        'Тех мечт, что в ракурсе игривом,',
        'Весь мир меняют для жизненных острот…',
      ],
      [
        'Которые как люди многолики.',
        'Но распознать загадку наших дней',
        'Поможет человек игривый,',
        'Зовя три тысячи цветных коней…',
      ],
      [
        'С той самой гривой разноцветной',
        'Для радуги у глаз тайком',
        'Узреть красавицу, как леди очнь секретной,',
        'Желаю жизнь я жить крылом…',
      ],
      [
        'Той самой птицы счастья неба,',
        'Что сквозь дожди себя найдёт.',
        'Вкусай ты радость солнечного хлеба.',
        'Весь мир пред Вами радугу найдёт!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 6, 11),
    code: 'byvaet-po-utram-sprosony',
    content: [
      [
        'Бывает по утрам спросони',
        'Ты скажешь полный бред себе.',
        'Потом ты глянешь на свои ладони',
        'И вспомнишь ту загадку - бытие.',
      ],
      [
        'Задав потом вопросы рядом,',
        'С тем самым смыслом бытия,',
        'Рассказ в ответ затеют взглядом',
        'Весь смысл в молчании тая…',
      ],
      [
        'Ты уж прости меня за яркие мотивы:',
        'Весь день идёт как рифма у ручья,',
        'Что с незапамятных времён болтливый',
        'Ведь социум общается как я…',
      ],
      [
        'Вот уж сказать здесь что-то нужно.',
        'Как я ведь значит: мы как все…',
        'Но знаешь, мне сказал однажды дядя равнодушно,',
        'Что счастье лишь в той утренней росе…',
      ],
      [
        'Когда глаза открыв с прогулкой',
        'По местности любимой ты идёшь.',
        'Ведь ранний жанр красот в шкатулке,',
        'Что образом своей же жизни ты зовёшь.',
      ],
      [
        'Зовёшь не глядя на простор Вселенной',
        'С ее могуществом всё к норме привести.',
        'Так вот желаю я задачи незабвенной,',
        'Что б жизнь твоя была в создании пути!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 6, 14),
    code: 'serjoznyj-parnishka-zatejal-besedu',
    content: [
      [
        'Серьезный парнишка затеял беседу',
        'С тем человеком, которого нету.',
        'Нет его рядом поговорить:',
        'Лети мир кувырком, ай-да произносить…',
        'Вслух те беседы, что в округе нас,',
        'Что с именем ярким настроят в тот час…',
        'Делать дела с порядком затей',
        'Да бы достигнуть Всемирных идей!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 6, 23),
    code: 'prostranstvo-chto-vokrug-tebja-povsjusu',
    content: [
      [
        'Пространство, что вокруг тебя повсюду,',
        'Есть то, что видишь ты своим крылом,',
        'Летящим к взрослому, великому уюту',
        'Да бы достичь своей же цели и притом…',
      ],
      [
        'Раскрепостить интригу мироздания',
        'И выявить загадки лет.',
        'Поверь, есть милые сюжеты из сознания',
        'Представить мир игры в твой личный сет…',
      ],
      [
        'Мелодий из души летящих',
        'В тот мир вокруг для поиска идей,',
        'Разрушавших домишки настоящих,',
        'В раздумьях хлеб судивших поскромней.',
      ],
      [
        'Кто здесь, недалеко со мною,',
        'Возьмись за действие времен.',
        'Тех настоящих с искрами длиною',
        'Той бесконечности, что разум окружен.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 6, 29),
    code: 'prekrasnyi-den-za-lesom-soltce',
    content: [
      [
        'Прекрасный день! За лесом солнце…',
        'Ты предскажи сегодняшний сюжет!',
        'Ведь знаешь, говорить не просто,',
        'Когда с тобой идет насыщенный ответ.',
      ],
      [
        'В согласных милых сквозь просторы мысли',
        'Ответ лишь призывает гордо описать,',
        'Как человек, нашедший близ себя медведя гризли,',
        'О том мечтает: как его обнять…',
      ],
      [
        'Ведь север придает серьезную манеру',
        'Свои сюжеты описать в волнах,',
        'Доступных в памяти, как космос, взяв Венеру,',
        'Ведет для общества свои загадки о богах…',
      ],
      [
        'Вносить в наш здравый ум суждений…',
        'Поверьте мне: из нас близ каждый может всё узнать.',
        'Лишь говори, как применил ты часть умений,',
        'И смотришь, умеешь милый фокус показать.',
      ],
      [
        'В толпе смотрящих будет смех различный.',
        'Кто-то в высокий тон твой облик принесёт.',
        'Желаю быть собой, как человек приличный.',
        'Толпа есть жизнь…. Да я шучу. Иди вперёд!',
      ],
      [
        'За гранями тех рассуждений мира',
        'Найдёшь себя, как утром тот упрёк,',
        'Бодрящий смыслом, и будет всё сатира.',
        'Вновь улыбаясь будешь двигаться, цветок…',
      ],
      [
        'Что свой бутон в манере вальса',
        'Лишь дарит людям для времен,',
        'Когда близ каждый будет, как запястье',
        'Для времени в наш облик погружен.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 7, 3),
    code: 'vzrosleem-i-nachinaem-ponimat',
    content: [
      [
        'Взрослеем… И начинаем понимать',
        'Насколько взгляд полон загадок',
        'С искристой гранью мир решать',
        'В преддверии трех куч тетрадок…',
      ],
      [
        'Исписанных бескрайними словами',
        'О превосходстве искренних затей.',
        'Все, что мы делаем хранят мечтами',
        'Те люди, что чуть-чуть сильней.',
      ],
      [
        'Они сильны лишь в личных рассуждениях.',
        'На улице тот человек дрожит.',
        'Ведь мы и знаем, что огонь в стремлениях.',
        'Пойдем пред нами, наш простор хранит…',
      ],
      [
        'Тетради с исписанием точной',
        'Нелепой, быстрой глубины.',
        'Вкушай идеи, небоскребы сочных',
        'Стихов о том, как все мы голодны.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 7, 1),
    code: 'chelovek-ty-interesnyi',
    content: [
      [
        'Человек ты интересный!',
        'С той загадкой неизвестной…',
        'Подсказать лишь сможет кот,',
        'Который во дворе зовёт',
        'Лишь поиграться, что ли в прятки.',
        'Он чёрный, у него есть взятки,',
        'Когда ты котика найдёшь,',
        'С улыбкой дальше ты пойдёшь',
        'Воспринимать повсюду',
        'Великие просторы дней.',
        'Ставь запятую в настоящую минуту:',
        'Мир ждёт намек, что б быть мудрей!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 6, 30),
    code: 'davai-poprobuem-seichas',
    content: [
      [
        'Давай попробуем сейчас. Повсюду',
        'Охуеваю я от едущий вперёд карблед.',
        'Желаю я себе всю ту причуду,',
        'Что представляешь ты, как ты они.',
      ],
      [
        'Там другой человек в санях резвых с провинции.',
        'Он на все плевал на это в принципе:',
        'Едущий вперёд и вдаль',
        'Тот капитан вазьми штурваль.',
      ],
      [
        'Так вот я еду так прилично,',
        'Что начал я об этом стих писать.',
        'Мол страх и ноты жизни - безразлично.',
        'Ты едешь очень быстро наяву...',
      ],
      [
        'Давай-ка в строну погрязнешь в рассуждениях',
        'Об ощущении себя в волне.',
        'Той хвали в настоящем без печали.',
        'Так и звучит, пусть будет бред.',
      ],
      [
        'Ты расскажи другим возле, повсюду',
        'О бытие, что б жизнь понять.',
        'Не знаю почему, не знают все вдруг суть к уюту.',
        'Так я и каждым словом даю всё это знать.',
      ],
      [
        'Один вопрос… Здесь нечто для прогресса.',
        'Здесь философии на Тома три',
        'В кино для цельного конгресса.',
        'Да и такого нет. Прости.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 7, 9),
    code: 'ja-ne-znaju-chto-so-mnoju',
    content: [
      [
        'Я не знаю что со мною…',
        'Стих уж начал как-то медленнее жить',
        'В душе, когда ты думаешь игрою,',
        'Хотя понять все так же сложно, пережить…',
      ],
      [
        'Бы эти все моменты дела,',
        'Когда уж больше нет тех игр.',
        'Да, принеси мне три мешка предела,',
        'И я воскликну восклицанием шифр!',
      ],
      [
        'Он будет явно как-то слепо',
        'Смотреть в происходящее тех дней,',
        'Когда ты бодрствовал умело,',
        'Не находил своих теней.',
      ],
      [
        'Ведь солнце так вокруг играло',
        'В тот танец, что дает понять',
        'Сквозьличное просторное начало.',
        'Осталось лишь одно то слово - ждать…',
      ],
      [
        'А вдруг появится негласный?',
        'А вдруг пройдет он мимо нас?',
        'Но знаете, и будет столь ужасный.',
        'Какой-то хлопец, друг всех баз.',
      ],
      [
        'Ведь база и действительно приходит',
        'К тебе… Не стоит к ней идти.',
        'Расслабь своей души восходы',
        'С улыбкой дальше ты иди.',
      ],
      [
        'Приходишь ты лишь постоянно',
        'К каким-то азбукам затей.',
        'Опять расслабься - все понятно.',
        'Не существует всех идей.',
      ],
      [
        'Не опускай свой нос игривый.',
        'Не существует до тех пор',
        'Пока тот мальчик молчаливый,',
        'Взяв в руки, вознесет топор…',
      ],
      [
        'Над деревом раздумий,',
        'Которые дают понять,',
        'Как действие летит в том шуме,',
        'Дающем всю загадку разгадать.',
      ],
      [
        'Ведь пообщавшись лично с нами',
        'Поймешь ты несколько причин.',
        'Раскрепостившись вдруг мечтами',
        'Найдешь ты возглас для доктрин.',
      ],
      [
        'Потом напишешь тем же действом',
        'Да бы понять все до конца,',
        'Разложишь листья с тем блаженством,',
        'Что в облике твоем юнца…',
      ],
      [
        'Отображались все столетия.',
        'Уж да, легко все написать…',
        'Кто дочитал, Вам долголетия',
        'И добрых глаз Вам увидать.',
      ],
      [
        'И в тот момент сутей сближения',
        'Рассказ вести о долгожданных днях,',
        'Высказывая всё предречение,',
        'Летящих в жизни на путях…',
      ],
      [
        'Ведущих ровно к тому взгляду,',
        'Что ощутил ты пару лет назад.',
        'Воскликнул шифр? Клянись обряду!',
        'Он нужен почему-то для наград.',
      ],
      [
        'Медаль не будет мило звоном',
        'Своим сверкать у Вас в руках.',
        'Она в душе с тем коридором,',
        'Прошедшим вновь, опять в кругах.',
      ],
      [
        'И выхода не будет рядом.',
        'Лишь будет память всех времен.',
        'Как мило ты играешь в атом',
        'Вот в той Вселенной, что же рядом. Освещен…',
      ],
      [
        'Тот коридор лишь блеклым светом.',
        'Поверьте мне, хоть раз за жизнь.',
        'Все, кто родился с этикетом,',
        'В том есть немного весь тот риск.',
      ],
      [
        'И знаешь, современный парень',
        'Я повторюсь, иль девушка зови…',
        'Цвет, сочность искреннего взгляда',
        'Не существует без любви.',
      ],
      [
        'Любви к тем сочным древам леса,',
        'Что даже в Арктике найдешь.',
        'Лишь путешествуй для всего прогресса.',
        'Воскликнешь - значит ты живешь.',
      ],
      [
        'А в путешествии осознанных стремлений',
        'Отправь улыбкой тот сигнал ты мне.',
        'Достойно, люди, уважений',
        'Быть человеком находясь из вне.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 7, 21),
    code: 'ja-dazhe-i-ne-znaju-chto-skazat',
    content: [
      [
        'Я даже и не знаю что сказать…',
        'Прошли часы, как то стремление',
        'Всю жизнь в один тот образ воссоздать',
        'Для предъявления затей кипения.',
      ],
      [
        'Ведь общность в области разумных,',
        'Летящих в даль всех целей для людей,',
        'Изображает действо умных.',
        'Отображает шаг в мелодию твоей…',
      ],
      [
        'Просторной, милой, многоликой',
        'Души для радости идей,',
        'Вкусая сочность с той клубникой,',
        'Что прорастает для гостей…',
      ],
      [
        'Которые придут, поверь ты,',
        'Испробовать тот образ с кипятком.',
        'Он полон стен, где все портреты:',
        'Друзья и есть один при том…',
      ],
      [
        'Который, ты прости безлико,',
        'Проводит сей эксперимент.',
        'Ведь результат, как говорил, уж дикий.',
        'Лови. Лови ты сей момент.',
      ],
      [
        'Всё в общности своей просторной',
        'Душе, как говорилось, с запятой,',
        'Которая поставлена проворной',
        'Для паузы, являясь той…',
      ],
      [
        'Что каждый в этом свете может',
        'Понять… И чайник засвистит.',
        'Забыл что есть реальность, ёжит?',
        'Ты создавай, а он пускай кипит…',
      ],
      [
        'С таким же бурным пузырем планеты',
        'Земля… Как сказано… На ней есть всё,',
        'Что создаёт для общества свои буклеты.',
        'Ведь точки две над буквой ё.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 7, 18),
    code: 'prosnulis-utrom-voshitilis',
    content: [
      [
        'Проснулись утром, восхитились',
        'Тем изобилием чудес,',
        'Мол мир вокруг, сверчкам годились,',
        'Нам ищет всем определенный лес…',
      ],
      [
        'Где каждый заяц многоликий',
        'Воспроизводит драмы жанр.',
        'Так интерес свой больно дикий',
        'Он превращает, не посудите строго, в тот кошмар…',
      ],
      [
        'Что с пьесой расчудесной',
        'Живет употребляя жизнь',
        'Лишь в образе литуры пресной',
        'Определяя тех новизн...',
      ],
      [
        'Для леса дикого из рода',
        'Творцов сознания чудес',
        'Для тех создателей, где кто-то',
        'Почувствовал, и убежал в наш лес.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 7, 15),
    code: 'vot-kak-vsegda-pri-vzglyade-mira',
    content: [
      [
        'Вот как всегда при взгляде мира',
        'Отрёкся он от свет-кумира.',
        'Пошёл гулять вдоль площадей,',
        'Искать, ты знаешь, тех людей,',
        'Что в облике своём игривом',
        'Распространяют позитивом',
        'Загадки века сих же лет.',
        'Ты знаешь где лежит буклет?',
        'В котором, ярко восхищаясь,',
        'Ответ природы едет в даль.',
        'Ты глянь в него и, обольщаясь,',
        'Воскликни ты свою мораль…',
        'Тех знатных, мелких, разношёрстных',
        'Прелюдий в мире тех же зим,',
        'Когда ты знаешь всех серьезно…',
        'Поехали с со мною в Рим?',
        'Вот данный ритм движения времени',
        'Раскрепощает все умения.',
        'Взгляни на руки и вперёд',
        'Тебя Вселенная зовёт!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 6, 31),
    code: 'proslavilsja-vot-bylo-paren',
    content: [
      [
        'Прославился вот было парень',
        'Воображая лозунг всех времён.',
        'И подошёл к нему один татарин',
        'Взять капельку легенды: просвещен…',
      ],
      [
        'Он в дикие мотивы рода.',
        'Да, человека, кто бы он ни был.',
        'Он здрав для чуткого полёта,',
        'Узрев людей… Но я его забыл.',
      ],
      [
        'Забыл лишь бредя по дороге',
        'О гении тех технологий дня.',
        'Ведь человек, взглянув в упреке,',
        'Все думал сколько будет дважды два.',
      ],
      [
        'Мне нравится подобный ракурс размышления.',
        'В нем кроется загадка лет.',
        'Лишь помни про своё же поведение,',
        'Не будет за тобою бед.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 7, 7),
    code: 'nichego-ne-pomnu-ja',
    content: [
      [
        'Ничего не помню я… Привет!',
        'Как дела во всем том мире,',
        'Что представляется тобой, мой цвет,',
        'Та радуга в научном блиц эфире?',
      ],
      [
        'В тех теоремах с короб дней,',
        'Лишь упакованных для правильного дела,',
        'Откроются те облики затей',
        'Вождения людьми отдела.',
      ],
      [
        'Не за горой, ты смотришь, развернулись',
        'В загадках дней для нас людей',
        'Мотивы игр, где в социум метнулись',
        'Мыслишки действий поскромней.',
      ],
      [
        'Ведь скромные манеры действий',
        'Несут лишь выполнение задач,',
        'Когда живет своею жизнью честный,',
        'Тот гений, хватающий удач.',
      ],
      [
        'В наличии лишь нескольких умений',
        'Для производства мира цифр.',
        'А сколько за плечами зрений',
        'На превосходство мира игр?',
      ],
      [
        'Ведь игры в настоящем, как листок',
        'Летят по ветру врученного дела.',
        'Исписывать моменты, как игрок',
        'Приносит искреннего хлеба…',
      ],
      [
        'Что за обедом с CEO Microsoft',
        'Расскажешь через годы смело.',
        'Забудь тот миг для встречи с тех высот,',
        'Что сказаны банальным страхом столь умело.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 7, 8),
    code: 'kto-to-za-oknom-letaet',
    content: [
      [
        'Кто-то за окном летает,',
        'Великого огня желает.',
        'Но стоит в раз его поймать,',
        'Как жизнь лишь тронет суп опять.',
        'Ведь ложки в мире так различны,',
        'Что кушать хочется тактично,',
        'Обрамляя свой же свет',
        'Тем словом дней. Но есть ответ…',
        'Ответ на все же мысли, взгляды',
        'Сквозь дождь согласия. Не надо…',
        'Мне говорить что есть ответы.',
        'Куда не кинь - здесь нет привета,',
        'Что в облаках летает днём,',
        'А ночью под моим ковром.',
        'По стенке в утро разбежавшись,',
        'Насытит ум, набравшись...',
        'Восхода солнца свет и дух.',
        'Ты прочитал? Скажи всем вслух!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 7, 30),
    code: 'strannoe-chuvstvo-kak-opisat',
    content: [
      [
        'Странное чувство… Как описать?',
        'Сознание под действом? Кого теперь мне ждать?',
        'Не знаю, что со мною.',
        'Нет сну, иду с тобою…',
      ],
      [
        'В тех бесконечных и прекрасных,',
        'С рукою милой! Но негласный',
        'Тот образ вот сейчас со мной.',
        'Опять мечтаю только лишь тобой.',
      ],
      [
        'Как повернуть все время в спячку',
        'И быстрым шагом подойти,',
        'Обнять и попросить жвачку?',
        'В сторонку тихо отойти.',
      ],
      [
        'И, не мешая слушать',
        'Себя же с той улыбкой на лице,',
        'Жвачку бесконечно кушать,',
        'И думать о мимолетном том словце…',
      ],
      [
        'Что чувствуешь ты рядом.',
        'И чувствуешь твоим же милым взглядом.',
        'Все впереди, поверь ты мне,',
        'И в путь Вы дайте жвачки две.',
      ],
      [
        'Пусть бесконечное раздолье',
        'Со вкусом яблока того',
        'Закроет разум в том подполье,',
        'Где не скажу я своего…',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 8, 8),
    code: 'prosnuvshis-podojdu-s-privetom',
    content: [
      [
        'Проснувшись, подойду с приветом,',
        'Глаза открою тем секретом,',
        'Что во главе законов дня',
        'Сиять лишь будет для тебя.',
      ],
      [
        'Ты улыбнёшься, подмигнёшь мне,',
        'И глазки нежные открыв,',
        'Узнав историй мира в том же сне,',
        'Поднимешься, уйдёшь с землей в отрыв!',
      ],
      [
        'И, полетев в пространстве',
        'Своём для нынешних времён,',
        'Ты будешь развлекаться в хулиганстве,',
        'Я бы назвал, день только для тебя рождён…',
      ],
      [
        'И так, спустя те годы расстояния,',
        'Ты восклицаешь лишь слова',
        'О том, что значит то сияние',
        'Из сердца юного мальца.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 8, 8),
    code: 'kogda-ja-razvlekajus-gordo',
    content: [
      [
        'Когда я развлекаюсь гордо,',
        'Никто не знает всех затей,',
        'Затем пройду между людей проворно',
        'Да бы добыть тот разговор ветвей…',
      ],
      [
        'Различных знатных рассуждений,',
        'О чем тот человек молчит.',
        'Не знает он всего суждений,',
        'О том, как мир наш тихо, но гремит.',
      ],
      [
        'Ведь столько много всех деталей',
        'Собралось в кучу для того,',
        'Что б разница всех тех скрижалей',
        'Добилась видно одного…',
      ],
      [
        'Пересечения граней',
        'Для поиска затей.',
        'Ты дашь ведь каждому названий,',
        'Но теряй своих идей.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 8, 13),
    code: 'musyka-igraet',
    content: [
      [
        'Музыка играет,',
        'В даль нас всех зовет.',
        'Ведь что-то она знает.',
        'Ведь стилем песенку поет.',
      ],
      [
        'Тот стиль уж больно хитрый.',
        'Вы не поверите, в насыщенных сердцах',
        'Он полон незабвенной той палитрой',
        'Недалеко от разума в словах…',
      ],
      [
        'Что незабвенно все летают рядом,',
        'Проснуться позволяют в миг забот,',
        'Мол что-то помнишь ты всем взглядом,',
        'Наполненным в твоей душе частот…',
      ],
      [
        'Ловящих добрую поэму',
        'О небывалой красоте небес.',
        'Не ставьте мне, я попрошу дилемму,',
        'Я и не ждал сверкающих чудес.',
      ],
      [
        'Ведь чудеса, как жизнь твоя живут здесь.',
        'Вот прямо в сердце у меня.',
        'Есть лишь вопрос, а как же мир весь,',
        'Когда не можешь ждать ни дня?',
      ],
      [
        '«Ведь ожидание творится сам собою…»',
        'Опять тот мальчуган из Грузии пришел.',
        'Вся жизнь вот почему-то только лишь с тобою.',
        'Задам вопрос… Реально обречен?',
      ],
      [
        'Ты букв не путай! Всё так рядом!',
        'Пройдись по миру свежим взглядом.',
        'Пойми себя, иди ты в даль.',
        'Пускай звучит и дальше твоя сталь…',
      ],
      [
        'Что так куется расчудесно',
        'Среди миров забредших вне тебя.',
        'При милой встрече я скажу словесно,',
        'Что ты одна… И слово выбирай ты для себя.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 8, 14),
    code: 'kak-opisat-chto-zdes-so-moju',
    content: [
      [
        'Как описать что здесь, со мною?',
        'Идёт волна? Да нет, живу мечтою,',
        'Что всё вернётся утром мне.',
        'Я буду вновь в своей мечте.',
      ],
      [
        'Как только думаешь об этом,',
        'Так сочиняется куплетом,',
        'Как будто ты себя поймёшь',
        'И вновь загадку обретёшь.',
      ],
      [
        'Но вся загадка в этом мире',
        'Есть то, что было в том трактире,',
        'Мол говорит весь здравый люд',
        'О том, что есть на самом деле тот уют.',
      ],
      [
        'Уют я лишь имею в здравом смысле,',
        'Мол ты себя ведёшь, как человек.',
        'Ты просто вновь расслаблен. Мысли',
        'Плывут же сами, как и был тот век.',
      ],
      [
        'Не управляй собою, парень.',
        'Забудь, ты есть, ты легендарен.',
        'Всё, что с тобой сейчас и тут,',
        'Есть настоящее: уют.',
      ],
      [
        'Ты знаешь обо всём же этом',
        'И составляешь тем куплетом,',
        'В котором каждый ждёт понять,',
        'Как же себя и разузнать.',
      ],
      [
        'Твой разум есть нейрон с тем действом',
        'Взаимодействия в воде не пресной.',
        'Она полна загадок дня.',
        'И помни, помни же себя.',
      ],
      [
        'Ты тот, кто знает всё, что в мире',
        'Вокруг творится без чудес.',
        'Есть биология в эфире.',
        'Иди и накачай свой пресс!',
      ],
      [
        'Чем больше действий для себя же,',
        'Тем больше разум твой вальяжен.',
        'Да я не знаю, что писать.',
        'Я просто всем хочу сказать.',
      ],
      [
        'Своя система многогранна.',
        'Она, поверь мне, людям странна.',
        'Живи ты с ней и помни день,',
        'Когда не знал, что есть та лень.',
      ],
      [
        'Ведь действий в мире так же много,',
        'Как и приносит всем нам йога.',
        'Да я не знаю кто есть кто.',
        'Я знаю только слово «что».',
      ],
      [
        'Ведь мир весь наш такой прекрасный,',
        'Но есть один момент ужасный.',
        'Ты, как и я, не знаешь сам,',
        'Где был, где есть тот свой капкан…',
      ],
      [
        'Что ты попал в мозгах своих же.',
        'Раскрой его, живи чуть ближе',
        'К тем всем друзьям, что для тебя',
        'Раскроют ракурс бытия.',
      ],
      [
        'Но их всего есть пара только.',
        'Я знаю их чуть-чуть, вот столько.',
        'Желаю им нести кураж',
        'Для своей жизни, где весь наш…',
      ],
      [
        'Неисполнимый свет раздолья',
        'Играет джаз вот в том подполье.',
        'Есть человек, есть ты и я.',
        'Есть мир. Вселенная. Заря.',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 8, 16),
    code: 'my-belarusy',
    content: [
      [
        'Мы - Беларусы.',
        'С нами - русские, c ними - бог.',
        'Мы - Белокузые.',
        'С нами лошадь, компот.',
        'Всех угостим, идите втроём.',
        'Я покажу всем вам хитрый приём,',
        'Где плечи закрытые,',
        'Слово прожитое',
        'Летят в том пространстве,',
        'В живом государстве.',
        'Мальчик закон свой подписал.',
        'Люд, как уклон, свой указ написал.',
        'Ты есть правитель пятых небес,',
        'Житель той фабрики подлый, бля, бес.',
        'Плечи открою, открытку пошлю.',
        'Мир я построю, сейчас покажу.',
        'В нем ты постигнешь пару интриг.',
        'Помни лишь всё это жданный тот миг!',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 8, 16),
    code: 'segodnja-vremja-bredit-dolgo',
    content: [
      [
        'Сегодня время бредит долго',
        'Вдоль тех полей того же разума бытия,',
        'Что и обрёл ты рядом с домом,',
        'В котором был твой облик жития.',
      ],
      [
        'Вот в том дворе, рядом с рекою,',
        'Ты выглядишь, играешь сам с собою',
        'Ты думаешь, что мир весь наш.',
        'Но есть один сей знатный зов, кураж…',
      ],
      [
        'Что б было во всем мире мирно',
        'И растворялось в той воде,',
        'Где человек лишь делиться игриво',
        'Той мыслью о бытие людей.',
      ],
      [
        'И каждый, гордо изучая',
        'Твои затеи тех времён,',
        'Подходит, дружно в миг вручая,',
        'Тех яблок, что вручил Семён.',
      ],
      [
        'Затем ты станешь чуть дружнее.',
        'Ведь фрукты Сёмы чуть важнее,',
        'Чем весь тот мир в твоих мечтах.',
        'Да будь ты парнем в тех томах…',
      ],
      [
        'Исписанных, пред утром глядя',
        'На дружный важный тот букет,',
        'Вручённый специально дядей,',
        'Что бы увидеть весь рассвет.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 8, 21),
    code: 'vot-vstretil-ja-babulu',
    content: [
      [
        'Вот встретил я бабулю,',
        'Что расплела рассказ',
        'О том, что в том районе',
        'Чуть не пошёл экстаз.',
      ],
      [
        'В том выражении взгляда,',
        'Мол всё с плохих сторон пошло.',
        'И встретила она как надо.',
        'И встретила тот третий сей балкон.',
      ],
      [
        'Да, не успев и раскачаться,',
        'Она же прыгнула с него.',
        'Не буду за себя ручаться.',
        'Я не дружу с таким же обществом всего.',
      ],
      [
        'Всего того же безобразия',
        'Вокруг, что крошится как бред.',
        'И вы придите восвояси,',
        'Быть может будет всем букет.',
      ],
      [
        'Да нечего здесь выражать прям с толком.',
        'Всё так, с причудой и не долго.',
        'Понять себя лишь стоит мне,',
        'И путь твой лишь в твоей судьбе…',
      ],
      [
        'Которую ты строишь, глядя',
        'На незабвенную мечту,',
        'Когда опять тот будет дядя',
        'Рассказывать семь сказок наяву.',
      ],
      [
        'И в сказке, главный тот, заметьте,',
        'Не придается никому.',
        'Вы почерк, взгляд его отметьте',
        'И не твердите самому…',
      ],
      [
        'Себе как было в той легенде.',
        'Вы лишь спросите, что есть бред?',
        'И выиграйте с людьми в прямом заезде',
        'Уклюжих зайцев для побед…',
      ],
      [
        'Своих же собственных мечтаний,',
        'О том, что нет всех больше знаний.',
        'Они живут во-вне тебя.',
        'Поверь, я делаю себя.',
      ],
      [
        'Как тот игривый рай-мальчишка.',
        'Хотя пусть будет роль девчонки.',
        'Хотя постойте, здесь мыслишка…',
        'Играть всю музыку в колонке.',
      ],
      [
        'Что звуком преподносит вам,',
        'Как именно идёт разгадка.',
        'Лишь помните, что мир есть нам,',
        'Я повторюсь, одна загадка.',
      ],
      [
        'Не опускай свой вялый нос.',
        'Ведь клюв у птицы не прирос.',
        'Разгадка есть вокруг тебя.',
        'Люби ты жизнь. Живи любя!',
      ],
    ],
  },
  // {
  //   title: void 0,
  //   uid: uuidv4(),
  //   locale: 'ru-RU',
  //   date: new Date(2022, 10, 4),
  //   code: 'davaj-poprobuem-povsudu',
  //   content: [
  //     [
  //       'Давай попробуем… Повсюду,',
  //       'Весь мир в стандарте бытия.',
  //       'Я и знаю вот сейчас причуду,',
  //       'Что всем подскажет то движение дня.',
  //     ],
  //     [
  //       'Вот прям сейчас я еду, глядя',
  //       'На стих, что вот сейчас же и творит',
  //       'Свои же образы, как дядя,',
  //       'Что слово действом говорит.',
  //     ],
  //     [
  //       'Те области, в которых действо',
  //       'Все продолжает впечатлять,',
  //       'Заключены в те общности, где детство',
  //       'Все нам приносит благодать.',
  //     ],
  //     [
  //       'Такими же словами выражаться будет',
  //       'Весь тот заметный, ловкий в мире том народ.',
  //       'В том мире, где вчера же каждый позабудет',
  //       'И будет двигаться лишь только уж вперёд.',
  //     ],
  //     [
  //       'Пускай смущаться будет парень',
  //       'Или же девушка, ты в миг смотри.',
  //       'Здесь каждый есть и будет легендарен.',
  //       'И сказку выражая, все есть в твоей крови…',
  //     ],
  //     [
  //       'Как и в моей, тот знатный голос',
  //       'Вселенной нашей, там где родились.',
  //       'Пусть пышен будет радужный твой волос,',
  //       'Ведь он цепляет солнца то тепло. Коснись…',
  //     ],
  //   ],
  // },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 9, 30),
    code: 'kakojto-den-segodnja-strannyj',
    content: [
      [
        'Какой-то день сегодня странный…',
        'Он движется, как черепаха лет.',
        'Вот как так сделать, чтоб приехал наш пожарный,',
        'И погасил же быстро весь куплет?',
      ],
      [
        'Куплет с тех пор уже не дышит.',
        'Он жив, но с образом каким-то всех времён.',
        'Лишь смотрится, года он не распишет.',
        'Ты знаешь, похоже, обречён.',
      ],
      [
        'Но этот стих и день, да, очень странный.',
        'Куплет горит как видишь наяву.',
        'Но подскажи теперь куда пропал пожарный?',
        'Ведь нас же требуют прогнать ту яркую сову!',
      ],
      [
        'Она же дремлет здесь который полдень.',
        'Она мечтает о стремлениях чудес!',
        'Вот подскажите, как изучить цвета, о осень,',
        'И передать в те руки для небес?',
      ],
      [
        'Сову, ребята, Вы не трожьте!',
        'Возьми себя ты в руки, гений лет!',
        'С тех пор свой разум бодро приумножьте.',
        'Идите в даль и стройте свой изысканный сюжет!',
      ],
    ],
  },
  // {
  //   title: void 0,
  //   uid: uuidv4(),
  //   locale: 'ru-RU',
  //   date: new Date(2022, 9, 30),
  //   code: 'davai-poproduem-o-nastojashem',
  //   content: [
  //     [
  //       'Давай попробуем о настоящем…',
  //       'Ты расскажи, что есть сейчас.',
  //       'Но мне мешает то же, с маршем',
  //       'О том, что ты, сучонок, смотришь в нас.',
  //     ],
  //     [
  //       'С тобой сейчас же говорит сам текст.',
  //       'Он не желает порядка впечатлений.',
  //       'Он требует, что б приготовил же ты тест,',
  //       'В котором жизнь твоя и будет же в мгновениях.',
  //     ],
  //     [
  //       'Не забывай, трёхглавый супер личный,',
  //       'Твоё оружие есть личный же манёвр.',
  //       'И я забыл, что значит мир свой нетипичный',
  //       'Лишь потому что облик жадно возвращён.',
  //     ],
  //     [
  //       'Вот что понять, я и не знаю,',
  //       'Я просто сам с собой играю,',
  //       'Да хоть расслабится чуть чуть,',
  //       'А то погряз в своём напряге, муть…',
  //     ],
  //     [
  //       'Какая-то больн не понятная…',
  //       'Я да, не знаю что сказать.',
  //       'Наука, прошу прощения, необъятная,',
  //       'Но заключения же в базовом всего.',
  //     ],
  //     [
  //       'Ты знаешь о внедрении нервов.',
  //       'Ты знаешь о магии времён.',
  //       'Забудь, что ход твой больно верный.',
  //       'Я да, есть в жизни. Восхищён.',
  //     ],
  //   ],
  // },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 9, 30),
    code: 'poprobuem-stihi-my-sozdavat',
    content: [
      [
        'Попробуем стихи мы создавать!',
        'И как-то глупо это слышится на встречу.',
        'Мы будем мир сей, что вокруг менять,',
        'Но я себя немного искалечил.',
      ],
      [
        'Мне страшно, что ребята,',
        'Кто был в моем том самом узком мире',
        'Есть да, никто иной как узурпатор',
        'Меня как образа с той жизнью прям в эфире.',
      ],
      [
        'Вот что сказать, ты спросишь без разлуки,',
        'С той самой долею бытия?',
        'А я скажу, что будут знать все внуки,',
        'Рассказывать три звука. Жизнь твоя.',
      ],
      [
        'Но если вспомнить меж собою',
        'Походы в бар для образа затей,',
        'Я просто был в крючке былой погоды,',
        'Что под контролем милых всех речей.',
      ],
      [
        'Давай же честно скажем лихо,',
        'Что говорить я не могу сейчас.',
        'Устал и дайте мне попить чай дикий,',
        'Что бы дождаться тот прекрасный час…',
      ],
      [
        'Когда на улице повсюду',
        'Тюльпаны и нарциссы расцветут!',
        'Ты скроешься в своём приюте,',
        'В тебя лишь парой слов же попадут.',
      ],
      [
        'Вот знаете, идеи многолики,',
        'Но каждый меня знает на рассвет,',
        'Что не сыграл, есть мой этап уж больно близкий',
        'Ты строишь как-то вне свой весь сюжет.',
      ],
      [
        'Так вот же, если глянешь в карту',
        'И там же пару точек обретёшь,',
        'Свои все мысли ты не продай монаху',
        'За то, что словом с ним легенду ты возьмёшь.',
      ],
      [
        'Вот далее ныряй вперёд пред башней.',
        'Своей же башни на глазах',
        'Людей, как в фильме Ералаше',
        'Все есть, живет в твоих мечтах.',
      ],
      [
        'Но мечты ты не трогай лучше.',
        'Ты их лишь впечатлением подсади,',
        'Чтоб забирались они в дом пастуший,',
        'Где овцы есть твои герои взаперти.',
      ],
      [
        'Всех сразу же не нужно прятать',
        'От глаз, где овцы так больны.',
        'Лишь предоставьте все, ты помнишь, дяде,',
        'И с криком начинайте от винты!..',
      ],
      [
        'Ваш взмах же будет разношерстный.',
        'Он будет наблюдать за трелью дней.',
        'Ведь три есть тот хитрец проворный',
        'Лишь показать, что буду поскромней.',
      ],
      [
        'Хотя к чему здесь рассуждение',
        'Погрязло в скромный же сюжет.',
        'Увидь своё ты сновидение.',
        'Купи для дамы розовый букет.',
      ],
      [
        'Затем присядь же возле древа,',
        'В котором наших дней вся жизнь живет',
        'И наблюдай за тем изгибом века,',
        'Где солнце рано утром нас зовет!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 9, 25),
    code: 'vsio-chto-vokrug-menja-povsjudu',
    content: [
      [
        'Все что вокруг меня, повсюду,',
        'Не знает, кто я есть такой.',
        'Но я найду всю ту причуду,',
        'И гордо я ступлю ногой.',
      ],
      [
        'Ведь рифма так прекрасна!',
        'Она живет те миллионы лет,',
        'Но жизнь мне кажется ужасной.',
        'Несёт она всего стремления побед.',
      ],
      [
        'Побед над теми вот делами,',
        'Что близко, но не знают все они',
        'Того что было сделано пред вами,',
        'Того во что и я и верил. Ты…',
      ],
      [
        'Пусть меня не знаешь лично,',
        'Но все же хочешь показать,',
        'Как мир весь пляшет безгранично',
        'И свет желает в жизнь отдать.',
      ],
      [
        'Ведь с теми яркими глазами жизни,',
        'С которыми ты встретился не раз,',
        'Пусть каждый день несёт те мысли,',
        'Что все на свете, все для нас.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 9, 28),
    code: 'vot-sejchas-ja-tochno-znaju-gljadja',
    content: [
      [
        'Вот сейчас я точно знаю, глядя',
        'На облако своих всех знатных тех идей,',
        'Мол все, что в мире есть тот дядя,',
        'Который ждал всех настоящих дней.',
      ],
      [
        'И вот те дни пришли внезапно.',
        'И солнце светит как-то так,',
        'Что мир вокруг ведёт себя немного странно,',
        'Но это все есть лишь пустяк.',
      ],
      [
        'Вот кроме пустяков в своих стремлениях',
        'Весь мир содержит миллион затей.',
        'Осталось только лишь руками двигать в искривлениях',
        'Своих же собственных всех настоящих тех идей.',
      ],
      [
        'Вот что кому расскажешь быстро?',
        'Вот что кому покажешь утром пред рассвет?',
        'Весь мир же представляет из себя то коромысло,',
        'Что говорил в стихах и был раздет.',
      ],
      [
        'Я гол в поэзии прекрасной, рядом',
        'Твоих, своих и всех людей плыву.',
        'Ты знаешь, не буду я забыт с тем взглядом.',
        'Я буду жить как человек, да, наяву.',
      ],
      [
        'Рассвет… Рассвет… Ты дай мне силы!',
        'Все силы день сей провести',
        'В те нежданные истории мотивы,',
        'И жизнь же гения в миг обрести!',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 9, 29),
    code: 'vot-prosnulsja-i-mir-vokrug-gremit',
    content: [
      [
        'Вот проснулся. И мир вокруг гремит.',
        'Моя душа не знает, что же делать в сей же день…',
        'Лишь помни, что ты тот лихой джигит,',
        'И ты не знаешь свою дерзкую, слепую лень.',
      ],
      [
        'Сейчас свой стих ты сочиняешь.',
        'Вот как-то мира ты не знаешь.',
        'Все пролетает в голове,',
        'Но не творит вот в той волне.',
      ],
      [
        'Волны нет в том же настоящем,',
        'Что ты как следствие времён.',
        'Ты будешь, есть превосходящим.',
        'Ты трогаешь все то, где ты силён.',
      ],
      [
        'Сейчас вообще какая-то прям шляпа',
        'Вот пишется. Не знаешь ты что делать в сей момент.',
        'Ты все своё прям очень сильно разрушаешь.',
        'Ты лишь есть тело… Не в мире ты клиент.',
      ],
      [
        'Тебе заказчиком лишь нужно быть,',
        'И в облако всех мечт приплыть.',
        'Иди вперёд! Забей на все!',
        'Но ты живи с забитым гвоздем как своё…',
      ],
      [
        'Своё жилое впечатление века,',
        'Мол где бы не был ты есть тру.',
        'Ты встретишь, думаю, в сей жизни человека,',
        'Который будет счастьем наяву.',
      ],
      [
        'И явь вся будет восхищаться',
        'Твоим же настроением чудес.',
        'Ведь все, что есть вокруг тебя - то счастье.',
        'И имя здесь я не пишу. Я жду небес…',
      ],
      [
        'При взгляде вот на ту планету,',
        'Где воздух есть и жизнь цветёт.',
        'Эй, люди, мы отдадим дань тому раритету,',
        'Который вместе с нами до сих пор живет!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 9, 26),
    code: 'ne-do-stihov-mne-mir-ves-nastojashij',
    content: [
      [
        'Не до стихов мне мир весь настоящий.',
        'Мое сознание стремится жизнью быть.',
        'Я умственно иду вперёд для цели предстоящей',
        'Для выполнения задачи, где тот миг…',
      ],
      [
        'Момент тех выполнений скромных',
        'Задач с изыском бытия для века лет.',
        'Хочу я быть тем настоящим дядей вольным,',
        'Не знать в сюжете диких бед.',
      ],
      [
        'Так вот же завтра на шоукейсе быстром',
        'Я буду речь для всех же говорить.',
        'Не зря ж, когда-то был магистром,',
        'Летал я в путешествии познать всю жизнь.',
      ],
      [
        'Но было, знаете, в моментах личных',
        'Незнание тех всех законов бытия.',
        'Сейчас, мне кажется, ведь голос тот девичий,',
        'И я не чувствую себя и яркого жития.',
      ],
      [
        'Так вот давайте в раз напишем быстро,',
        'Что все на свете есть прогресс людей.',
        'Я заикнусь, вы выбрали министра,',
        'Что б щёлкать все орехи. Ты, быстрей!',
      ],
      [
        'Ты быстр в любом же твоём деле счастья.',
        'Ты знаешь как поведать весь секрет.',
        'И вот, как на дворе увидел ты ненастье',
        'Опять ты вспоминаешь своих бед.',
      ],
      [
        'Они лишь здесь в твоём рассудке прошлом.',
        'Ты тот, кто тянет за собою мир планет,',
        'Что люди уважают в трошки пошлом',
        'Сюжете и не знают бед.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 9, 21),
    code: 'prosnutsa-posmotret-nalevo',
    content: [
      [
        'Проснуться, посмотреть налево',
        'И облик мира воссоздать.',
        'Затем же двигаться в нем также смело,',
        'Как и во сне была лихая благодать.',
      ],
      [
        'Привстать, подумать неприлично',
        'И к двери ванной подойти.',
        'Воскликнуть ерунду прилично',
        'И дальше в жизни так идти.',
      ],
      [
        'Приличным быть не так уж мило.',
        'Ты булку лишь купи игриво.',
        'Да, говоря, вы будьте уж добры.',
        'А то покажется вам свет Луны.',
      ],
      [
        'Того же бара близ Камчатки',
        'Для области людей ведь знатно,',
        'Что потребляют алкоголь.',
        'Ты уж дебил, лишь оглянись, постой…',
      ],
      [
        'Как человек без… Человек бес.',
        'Твори чудес лишь толко в своём мире.',
        'Тебе же двойка.',
        'Ты в сартире.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 9, 21),
    code: 'utro-est-to-nastojashee-v-sej-den',
    content: [
      [
        'Утро есть то настоящее в сей день,',
        'Но я не знаю как что будет.',
        'Мне думать, поверьте, не так лень,',
        'Но мысль моя вокруг все так же блудит.',
      ],
      [
        'Я не скажу вам слово года.',
        'Сейчас я только лишь проснулся для тебя.',
        'Тебя мой мир, люблю, природа,',
        'И вечер ты оценишь да, придя…',
      ],
      [
        'К тому лихому веку жизни,',
        'Где лист от клена даст понять,',
        'Что база есть то коромысло.',
        'И мне останется лишь только ждать.',
      ],
      [
        'Ждать же придётся пару мыслей',
        'О том, что все мы в мире, здесь.',
        'Ведь так приятно думать смыслом,',
        'Что мир вокруг тебя все так же есть.',
      ],
      [
        'Весь мир прозрачный, как тот воздух,',
        'Что вздохом в свой же организм произведёшь.',
        'В нем ярко светится весь тот подсолнух,',
        'Которым свои думы создаешь.',
      ],
      [
        'Потом кататься знатно будешь',
        'Верхом на лошади времён.',
        'Чрез время ты, как олух, все забудешь',
        'И будешь той легендой одарён.',
      ],
      [
        'Твои слова не вяжутся так славно,',
        'Ты как нелепый человек идёшь,',
        'Но все ведь так же выглядит довольно знатно.',
        'Ты своё время прям к себе зовёшь.',
      ],
      [
        'Зовёшь его. Не растерявшись,',
        'В окно ты смотришь на восток.',
        'Весь смысл идёт лишь потерявшись',
        'В Науке где ты радугу упрёк.',
      ],
      [
        'Упрёк без смысла своих действий.',
        'Весь смысл брошен в даль для всех.',
        'Ты знаешь парочку воздействий,',
        'Что так не любишь и так любишь без утех.',
      ],
      [
        'Все те воздействия, как крылья,',
        'Тебе покажут воздух, свет.',
        'Ты испытаешь то бессилие',
        'Понять, что в голове ведь будет бред.',
      ],
      [
        'Затем ты воздуху покажешь,',
        'Как вздох работает в груди,',
        'И свету все затем расскажешь,',
        'И будет слово позади…',
      ],
      [
        'Всего того же промежутка',
        'Во времени твой же жизни лишь в обед,',
        'Ведь мысли были, как минутка',
        'В то время, когда опять же нёсся бред.',
      ],
      [
        'Ты вдруг опять себе расскажешь',
        'О сказке рядом. Все в волне',
        'Того бессилья, но все же ты покажешь,',
        'Как ручку правильно держать вот в том ведре.',
      ],
      [
        'Полно оно все будет мыслью,',
        'Что пробегает в твоей же голове.',
        'Ты вспомнишь благое коромысло',
        'И будешь ты опять в ведре.',
      ],
      [
        'Ведре я повторюсь все с той же мыслью,',
        'Где воздух сильно искажён.',
        'Поверьте мне, инакомысли',
        'Есть через время. Поражён!',
      ],
      [
        'Я поражён тем рассуждением,',
        'Где  две строки есть полный бред.',
        'Я вот не знаю, что с моим суждением.',
        'Я не живу сейчас во век.',
      ],
      [
        'Во век всех хитрых заключений,',
        'Что в воздухе и в суете висят.',
        'Я жду лишь только приключений.',
        'Ведь все они - один лишь ракурс поросят.',
      ],
      [
        'Что просят маму воду, мира,',
        'В котором люди дышат также мило,',
        'Все рассказать друг другу в даль.',
        'Забудь ты, милая, печаль.',
      ],
      [
        'Она вся будет восторгаться',
        'Два раза. Завтра уж признаться',
        'Не будет жить весь тот куплет,',
        'Где здесь написан полный бред.',
      ],
      [
        'Так вот поверь ты мне сейчас же,',
        'И фокус покажи потрясшей.',
        'Ты в клипе будешь та же мать,',
        'Что всем приносит благодать.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 8, 27),
    code: 'vy-znajete-tak-mnogo-v-mire',
    content: [
      [
        'Вы знаете, так много в мире',
        'Всегда Вам можно передать!',
        'Смотри ты в даль в том ориентире.',
        'Здесь каждый миг с улыбкой есть волна плясать!',
        'Да знаешь, хочется приехать',
        'И стих тебе днём ль утром да прочесть.',
        'Затем, оставив книгу приоткрытой,',
        'Всей той землей вперёд смотреть.',
        'И, рассуждая о причинах,',
        'Найдёшь ты всех в глубинах,',
        'Что в книгах для тебя живут,',
        'Ведь в честь тебя весь мир сомкнут!',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 8, 7),
    code: 'rasslabsja-i-zhivi',
    content: [
      [
        'Расслабься и живи!',
        'Здесь прозвучал тот возглас,',
        'Что восклицал тот крик: живи,',
        'Пока не опал волос…',
      ],
      [
        'Такие вот простые строчки',
        'Вновь создаются из чудес.',
        'Расславлены все были точки.',
        'Не достучался я небес.',
      ],
      [
        'И все так просто, многолико,',
        'Все торговало предо мной,',
        'Что я увидел мир весь милый,',
        'Ведь только был я лишь с тобой.',
      ],
      [
        'Так вот сейчас, что есть потрогать,',
        'Есть полотенце из ручья,',
        'Что вытирает сердце ёкать',
        'Свои загадки бытия.',
      ],
      [
        'Расслабься и живи ты мигом,',
        'Что вдоль проходит меж тебя.',
        'Люби ты сердцем, всем тем миром,',
        'Что создало тебя, живя!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 8, 13),
    code: 'smorju-ja-v-dal-a-tam-rassvet',
    content: [
      [
        'Смотрю я в даль, а там рассвет',
        'Тех сновидений, где лишь только бред.',
        'Ведь я не помню их совсем.',
        'Вот потому и бред затем.',
      ],
      [
        'Ведь утро с превосходным смыслом',
        'Рождает новые все мысли',
        'О мире, что вокруг тебя живет,',
        'О том, что вся твоя душа поёт.',
      ],
      [
        'Но вдруг работа многозначно',
        'Все строит в бизнесе идей.',
        'Ты восклицаешь однозначно',
        'Своим тем ракурсом затей.',
      ],
      [
        'Все принимают то, что было',
        'Лишь сказано в словах,',
        'Но строишь ты мосты учтиво,',
        'Не то, что и в своих же снах…',
      ],
      [
        'Но сны дневные так прекрасны,',
        'Где размышляешь ты, как мальчик пред зарей.',
        'В них есть порой предлог алмазный,',
        'Что в настоящем ты порой…',
      ],
      [
        'Он здесь, в алмазе, в том же смысле,',
        'Что все вокруг есть та Земля',
        'В пространстве ты, с которым перечисли',
        'За что ты… Человек, не зря…',
      ],
      [
        'Искал и ищет то движение,',
        'В котором каждый в нас живет.',
        'Ты напиши лишь только то введение,',
        'Что каждого в ту даль зовёт.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 10, 12),
    code: 'pribyl-malchik-v-te-zhe-zhanry',
    content: [
      [
        'Прибыл мальчик в те же жанры,',
        'Что дядя милый описал.',
        'Нашёл в пути все гонорары.',
        'Нашёл того, что долго ждал.',
      ],
      [
        'В том ожидании, он без мыслей,',
        'Он рассуждал о мире, бытие.',
        'Но взгляд его да изменился в кислый.',
        'Не разглядел он близ колье.',
      ],
      [
        'Порядок действий он построил,',
        'Затем свой мир он весь освоил.',
        'Колье он продал, не секрет,',
        'И наградил свой весь сюжет.',
      ],
      [
        'Да как колье он раздобыл то?',
        'Не уж то он обрел его?',
        'Да бросьте, были у него те пара мыслей,',
        'Что «Нет» же совестным всего.',
      ],
      [
        'Нет ничего, что может взглядом зацепится.',
        'Нет никого, кто в двери постучится.',
        'Прошёл малец тот весь сюжет.',
        'В закон вписалось слово «Нет»…',
      ],
      [
        'Когда он говорил повсюду',
        'О том же совестном приюте.',
        'Лишь слово «Нет» ты мне скажи,',
        'И будет счастье для луны…',
      ],
      [
        'Что с незапамятных времён под солнце',
        'Тот танец пляшет напролёт.',
        'Ведь слово «Нет» есть слог о горце,',
        'Который ждёт и ждёт, и ждёт.',
      ],
      [
        'Ждёт изобилие гонораров,',
        'Что мальчик утром вдруг обрёл.',
        'Не зря же мальчик вот в те жанры',
        'Да прибыл и был же награждён…',
      ],
      [
        'Благим же светом многоликим,',
        'Где слово есть у каждого из нас.',
        'Мальчишка стал уж больно диким.',
        'Он взял гитару и ударил бас.',
      ],
      [
        'Затем та музыка прелестно',
        'Лилась в ту комнату тайком.',
        'Зашёл в миг ракурс тот прелестный,',
        'Сказав три слова прям при нем.',
      ],
      [
        'Одно из них есть та же скромность,',
        'Что звуком передать нельзя.',
        'Привстал, сказав себе «Опомнись»,',
        'И вышел словом все броня.',
      ],
      [
        'Тот ракурс понял здесь три смысла.',
        'Вот первым он и так и жил.',
        'Все остальное есть то коромысло',
        'Для равенства суждений… Лик твой мил!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 10, 7),
    code: 'otkrylas-dver-i-vyshel-brat',
    content: [
      [
        'Открылась дверь и вышел брат',
        'Того парнишки с вечеринки.',
        'Он выбрал хлопца наугад',
        'И вставил в руки две пластинки.',
      ],
      [
        'Сказал, всё глядя на него вплотную,',
        'Что б пластик быстро заиграл,',
        'И музыка лилась в живую,',
        'И Гришка музыку познал.',
      ],
      [
        'Григорий сразу покачнулся.',
        'Затем дотронулся рукой.',
        'Он сам в себе, да, упрекнулся.',
        'И топнул громко он ногой.',
      ],
      [
        'Где диджек парни? Где девчонки?',
        'Вдруг начал Гришка говорить.',
        'Нам нужен танц, плевать на шмотки.',
        'Мы здесь что б танцу в мире быть.',
      ],
      [
        'Зашёл я в дом и тронул Веру.',
        'Та глянула ну очень скверно.',
        'Я дальше шагом верно плёл,',
        'Но выглядел с дали, как тот орёл…',
      ],
      [
        'Который танец начал, глядя',
        'На Гришку музыку слепя',
        'Всем искажением, где дядя',
        'Принёс нам треки на века.',
      ],
      [
        'И в тот момент, как дискотека',
        'Была немного под рассвет,',
        'Увидел я два человека,',
        'Но рассмотреть не мог, как бред…',
      ],
      [
        'Который нёсся, сознавая',
        'Красу всех обликов времён.',
        'Где наша жизнь? Вопрос, я знаю.',
        'И восхищаюсь я тем днём…',
      ],
      [
        'Когда те сеты многолико',
        'Неслись в тех ракурсах затей.',
        'Хоть и смотрелось это дико,',
        'Мы выполняли роль детей…',
      ],
      [
        'Что поиграть приплыли',
        'В заоблачные города.',
        'Всегда мы любим мир игривый.',
        'Всем мир, забота, мудрость и года.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 11, 5),
    code: 'eto-tak-i-zahotel-ne-gljadja',
    content: [
      [
        'Это так я захотел, не глядя',
        'На жизнь плывущую с тобою в даль.',
        'Рифмуется тот милый дядя,',
        'Что позабыл свою печаль.',
      ],
      [
        'Так говорить не каждый может.',
        'Так жизнью жить не все хотят.',
        'Постой, весь мир твои слова тревожат.',
        'Поступки в области бытия…',
      ],
      [
        'Своих прекрасных майнд сознаний.',
        'The travel’s show behind the jazz.',
        'Написан будешь ты в преданиях.',
        'Ох разошелся он для нас!',
      ],
      [
        'Да не судите строго без оглядки.',
        'Вы гляньте в область джанко мечт.',
        'Получит каждый игры в прятки',
        'И унесется в то, где есть ответ…',
      ],
      [
        'На тот безликий же вопрос,',
        'И близ девчонки рядом,',
        'Задать который сам не в силах наш запрос…',
        'Где я? Так во Вселенной видишь взглядом.',
      ],
      [
        'Во времени всё бытие',
        'Стремится к всем ответам.',
        'Где Истина? В твоем житие.',
        'Держи зерно к прогулке разума, Тибетам.',
      ],
      [
        'Но область мира все равно',
        'Загадкой всё же возникает.',
        'Так всё живет и то зерно,',
        'Да, очнь духовно возрастает.',
      ],
      [
        'Придя к ответу баз основы,',
        'И ты придумай, назови',
        'Слогов образований мирных, новых.',
        'Пусть будет вера впереди…',
      ],
      [
        'На всех собраниях ветвлений',
        'Разброса искр, где яркость дня',
        'Приносит беглую утеху',
        'Прекрас реальности храня.',
      ],
      [
        'Ведь будет та погода века.',
        'Пусть революция надежд',
        'И будет, найдет же человека,',
        'Поставит в ряд всех нас невежд.',
      ],
      [
        'И я, заметьте, рядом с Вами…',
        'Пошел я в сторону сейчас.',
        'И вот, я видел меж цветами',
        'Тот образ, что как был - погас.',
      ],
      [
        'И это лишь тот слог надежды,',
        'Что каждый может сотворить',
        'Свои же личные мечтания между',
        'Всех ракурсов раздумий, адпачыць.',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 10, 11),
    code: 'napishu-ja-paru-strok',
    content: [
      [
        'Напишу я пару строк.',
        'Прям в живую вот сейчас же.',
        'Глянул взгляд да на восток.',
        'Разум был вот в нашей чаше…',
      ],
      [
        'Всех тех мыслей на рассвете,',
        'В сказочном большом букете,',
        'Полны радуги зари.',
        'Верный путь да впереди.',
      ],
      [
        'Впереди во всех тех мирах,',
        'В сказочных площадках гривах,',
        'Что у коней для той волны',
        'Полетов мыслей и луны…',
      ],
      [
        'Для ночи ласково и взятка',
        'Дана по русски, стало шатко.',
        'Вот несколько больших парней',
        'Привстали, стали так сильней…',
      ],
      [
        'Что мысли все мои поплыли мило,',
        'И было все это игриво.',
        'Он взял, сказал спасибо и ушёл.',
        'Бугай в тот миг же цель обрёл.',
      ],
      [
        'Я выскочил, не глядя сзади,',
        'Что было в доме для того,',
        'Что б выскочили эти дяди',
        'И потащили меня в дом.',
      ],
      [
        'Кулак был поднят, но манера',
        'Того парнишки, в сталь лицом,',
        'Вела себя довольно смело.',
        'Ошибся парень тем крыльцом…',
      ],
      [
        'Что в зал водило поколения',
        'Без разума изысков обретения.',
        'Ему я дал такой пизды.',
        'И волос не упал. Ему кранты.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 10, 15),
    code: 'proshelsja-paren-vdol-zavoda',
    content: [
      [
        'Прошёлся парень близ завода,',
        'В котором шины создавали взаперти.',
        'Возникла некая невзгода,',
        'Что жизнь была вся позади.',
      ],
      [
        'От шин несло каким-то тестом,',
        'В котором выражен сюжет.',
        'Он продолжал идти в словесном',
        'Том выражении, где был лишь бред.',
      ],
      [
        'Затем он вынул из кармана',
        'Две с чем-то банки, с ветерком.',
        'Пошёл же дальше близ канавы.',
        'Был жизнью парень восхищён.',
      ],
      [
        'Но не прошло и часа с лишним',
        'Сей парень рухнул прям в кювет.',
        'И шину разглядел в своём же ближнем',
        'Вдруг подошедшим с ним для смет.',
      ],
      [
        'Собрать все данные нам нужно!',
        'Воскликнул парень с ветерком.',
        'И вдруг обрёл свой взгляд наружный,',
        'И обзывал себя тайком.',
      ],
      [
        'Ты запиши, не стой прилично!',
        'Опять он начал симпатично.',
        'Да напиши весь свой сюжет!',
        'Вот сколько ты принёс нам бед?',
      ],
      [
        'Подумал хипстер, оглянулся,',
        'Затем дотронулся рукой,',
        'И вверх поднялся ветер гнусный.',
        'Он встал, пошёл. Да бог с тобой.',
      ],
      [
        'И попрощавшись близ парнишки,',
        'Он путь свой дальний, жизнь обрёл.',
        'А парень дулся за делишки,',
        'И к шинам дальше он пошёл.',
      ],
      [
        'На повороте же к тому заводу',
        'Он мысль толковый же обрёл,',
        'Но не найти уже ведь ту свободу',
        'В кювете, хипстер обречён.',
      ],
      [
        'И знаете, все люди мира,',
        'Есть два события для нас.',
        'Вот первое - вы ищите кумира.',
        'А все второе есть лишь только джаз.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 10, 28),
    code: 'najti-zadat-i-vnov-prosnutsa',
    content: [
      [
        'Найти. Забыть. И вновь проснуться.',
        'В весь данный мир в миг окунуться.',
        'Найти загадку из-за дня,',
        'Что крутит что-то для тебя.',
      ],
      [
        'Тот весь заверток звонкий',
        'Несёт для дня твой голос тонкий.',
        'Он хочет всё всем рассказать,',
        'Но в дрожь бросает взгляд опять.',
      ],
      [
        'Ври встрече с данным человеком',
        'Он заиграл всю прелесть треком,',
        'Он слов из реплики забыл.',
        'Он так и был, но дальше плыл.',
      ],
      [
        'В тех классных, звонких, многоликих',
        'Тот час затронул отзыв дикий.',
        'В мирах небесной красоты',
        'Есть только сон, есть я и ты.',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 11, 11),
    code: 'najti-zadat-i-vnov-prosnutsa',
    content: [
      [
        'Привет! Надеюсь, вся погода рядом',
        'Распространяет только жизни яркой свет.',
        'Надеюсь, ты увидишь блаженным, ярким взглядом',
        'Нежданный в этот день цветной букет.',
      ],
      [
        'Цветы в нем будут так прекрасны,',
        'Что образ больше тот не будешь создавать,',
        'Ведь звук в букете будет дико классным.',
        'Он будет ритм, мелодию свою плясать.',
      ],
      [
        'И в пляс бы было превосходно',
        'Войти и двери распахнуть,',
        'Что б жизнь стремилась лишь к тому восходу,',
        'Что в области науки преподаст час отдохнуть.',
      ],
      [
        'И в это время так спокойно',
        'Есть время дико рассуждать.',
        'Желаю недели превосходной',
        'Творить, играть и создавать!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 11, 27),
    code: 'holodnyj-kosmos-est-pesnja-v-mashine',
    content: [
      [
        'Холодный космос есть песня в машине.',
        'Не знаю я куда и глянуть-то в окно,',
        'Что б растворилась вся мечта напитком с тем же кофеином,',
        'И наступил бы вечер наш вдвоем.',
      ],
      [
        'Поговорив о мире здесь насущном,',
        'Погрязли бы в неловкий, милый весь сюжет',
        'И растворились бы, как в кофе равнодушном,',
        'Узрев ту кульминацию трансформера в тот сет…',
      ],
      [
        'Что обыграл бы чудо того года,',
        'Когда знакомство наше было на заре.',
        'Прости, но думаю, что общество народа',
        'Все видит с искажением в искре…',
      ],
      [
        'Что обладаешь ты, нас всех миледи,',
        'Что восхищает всех нас в этом свете.',
        'Прости, такой вот стих прошел сейчас.',
        'Давно ведь наступил тот впечатлительный наш час…',
      ],
      [
        'Когда восходы долгих впечатлений',
        'Приносят весь изыск бытия.',
        'Дарить же хочется всех сказочных стремлений.',
        'Искать как быть там, где ничья.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2022, 11, 25),
    code: 'if-you-can-just-do-it-buddy',
    content: [
      [
        'If you can just do it buddy,',
        'Just think as area of brain',
        'That world builds pretty gaudy.',
        'It looks like elephant of drain',
        'Comes just forever with the water,',
        'The information to with us.',
        'We know that knowledge is a daughter',
        'By Universe at clever once.',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 11, 27),
    code: 'chto-so-mnoju-kak-to-stranno',
    content: [
      [
        'Что со мною? Как-то странно…',
        'Вот нечего же больше мне скрывать.',
        'Ты знаешь, как-то час сей вот настал внезапно',
        'И хочется пред ночью мне встречать…',
      ],
      [
        'Весь мир тот солнца, впечатлений,',
        'Что не приносятся сейчас.',
        'Вот нет, как будто, тех мгновений,',
        'Что общность создают всех нас.',
      ],
      [
        'И все, что в ракурсе и памяти прелестной',
        'Ведёт рассказ про меня в облике времён,',
        'Мол все, что было сказано чудесно,',
        'Вот в том прошло, когда ты был живьём.',
      ],
      [
        'Как будто все ты видел в прошлом.',
        'Не можешь ты ни с чем взойти.',
        'Аналоги сей жизни на дорожку',
        'В бескрайнем всем твоём пути.',
      ],
      [
        'Вот кто мне скажет, так у всех нас сразу?',
        'Вот все, что ты живешь, как будто видел ты уже.',
        'Я предлагаю всем сказать нам фразу,',
        'В которой писан наш сюжет.',
      ],
      [
        'И говор твой неловких приключений',
        'Пусть будет радостью сверкать.',
        'Все, что ты видел раньше в сновидениях,',
        'Есть только то, что нужно ждать.',
      ],
      [
        'И так ты можешь не встряхнуться,',
        'А жизнь свою оставить на причале бытия.',
        'Поверь, есть цель, не нужно промахнуться.',
        'Все, что ты знаешь есть же жизнь твоя.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 0, 13),
    code: 'stihi-ty-prosto-sochinjai',
    content: [
      [
        'Стихи ты просто сочиняй',
        'И свет восхода гордо знай.',
        'Знай солнца линий абстрагизм',
        'И не пиняй на критинизм.',
      ],
      [
        'Когда пред солнцем вдруг захочешь',
        'Семь миль восхода, ты порочишь.',
        'Порочишь свет весь для людей.',
        'Знай и пеняй ты все сильней.',
      ],
      [
        'Когда захочешь же приникнуть',
        'И отпустить людей отвыкнуть,',
        'Дай словом всем же свой сюжет,',
        'В котором нет два слова нет…',
      ],
      [
        'Которые не глядя в искры',
        'Все слышат из-за тех углов насвисты,',
        'В которых выражен сюжет',
        'Людей даров для дня побед…',
      ],
      [
        'С задачей хитрой без умений,',
        'Привыкнуть к образу мышлений.',
        'Все происходит здесь сейчас.',
        'Эй парень, поиграй ты джаз.',
      ],
      [
        'Твой джаз же будет равноправным.',
        'Он будет с возгласом бездарным.',
        'Он будет солнце воплощать.',
        'Я ж попросил, не обижать…',
      ],
      [
        'Всех тех людей с табличкой,',
        'Мол нет для жизни вот той спички,',
        'Что обладает сей сюжет.',
        'Зажги огонь! Живи как свет!',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 0, 16),
    code: 'razdolie-v-golove-s-prichudoi',
    content: [
      [
        'Раздолье в голове с причудой',
        'Весь вечер, будь то тот сюжет,',
        'В котором девушка с изыском и уютом',
        'В своем сознании в высь строит жизни яркой свет.',
      ],
      [
        'И светит солнце чрез очки забвений.',
        'Но свет же вновь проходит к ним,',
        'К тем людям, что ищут приключений,',
        'В которых душу, как маяк храним.',
      ],
      [
        'Никто не скажет там в сторонке:',
        'А как же жизнь идет лишь у тебя?',
        'Но есть заметка, и ты будешь в шоке,',
        'Ведь есть определение бытия.',
      ],
      [
        'Все, что сказать здесь стоит мило,',
        'Есть только лишь тот образ бытия,',
        'Иначе взгляд живой хранит так очень мило,',
        'Как говорил, тот мир, где мы ничья.',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2022, 9, 18),
    code: 'ty-znaesh-est-tri-slova-mira',
    content: [
      [
        'Ты знаешь, есть три слова мира.',
        'Мы не способны их понять.',
        'Мы рассуждаем, в общем, да, довольно сыро,',
        'Но свет луны нам позволяет всем мечтать.',
      ],
      [
        'Мы свет её возьмём за меру,',
        'Когда нет облика времён.',
        'Возьмём с собой мы фото смены',
        'Всех тех же радостей, что каждый одарён.',
      ],
      [
        'Тогда во всех суждениях летом',
        'Наш мир всем будет свет дарить.',
        'И ты окажешься довольно смелой.',
        'И сможешь все заботы позабыть.',
      ],
      [
        'Затем настанет та легенда,',
        'В которой облик времени живет',
        'Со всеми вместе до момента,',
        'Когда свой голос так воодушевлен.',
      ],
      [
        'И песня будет литься здраво',
        'Людей всех в общество позвать',
        'Отведать гибкого того же нрава,',
        'Что ты не сможешь словом наказать.',
      ],
      [
        'И в области тех светлых, темных зданий',
        'Людей ты будешь звать прочесть',
        'Три тома с изыском их названий:',
        'Природа, мир… Ты - мир, природа. Так и есть.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 0, 22),
    code: 'prostite-ludi-za-trevogu',
    content: [
      [
        'Простите, люди, за тревогу',
        'Лишь складом этих вот стихов.',
        'Я выражаю лишь свою свободу',
        'Быть тем, кого забудут, он таков.',
      ],
      [
        'Все в мире с толком и причудой.',
        'Все в мире в образе времен.',
        'Но кто придумал эту всю науку?',
        'Кто прямо сердцем обречен?',
      ],
      [
        'Я думаю, что каждый, глядя',
        'На незабытые моменты бытия',
        'Желает, что б пришел тот дядя,',
        'Который знает сколько будет дважды два.',
      ],
      [
        'Вы уж простите за тревогу.',
        'Есть время просто показать,',
        'Что значит счастье диалога,',
        'Где в разговоре хочется кричать.',
      ],
      [
        'Но крик-то будет очень здравый.',
        'Он будет лишь, как голос для тебя.',
        'Забудь, коль люди же считают нравом',
        'Так жить, где только образ для себя.',
      ],
      [
        'В том образе, поверь, найдешь ты пару текстов.',
        'Так напиши ты их, коль время же дано.',
        'Добиться дважды два лихих прогрессов',
        'Есть удивлять наш мир да заодно!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 0, 26),
    code: 'ja-ne-mogu-stihov-pisat-o-mire',
    content: [
      [
        'Я не могу стихов писать о мире.',
        'Все говорят, что образ мой живой',
        'Лишь в случае, когда они сидят в трактире,',
        'Пьют чай, дай Бог, заказ ведут рукой.',
      ],
      [
        'Но образ есть сейчас, как личность.',
        'Все знают, что по телеку идут',
        'Программы, в которых истерично',
        'Вселенная и космос рядом здесь живут.',
      ],
      [
        'Проблема есть лишь только в действах,',
        'Мол люди сами не дошли',
        'До тех мышлений для прогресса:',
        'Оставить бред весь так в дали.',
      ],
      [
        'Все настоящее так близко, рядом,',
        'Желает всю ту даль разоблачить,',
        'И глянуть на него таким уж добрым взглядом,',
        'Что б в настоящем, мир любя, и жить.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 0, 25),
    code: 'hey-team-i-am-not-available-today',
    content: [
      [
        'Hey team! I am not available today…',
        'There’s the strange of fast away.',
        'Can somebody tell me the story',
        'About someone travel journey?',
      ],
      [
        'Yes, mister! Yeah, my lady!',
        'I know the word regarding stranger gladii.',
        'Just tell some words to anyone',
        'And keep the sense in soul of fun!',
      ],
      [
        'I am sorry man or “now and clever”,',
        'I think that mind is, yeah, forever.',
        'I wish the day of full of dreams',
        'That realising great one teams!',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 0, 26),
    code: 'hi-music-friends-hi-music-sisters',
    content: [
      [
        'Hi music friends! Hi music sisters!',
        'My brain can think as drain minister…',
        'What is the travelling of mind?',
        'Can you describe what’re thoughts of blind?',
      ],
      [
        'Yeah, blind you think in minute?',
        'No question, that’s the stranger dispute.',
        'Can you provide your own dreams',
        'Into the text and to the bis?',
      ],
      [
        'I’m normal man, I’m neural lady.',
        'You think as stranger of arcady',
        'Who comes at night to say some words',
        'And do the same as bull at birds.',
      ],
      [
        'I’m sorry sonny, sorry sister…',
        'You know that you’re the world minister.',
        'Just do it quickly, with some noise',
        'Then everybody speaks as choice.',
      ],
      [
        'The choice will be between the fairy.',
        'Do it the mean of story airy?',
        'That air I wish to you my friend.',
        'Do not attend on stranger scene!',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 1, 18),
    code: 'volshebnyi-den-ja-rascvetaju',
    content: [
      [
        'Волшебный день, я расцветаю!',
        'Что делать сам с собой не знаю.',
        'Сегодня пасмурно и бред,',
        'Но так прекрасно, нет всех бед.',
      ],
      [
        'Весь мир прекрасный, дружелюбный.',
        'Он хочет все постигнуть в ту минуту,',
        'Когда опять увижу я тебя,',
        'Скажу два слова от себя.',
      ],
      [
        'Два слова будут возвращаться',
        'Опять, опять, опять, опять.',
        'Ведь не смогу я попрощаться.',
        'Есть слово жизнь. Есть слово знать.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 1, 17),
    code: 'ne-tak-prekrasno-znat-ves-mir',
    content: [
      [
        'Не так прекрасно знать весь мир,',
        'Но есть же в этом часть сатир,',
        'Что человек с собой несет',
        'И общий ракурс придает.',
      ],
      [
        'Я не скажу, что мне приятно',
        'Все слышать с уст будь то очнь знатно.',
        'Я лишь хочу узреть ответ,',
        'В котором нет же слова нет.',
      ],
      [
        'Поверь, весь мир такой игривый,',
        'Но ты ведь знаешь без сатиры,',
        'Что человек тебя зовет,',
        'Старается и песню создает.',
      ],
      [
        'Поет про то, что пара ливней,',
        'Что летом гордо собрались',
        'Покажут ход движения тех линий,',
        'Что оглашаются, как жизнь.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 1, 18),
    code: 'prospekty-reki-i-tramvaji',
    content: [
      [
        'Проспекты, реки и трамваи',
        'В публичных ракурсах затей.',
        'Путь будет солнце между вами',
        'И не отбрасывает тень.',
      ],
      [
        'Полет тепла ты приюти наглядно.',
        'Пусть будет жарко для тебя.',
        'Ведь зим увидела ты знатно,',
        'И лето ждет в полете так трубя.',
      ],
      [
        'Весна в пороге расчудесном.',
        'Пусть будет мир прекрасных грез.',
        'Так вот желаю я увидеть в том прелестном',
        'Мгновении все сказки жизни проз.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 1, 18),
    code: 'slovesnjy-genij-v-dom-idet',
    content: [
      [
        'Словесный гений в дом идет.',
        'Вдоль всей стены построены молитвы.',
        'В тот день волшебность превзойдет,',
        'И будет танец больно дивный.',
      ],
      [
        'Волшебности, детали и склонения',
        'Отображают мир весь наш.',
        'И в тот момент, когда полеты в сновидениях',
        'Приютен облик, как герой весь ваш.',
      ],
      [
        'И танец резвый, заграничный',
        'Отображает голос грез.',
        'Пусть будет он такой публичный,',
        'Что разговор пойдет всерьез.',
      ],
      [
        'В том разговоре за пределы',
        'Все будут рамки выходить.',
        'Желаю я такой святой недели,',
        'Что ум как солнце будет жить.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 1, 18),
    code: 'vpered-voskiknul-tot-malchishka',
    content: [
      [
        'Вперед! Воскликнул тот мальчишка.',
        'Ведь у него лежит та книжка,',
        'Где сказка мира суждена.',
        'Он ждет ее, весну для сна.',
      ],
      [
        'Для сна теплее будет летом.',
        'И каждый день идет эстетом.',
        'Во всем том мире куча грез,',
        'А в нашем он один - всерьез.',
      ],
      [
        'Куда ни кинь везде публичность,',
        'Но мы то знаем безграничность',
        'В том наводнении затей,',
        'Когда живешь, тебе не лень.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 1, 17),
    code: 'vsjo-chto-vokrug-povsudu-rjadom',
    content: [
      [
        'Все что вокруг, повсюду, рядом',
        'Есть шар Земли да с высоты.',
        'Я жду, когда ты скажешь взглядом,',
        'Как выглядит тот день, что позади.',
      ],
      [
        'Тот день ещё и не был, я же',
        'Все весь рассказ веду сейчас',
        'О том, что ритм тебе расскажет,',
        'Как угадать что будет в час.',
      ],
      [
        'А в час я только лишь в Варшаве.',
        'Поверь, найду чем заниматься я весь день.',
        'Но есть одна причина, я в забаве',
        'По той причине, что я есть та лень…',
      ],
      [
        'Что происходит с человеком',
        'Будь добр он либ слишком мил.',
        'Ленюсь сказать, что будет добрым веком,',
        'Ведь ты поймешь что будет, поманил.',
      ],
      [
        'Поманил есть слово бесконечность.',
        'Вот что же интересно для тебя?',
        'Найдется в жизни вся беспечность.',
        'Живу я только для тебя.',
      ],
      [
        'Живу я жизнь как так живется,',
        'Послышались вдруг гордые слова.',
        'Но есть же то что в общее зовется,',
        'И порождается глава.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 1, 17),
    code: 'polet-zhe-nachinaetsja-da-utrom',
    content: [
      [
        'Полет же начинается да утром,',
        'И есть желание все обозреть,',
        'Найти сто лет во всем премудром,',
        'И быстро быстро да лететь.',
      ],
      [
        'Я стих пишу что б было мягко',
        'Все ощущать, как в каждый день',
        'И так же слушать речь очнь гладко,',
        'Отбрасывая нужный тень.',
      ],
      [
        'Все что вокруг да очень мило',
        'И смотрится все больн игриво.',
        'Все что вокруг есть та Земля',
        'Которая в познании живет для дня.',
      ],
      [
        'День тот прекрасный, милый, дикий.',
        'Он ждёт пока все станет ликом,',
        'Который я увижу в день,',
        'Когда смеяться будет мне не лень!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 1, 21),
    code: 'poroj-nebrezhnyje-motivy-zakluchenij',
    content: [
      [
        'Порой небрежные мотивы заключений',
        'Приводят к искренним забытым небесам,',
        'Что видишь ты согласно изречениям,',
        'Воздвигшим образ в сущность пополам.',
      ],
      [
        'Ты никогда не встретишь человека,',
        'Которого зовут, как в те года пришлось',
        'Бродяге стать тем знатным треком',
        'Для человечества, но не нашлось…',
      ],
      [
        'Того мгновения встречи вдоль раздолья,',
        'Где позади все миллионы лет.',
        'Одно ты знаешь, день грозит подпольем,',
        'Что б сны лишь снились создавая свет.',
      ],
      [
        'И в том же свете разноликом',
        'Ты повстречаешь образ, где согрет',
        'Тот человек в одежде с милым бликом,',
        'И слышен здесь опять игривый сет…',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 1, 15),
    code: 'the-loren-ipsum-comes-like-mind',
    content: [
      [
        'The Loren ipsum comes like mind',
        'To know what into then, behind.',
        'Keep feeling over such express',
        'That brings the roof to get a bless.',
      ],
      [
        'That bless is minor for the list',
        'Of tasks that match the people first.',
        'The solving into clever mind',
        'As was the mention in behind.',
      ],
      [
        'Behind the dream of moving bless God',
        'Does not exist in shaking world.',
        'Let’s rest to be the active self broad',
        'To keep the strength into bright curld!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 1, 23),
    code: 'vot-povod-raznyi-raznocvetnyi',
    content: [
      [
        'Вот повод разный, разноцветный',
        'Желает все в глаза сказать',
        'И принести тот фильм сюжетный,',
        'Всю жизнь стараясь рассказать.',
      ],
      [
        'В том мире, что не можешь смыслом',
        'Лишь описать для благо дня,',
        'Узнать же можно суть по числам',
        'К ведущим для рассвета же не зря.',
      ],
      [
        'И в то же утро, для себя же',
        'Есть парочка довольных вдруг идей,',
        'Что в свете есть друзья, но также',
        'Есть свет для дня всех ракурсов затей.',
      ],
      [
        'В тот день же светлый, добрый, знатный',
        'Все будет смысл узнать наглядно,',
        'Как человек порою ждёт',
        'Всех тех нюансов, что взбредет…',
      ],
      [
        'В ту мысль, что будет коромыслом',
        'Двух тех миров за пазухой людей.',
        'Так вот делись всегда тем добрым смыслом,',
        'Что б жизнь тянулась к образам идей.',
      ],
      [
        'Хоть и стоишь ты на земле столь властно,',
        'Желаю я тянуться до небес,',
        'Где жизнь идет не так напрасно',
        'И да полна она чудес.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 1, 24),
    code: 'druzja-kto-skazhet-mne-tot-vosglas-bayija',
    content: [
      [
        'Друзья! Кто скажет мне тот возглас бытия?',
        'Какой же нужен для тебя?',
        'А тот, который всем несёт уютно',
        'Жизнь-мотиватор без причуды.',
      ],
      [
        'Да ты, малой, всегда о чем-то',
        'Да говоришь, не зная бед.',
        'Ты вот спроси лишь, где то чудо,',
        'Что описал ты, глядя в бред.',
      ],
      [
        'Довольно смело, ненароком',
        'Ты преподносишь дальний свет.',
        'Я вижу, что несешь ты слогом,',
        'Но не могу понять, где нет побед.',
      ],
      [
        'Не нужен бред твой, нужен ракурс,',
        'Который может мир понять.',
        'Поверьте мне, ты спишь под градус.',
        'Поверь, тебе мир наш не знать.',
      ],
      [
        'А я вот знаю, я легенда.',
        'Ты девушка, поверь да с чем-то.',
        'И это что-то разглядеть',
        'Удастся мне и песню спеть.',
      ],
      [
        'Ведь песня будет на гитаре,',
        'И может быть в ютуб канале.',
        'Играть же в игры нам дано,',
        'Но мне уже да всё равно.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 2, 4),
    code: 'kak-stih-seichas-vot-napisat-tebe',
    content: [
      [
        'Как стих сейчас вот написать тебе?',
        'Да все здесь так же, как бытие.',
        'Все как-то также без высот,',
        'И не получишь ты невзгод.',
      ],
      [
        'Все взгоды есть лишь нечто с кем-то.',
        'Все взгоды это лишь легенда.',
        'Все взгоды есть, где нет тебя.',
        'Шальная пуля для меня.',
      ],
      [
        'Что привлекло тебя все также',
        'К другим, с огромным саквояжем',
        'Есть человек с самим собой.',
        'Век, радуга с тобой.',
      ],
      [
        'Проснуться утром так же классно,',
        'Но вот боишься ты сейчас же,',
        'Мол утро только для людей.',
        'Я знаю жизнь: да, да - светлей.',
      ],
      [
        'Светлей есть действо без невзгоды,',
        'Высоты милой в миг заботы,',
        'Что ты умеешь создавать.',
        'Семья, все братья есть та мать…',
      ],
      [
        'Что умным даже не порочить.',
        'Да, как-то сложно уж назвать…',
        'Сей стих опять ты мудрый строчишь.',
        'Кто есть мудрец? Есть твоя мать.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 2, 5),
    code: 'stihi-davai-je-sochinjat',
    content: [
      [
        'Стихи давай же сочинять',
        'И мир Вселенной предлагать.',
        'В заявке выявлен предлог,',
        'Чтоб каждый сам себе помог.',
      ],
      [
        'В ритм весело осознавая',
        'Всю помощь, как бы не блистало.',
        'Написан будет сей куплет',
        'Для радости и в знак побед.',
      ],
      [
        'На знаке видим три дороги.',
        'И как же нам ее узнать?',
        'Лишь двигаться для ритма в ноги,',
        'Сэнсэя видно наблюдать.',
      ],
      [
        'В том образе, заметном сверху,',
        'Учитель может всё сыграть',
        'Вселенной времени, где есть поверхность…',
        'Сознанием мы можем вот его назвать.',
      ]
    ],
  },
  {
    title: 'Лайф',
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 1, 26),
    code: 'life',
    content: [
      [
        'Давай попробуем. Повсюду',
        'Магический мир снов и красоты.',
        'Но в голове живет тот человек уютный.',
        'Не помня что и делала: лишь ты…',
      ],
      [
        'Я человек вполне здоровый,',
        'И много килограммов я могу поднять,',
        'Но есть одна причина, след толковый',
        'Ведь шаг же сделан так, как нужно знать.',
      ],
      [
        'И знание всем людям в превосходство',
        'Да придают красот и высоты,',
        'Но вот опять же слово благородства',
        'Есть только человек один, лишь ты.',
      ],
      [
        'И здесь писать же можно километр,',
        'Ведь лайф для жизни очень уж идет.',
        'Возьму за листик мысли, сантиметр',
        'Той красоты здоровья и в полет!',
      ],
      [
        'Буду летать я в детстве, да повсюду.',
        'Буду я прыгать, звать и напрягать',
        'Ибо задумка будет лишь к уюту,',
        'Который образ весь помог да и создать.',
      ],
      [
        'В создании же были те манеры,',
        'Где прелесть жизни без измены.',
        'Полет сей радости не может забывать,',
        'Как же прекрасно лишь тебя и долго знать.',
      ],
      [
        'В длине же времени не будет заключаться',
        'Сей диалог для прелести идей.',
        'В ней будет жизнь идти и будет подниматься',
        'В полете мыслей, знатных всех идей.',
      ],
      [
        'Идея мира, что один да номер рядом',
        'Всех позовет в единую толпу.',
        'Толпа же слово есть и будет гадом',
        'Лишь потому, что шлет оно ботву.',
      ],
      [
        'Вы уж простите, слово же такое.',
        'Нет тех пространств в том заключении толпы.',
        'Да будем ждать и мир наш строить, как благое,',
        'Но в точке центра будешь лишь и только ты.',
      ],
      [
        'Ты в центре всех же превосходств для мира рядом.',
        'Ты в центре милых и всех светлых площадей.',
        'Давай ты будешь в радости смеяться взглядом,',
        'А я лишь подойду с рукой и буду ждать речей.',
      ],
      [
        'В той первой от тебя же с милым взглядом',
        'Да я готов нести заслугу всех времен.',
        'Ведь выражен тот толк для всех и будет лишь квадратом',
        'Для всех людей пространства, где лишь ты умен.',
      ],
      [
        'В твоем уме и просто разноречие.',
        'Ты положи свою тетрадку на плечо.',
        'Пусть будет лишь погоном в тех временах в предплечье',
        'Для разделения мира, кто и где живет.',
      ],
      [
        'Вся жизнь да будет заключаться в остром',
        'И милом образе понятных всем речей.',
        'Давай возьмемся в руки, будем мы народом',
        'Всех тех времен, когда весь образ да умней.',
      ],
      [
        'Весь ум, как говорил в простом разнречье,',
        'Ты не спеши, а то получишь ты увечье.',
        'Не забывай все буквы словаря,',
        'И жизнь твоя идет солдатом трезво дя стоя.',
      ],
      [
        'Стоит солдат у площади манеры',
        'Всех тех времен, нет взгляда у него.',
        'Желаю я ему три образа Венеры,',
        'Что б разобрать тот космос наяву всего.',
      ],
      [
        'Ох наяву, сказал ты милый парень.',
        'Ох позабылось все, что нужно для людей.',
        'Так вот желаю я свой статус - легендарен.',
        'Так вот желаю я всем тех календарей.',
      ],
      [
        'Конца же нет вот в данном стих-сложении.',
        'Есть только образ дикий, заключение.',
        'Желаю всем добра и мира для небес.',
        'Желаю всем добрейших, милых всех чудес!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 2, 8),
    code: 'i-dont-know-the-day-of-writer',
    content: [
      [
        'I don’t know the day of writer.',
        'I know one copywriter',
        'That can describe the world of us',
        'To bring the dancing mood and jazz.',
      ],
      [
        'The dance can live such as an apple',
        'With hands come to the chapel',
        'To think as relevant approach',
        'That sees the Universe at watch.',
      ],
      [
        'The timer goes like fun life, clever,',
        'Cool lady for the mind forever.',
        'I wish your’all eyes happiness',
        'Providing grateful such events!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 2, 17),
    code: 'idti-vpered-i-veselitsa',
    content: [
      [
        'Идти вперед и веселится,',
        'Всем миром сразу восхититься.',
        'Идти вперед, где всем нам свет',
        'Приносит только слово «Нет».',
      ],
      [
        'То слово «Нет» есть что-то с чем-то.',
        'То слово «Нет» есть та легенда.',
        'Свое ты действие забудь,',
        'Ведь слово «Нет» и есть твой путь.',
      ],
      [
        'Попробуй ты легко, премудро',
        'Лишь выставить акцент уютно',
        'На образ, что ты превзошел,',
        'И смотришь, свой же мир нашел.',
      ],
      [
        'Нашел он несколько стратегий,',
        'В которых нет всех привилегий,',
        'Есть только то, что хочешь ты,',
        'Но не забудь свои мечты.',
      ],
      [
        'Свои мечты есть только путь твой.',
        'Свои мечты есть только путь мой.',
        'Когда не знаешь ты сюжет,',
        'То слышно слово только «Нет».',
      ],
      [
        'О слове том и есть рассказ.',
        'Желаю мир, что б был для вас.',
        'Желаю мир я обрести',
        'И слово «Да» с собой нести.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 2, 25),
    code: 'i-vot-prishel-ja-v-samolet',
    content: [
      [
        'И вот пришел я в самолет.',
        'Здесь кто-то с кем-то да живет.',
        'Кто-то с кем-то есть сюжет,',
        'В котором кроется всех красок цвет.',
      ],
      [
        'Чрез пару мигов, восхищений',
        'Найдутся несколько умений',
        'Того пилота впереди.',
        'Уж больно скоро же все будет позади.',
      ],
      [
        'А впереди не только дядька,',
        'Что за штурвалом как просядь-ка.',
        'Вот что за слово мне не знать,',
        'Лишь весь рассвет хочу сказать…',
      ],
      [
        'Что встретиться чрез пару мигов.',
        'Не будет там нелепых всех тех ликов.',
        'Опять мне слово не познать.',
        'Желаю я весь образ знать.',
      ],
      [
        'А что за образ здесь всем нужен?',
        'Он пусть себе идет, он дружен.',
        'Опять же слова нет совсем,',
        'Но в том легенда всем нам всем.',
      ],
      [
        'В том слове есть три дня раздолья.',
        'В том слове искры многословья.',
        'Желаю я весь образ дня!',
        'Не жди, не жди же ты меня…',
      ],
      [
        'Я тот кто рядом, и повсюду,',
        'Стремящийся к нелепому уюту.',
        'Я тот, кто в образе времен',
        'И сам собою восхищен.',
      ],
      [
        'Мне также знать уж не придется',
        'Всех смыслов крайних, все сольется',
        'В единый ракурс бытия.',
        'Поверьте люди, жизнь своя.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 2, 26),
    code: 'stihi-ja-vsem-pri-vzlete-napishu',
    content: [
      [
        'Стихи я всем при взлете напишу.',
        'Я сам в себя сейчас гляжу.',
        'Мне страшно кажется, но бред,',
        'Ведь сбудется весь наш ответ.',
      ],
      [
        'Ответы есть для страсти глядя.',
        'Ответы есть про то, что дядя',
        'Все написал для мила дня.',
        'Всё было, есть и здесь Земля.',
      ],
      [
        'Ее лишь видно за границей.',
        'Она является частицей',
        'Всего того, что сделал я.',
        'Все что вокруг есть жизнь твоя.',
      ],
      [
        'На том просторе безграничном',
        'Я выгляжу при страхе лично,',
        'Как небывалый, милый взлет.',
        'И вот сейчас я вижу брод.',
      ],
      [
        'В том броде страшно, многолико.',
        'Всегда мне кажется уж дико',
        'В том образе, где я лечу.',
        'Ребята, всем Земли, свечу…',
      ],
      [
        'Поставить было б мило,',
        'Но в Бога вот того игриво',
        'Не лезут пару знатных слов,',
        'В которых ты и я - улов.',
      ],
      [
        'Улов всех тех людей прекрасных,',
        'Что не рождаются причастным.',
        'Для них лишь только яркий свет.',
        'Желаю я тебе постичь куплет…',
      ],
      [
        'Который странно и приятно',
        'Прочтется вечером внезапно.',
        'Возможно он откроет путь,',
        'Который ты же можешь упрекнуть.',
      ],
      [
        'В том мире здравом, безграничном',
        'Привет лишь слышится больн лично.',
        'Так вот в том слове есть куплет.',
        'Теперь попробуй ты узнать, где бред.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 2, 29),
    code: 'hvatit-v-igry-zdes-igratsa',
    content: [
      [
        'Хватит в игры здесь играться.',
        'Есть то, что действием зовут.',
        'Мне не хотелось бы надраться,',
        'И пусть меня так сильно бьют.',
      ],
      [
        'Все, что случилось в западне той',
        'Уж не расскажет мне никто.',
        'То был тот мрак раздетый тот мой,',
        'Что потерял я ни за что.',
      ],
      [
        'Взгляни на руки и забудь ты',
        'Свои небрежные слова.',
        'А вы, все люди, позабудьте',
        'Тот образ, что был дважды два.',
      ],
      [
        'В той алгебре найдутся мысли',
        'Для достижения времен.',
        'Так вот с тех пор себе ты опыта начисли,',
        'И будь самим собой ты награжден.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 2, 31),
    code: 'priehal-ja-v-gorod',
    content: [
      [
        'Приехал я в город.',
        'Сел я под дерево.',
        'Слышен здесь хохот.',
        'Иди преднамеренно.',
        'Ведь так восхитителен',
        'Весь твой куплет.',
        'Рассказ наш стремителен,',
        'Знает он бред.',
        'В слове снаружи',
        'Свой образ найди.',
        'Он непослушный,',
        'Но знай, но иди.',
        'За гранью рассвета',
        'Ракурс зови.',
        'Найдешь ты три цвета:',
        'Свой ты яви.',
        'Для радуг картины',
        'Брось яркий свет.',
        'Ты знаешь сатиру,',
        'Твой голос согрет.',
        'В слове том сказанном',
        'Будь красота.',
        'Быть Вам обязанной',
        'В причудах кнута,',
        'Чем ты всех, слушая,',
        'Будешь плясать.',
        'Свет лишь за грань',
        'Стихи да писать!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 5),
    code: 'prosnutsa-glanut-na-sebja-snaruzhi',
    content: [
      [
        'Проснуться, глянуть на себя снаружи.',
        'Весь образ ведь получится послушным.',
        'Ты делаешь так лихо, с собою говоря,',
        'И действия твои идут вперед не зря.',
      ],
      [
        'Когда ты встретишь образец с причудой',
        'Ты знай, ведь мир твой столь уютный,',
        'Что нет дорог в мир без чудес,',
        'И только не валяйся ты без действия, как бес.',
      ],
      [
        'Все, что ты делаешь в природе',
        'Есть иск твоей легенды, вроде.',
        'Ты так живешь, ты есть прогресс.',
        'Я ж говорю, весь мир чудес.',
      ],
      [
        'Поверь ты в знатное мгновение,',
        'Когда ты утром так силен.',
        'Привстань, оденься, то призрение',
        'Пусть и останется же сном.',
      ],
      [
        'В день расчудесный позабудь ты,',
        'Как существуют люди. Будь ты',
        'Тем человеком всех миров…',
        'Желаю солнце и добро без слов.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 2, 22),
    code: 'davaj-poprobuj-napisat-sejchas',
    content: [
      [
        'Давай, попробуй написать сейчас!',
        'Все, что вокруг вот как-то странно.',
        'Никто не знает кто для нас',
        'Является тем ликом необъятным.',
      ],
      [
        'Все в даль идет, как каждый день.',
        'И есть лишь только несколько трагедий.',
        'Вот первая есть то, как мило твоя лень',
        'Не может разглядеть всех ярких же созвездий.',
      ],
      [
        'Ты перестань употреблять в словах',
        'Для описания лишь пару междометий.',
        'В тот час ведь сразу будешь в западнях.',
        'Никто не понимает твоих, опять, созвездий.',
      ],
      [
        'Так что же ты стремишься всем сейчас',
        'Да передать, что в небе происходит?',
        'Пусть будет это все лишь твой любимый джаз,',
        'И пусть легенда будет и снисходит.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 1, 26),
    code: 'iskristyi-den-ne-govori-ty',
    content: [
      [
        'Искристый день? Не говори ты...',
        'Он словно тень в той области забытой.',
        'Привстань с сидения для чудес',
        'И в миг получишь ты прогресс.',
      ],
      [
        'Прогресс наш будет выражаться',
        'В неимоверном свете дня,',
        'Когда бульвар не сможет задержаться',
        'Быть площадью прожитой. Ерунда…',
      ],
      [
        'Всем превосходством истинных идей,',
        'В которые ты веришь взглядом,',
        'Будь так же парочка несломанных затей,',
        'Прожитых веком, так ему и надо.',
      ],
      [
        'И вот когда росток корней счастливых',
        'Да обретет свой облик светлых дней,',
        'Тогда и будет транспорт пережитых',
        'Все исключать, что было поскромней.',
      ],
      [
        'Но ты не стой на месте рядом с нами.',
        'Возьми свою дорогу в жизни грез.',
        'Путь будет счастье в повозку со словами,',
        'И пусть же счастье дышит ветром милых слез.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 6),
    code: 'vot-brodish-ty-kak-v-nastojashem',
    content: [
      [
        'Вот бродишь ты, как в настоящем',
        'И нет того, что ищешь ты.',
        'Все превосходит в предстоящем,',
        'Свои исписаны черты.',
      ],
      [
        'Черты характера природы,',
        'Что награжден ты, человек.',
        'Не спи, проснись, забудь заботы',
        'И да найди ты свой ночлег.',
      ],
      [
        'Ты выспись знатно, глянь в себя же,',
        'И образ ты создай сейчас.',
        'Затем присядь ты рядом так же,',
        'И напиши ты в слог для нас…',
      ],
      [
        'Три слова любящих столетие,',
        'В которых есть то междометие,',
        'Что и зовут тобой порой…',
        'Будь верен, друг, и будь собой.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 6),
    code: 'kto-pervij-kto-vtoroj-kto-rjadom',
    content: [
      [
        'Кто первый? Кто второй? Кто рядом?',
        'Кто шепчет мне сейчас же взглядом?',
        'Кто превзошел весь мир для всех?',
        'А это ты есть, человек.',
      ],
      [
        'Ты тот, кто верит в чудо века.',
        'Ты тот, кто распознает человека',
        'Будь он неправду говорить.',
        'Да брось его, ему так жить.',
      ],
      [
        'Ты тот, кто может чудесами',
        'Скрестить Вселенную с часами,',
        'Что на руке да носишь ты,',
        'И это все твои мечты.',
      ],
      [
        'Мечтать не плохо, плохо верить',
        'Что есть мечта, но нет же дня.',
        'Желаю быть мечтой, себя не мерить',
        'И быть мил человеком для себя.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 7),
    code: 'chto-v-mire-est-chto-darit-sontse',
    content: [
      [
        'Что в мире есть? Что дарит солнце?',
        'Что так прекрасно на заре?',
        'А то есть жизнь, полна эмоций.',
        'Ты есть и существуешь в том добре…',
      ],
      [
        'Что есть вокруг тебя и рядом,',
        'Что чувствуешь своим же взглядом.',
        'Ты тот, кто есть, ты в мире, здесь,',
        'И про тебя есть эта песнь.',
      ],
      [
        'Да оглянись вокруг, повсюду',
        'И станет на душе уютно.',
        'Вся жизнь прекрасна, ты есть все',
        'И позабудь свое шизо.',
      ],
      [
        'Оно не здесь, оно повсюду,',
        'Но только не с тобой в уюте.',
        'Ты тот, кто в мире, есть сюжет.',
        'Да будет свет! И эт не бред.',
      ],
      [
        'Здесь слово есть о милом взгляде.',
        'Пусть будешь ты в том аромате,',
        'Что ощущает каждый в нас.',
        'Да пусть же будет твой лишь джаз!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 7),
    code: 'uvy-ne-znaju-ja-sejchas',
    content: [
      [
        'Увы не знаю я сейчас,',
        'Что есть для всех, нам лишь с тобой.',
        'Уверен, знаю все есть джаз.',
        'Да будь весь мир лишь той порой…',
      ],
      [
        'Что будешь ты так мило взглядом',
        'Искрится в мире ярких лет.',
        'Потом ты скажешь, мне не надо,',
        'И образуется сюжет.',
      ],
      [
        'В нем тот герой такой игривый',
        'Предскажет, как лететь с тобой.',
        'Затем ты скажешь больно хитро,',
        'Что есть весь мир лишь той порой.',
      ],
      [
        'В ту пору мир такой чудесный.',
        'В ту пору человек идет с тобой.',
        'Да будь же свет такой прелестный,',
        'И пусть живет луна с тобой.',
      ],
      [
        'Она поможет в ориентире,',
        'Она ведь знает для тебя,',
        'Как все найти и быть кумиром',
        'Всех тех людей, кто есть не зря.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 10),
    code: 'chto-skazat-mogu-ja-razon',
    content: [
      [
        'Что сказать могу я разом',
        'Да не вспомнит же никто.',
        'Жизнь несёт предлог заразный',
        'В том, что нет здесь ничего.',
      ],
      [
        'В моем сознании столь быстром',
        'Раскрепостился весь сюжет,',
        'Затем все как-то в миг зависло:',
        'Не угадать мне больше цвет.',
      ],
      [
        'В том состоянии без затеи',
        'Мне сложно все произвести.',
        'Пусть дальше сыпятся идеи,',
        'И пусть ты будешь впереди.',
      ],
      [
        'Пусть только ты в своих желаниях',
        'Дотянешься до ракурса рукой.',
        'И мир захватит обонянием',
        'С тем запахом души живой.',
      ],
      [
        'Затем пройтись же нужно мило',
        'У города, что дал прогресс.',
        'Поехать дальше, классно было.',
        'Я жду ещё живых чудес.',
      ],
      [
        'С тем чудом в жизни без затеи',
        'Ты взглянешь на руку впервой.',
        'Коснешься верной ты идеи',
        'И дальше путь несет рекой.',
      ],
      [
        'У берега встревожат страсти',
        'Твоей затеи с западней.',
        'Ты ей ответишь, нет той власти.',
        'Представь ты лишь себя волной…',
      ],
      [
        'Что мчится нежно и игриво',
        'За ветром звезд себя обнять.',
        'Так вот желаю мир я дивный.',
        'Игру в загадки предлагать.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 1, 19),
    code: 'kogda-nas-dvoje-napadaet',
    content: [
      [
        'Когда нас двое нападает',
        'На тело искреннего взгляда,',
        'Тогда несется, отлетает',
        'Искра тех слов, что и не надо.',
      ],
      [
        'Не надо портит сразу стих.',
        'Не чувствуется рифма рядом.',
        'Тот ритм как будто бы утих',
        'И говорит опять тем взглядом.',
      ],
      [
        'Тот взгляд ты можешь угадать',
        'Сто раз и скажешь очень мило,',
        'Что жизнь одна и нужно взять',
        'Тот мир, вслед космосу дышать игриво.',
      ],
      [
        'Вот первый вздох ты создаешь',
        'С прямым и смелым восхищением.',
        'Второй ты рядом позовешь,',
        'Да быть великим изречением!',
      ],
      [
        'Два слова первых: я и быть.',
        'На третьем месте мир и люди.',
        'Так вот желаю всем светить,',
        'И находится в изумруде!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 1, 24),
    code: 'drugimi-slovami-rozhdaetsja-vshod',
    content: [
      [
        'Другими словами рождается всход.',
        'И сердце расправится, слог вот-вот-вот.',
        'Все, что же скажет есть море игры.',
        'За ним расправляется полночь зари.',
      ],
      [
        'За миром безбрежным в сладости грез',
        'Есть мир-бесконечность, полон он слез.',
        'Но в миг ты забудешь пару друзей,',
        'Как день начинается в миг и скорей.',
      ],
      [
        'Где же солдаты нелепой войны?',
        'Вас приглашали? Все мы видны.',
        'Глазу с тремя бесконечностями.',
        'Рифма есть слог, ты свой ракурс возьми.',
      ],
      [
        'Слово в поэзии “Ракурс” полно',
        'Всходом идей, что познать нам дано.',
        'Всем бесконечности, райской души.',
        'Мир в середине и нету войны.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 13),
    code: 'kto-znajet-kak-zhe-mir-stremitsja',
    content: [
      [
        'Кто знает, как же мир стремится?',
        'То скажет вам одна девица.',
        'Ей все равно на сей куплет,',
        'И в жизни нет негласных бед.',
      ],
      [
        'Да про кого ты начал лихо?',
        'Ты знаешь, действие игриво',
        'Всем преподносит сей сюжет,',
        'В котором слышно слово нет.',
      ],
      [
        'Не повторяйся ты сегодня.',
        'Будь прежним лишь когда свободна',
        'Вся та же область бытия,',
        'В которой и идет вся жизнь твоя.',
      ],
      [
        'Я лишь про девушку с улыбкой,',
        'Что не читает стих с ошибкой.',
        'С ошибкой той, что есть у всех:',
        'Не виден свой, чужой успех.',
      ],
      [
        'Успех есть нечто из природы,',
        'Где не получишь ты невзгоды.',
        'Успех с задачей на перед',
        'Тебя с улыбкой в даль зовёт.',
      ],
      [
        'Весь зов прелестный, милый, тихий.',
        'Весь зов умелый в этом стихе.',
        'Будь ты главой себя в душе,',
        'И пусть река для счастья будет на меже…',
      ],
      [
        'Где ты найдешь ту пару действий',
        'Для разглашения той вести,',
        'Что ты есть тот иль та для дня,',
        'С тем жарким сердцем от огня.',
      ],
      [
        'Огня души твоей прелестной.',
        'Да быть тебе же столь известной,',
        'Что мир вдруг в миг себя встряхнет,',
        'Желание и действо позовет.',
      ],
      [
        'И зов опять такой умелый',
        'В том стихе, где ты столь же смелый,',
        'Что все ты в мире распознал.',
        'Слови ты свой оригинал!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 13),
    code: 'dobejsja-tseli-svojei-zhizni',
    content: [
      [
        'Добейся цели своей жизни',
        'В тот день, что солнцем мы зовем.',
        'Ведь луч твой тронувший запястье, кисти',
        'Создаст затею всех времен.',
      ],
      [
        'Ты будешь лучшим в этом мире.',
        'Ты и не будешь знать совсем,',
        'Что каждый вдумчив в рай кумира',
        'И создаёт свой мир затем.',
      ],
      [
        'В твоем создании подвластном,',
        'Лишь прозе в образе бытия,',
        'Найдешь ты ласку в безобразном',
        'Сюжете лет, где жизнь твоя.',
      ],
      [
        'Ее не стоит опасаться.',
        'Лишь только тронь ее рукой,',
        'И жизнь сюжету так подвластна:',
        'Несётся в даль за бред-мечтой.',
      ],
      [
        'Ты слово бред лишь только помни',
        'В тот вечер перед солнца днём.',
        'Мечта твоя ведь так укромна…',
        'И пусть горит она огнем!',
      ],
      [
        'В огне те искры от сюжета',
        'Дадут понятие времен.',
        'Так вот желаю быть легендой,',
        'Постичь все общество! Спасен…',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 3, 21),
    code: 'someone-strange-and-pretty-funny',
    content: [
      [
        'Someone strange and pretty funny',
        'Helps to keep the mind in sunny,',
        'Random, round over world.',
        'Take the place guy, take your word!',
      ],
      [
        'That’s the sentence of a beauty.',
        'Let’s do fun with any duty.',
        'Help my God to know what’s need',
        'To create the word as seed…',
      ],
      [
        'That the tree then grows like tiger',
        'To explain the word with cycle.',
        'Don’t repeat, please be the call',
        'All of people strange like wall…',
      ],
      [
        'With the picture of the funny',
        'Someone keeping into sunny.',
        'Just imagine someone world',
        'That’s the strictly just a word.',
      ],
      [
        'But the sense is almost near.',
        'I’ll explain that word is here.',
        'Let’s take dance and be the calm',
        'That explains the world of fun!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 23),
    code: 'chto-smogu-ja-napisat',
    content: [
      [
        'Что смогу я написать,',
        'Знает только моя мать.',
        'Знает все, что в мире есть.',
        'Ей же только свята честь.',
      ],
      [
        'А ещё есть люди в мире,',
        'Что не тронуты кумиром.',
        'Есть же люди и прогресс.',
        'Весь наш мир полон чудес.',
      ],
      [
        'Кто не знает же про это,',
        'Ты воспользуйся сюжетом,',
        'Что и в людях ты найдешь,',
        'Парочку заметок обернешь…',
      ],
      [
        'Ты в свой дневник суждений.',
        'Том великих рассуждений.',
        'Будь с тобой он изо дня.',
        'Пусть же радость есть твоя.',
      ],
      [
        'И затем ты тихо, мирно',
        'Обернись и встреть игриво',
        'Свою мать и век чудес.',
        'Весь мир добр, взгляд небес…',
      ],
      [
        'Ведь обретают люди тихо.',
        'Затем им в пользу очень мило',
        'Он разветвляется в сюжет,',
        'Но тихо слышно слово “Нет”.',
      ],
      [
        'За этим словом есть восходы.',
        'А люди верят, идиоты.',
        'Ведь Достоевский для любви',
        'Желал внезапные черты.',
      ],
      [
        'Что обладает каждый, глядя',
        'На мир чудес, где тот же дядя',
        'Все рассказал, пошел гулять.',
        'Такой вот день. Люблю я мать.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 23),
    code: 'v-tot-moment-prishel-ja-divno',
    content: [
      [
        'В тот момент пришел я дивно.',
        'И все узнали, будет тихо.',
        'Никто не скажет все слова,',
        'Ведь смысл будет ерунда.',
      ],
      [
        'Во всех подряд словах для связки',
        'Так строится барьер завязки!',
        'Чтоб разузнать, так в чем секрет,',
        'Достоин ты лишь слова «Нет»…',
      ],
      [
        'Которое стоит с тобою рядом.',
        'Ты говоришь все это взглядом.',
        'Во всех подряд словах ничто.',
        'В определении есть кой-кто.',
      ],
      [
        'Тот самый с кем ты говоришь.',
        'Тот самый с кем ты рядом все гласишь',
        'О бреде том, что все друзья.',
        'На самом деле здесь ничья.',
      ],
      [
        'Ничья есть только в мире,',
        'В котором воспитание тихо',
        'Всем говорило про себя',
        'И так же слушало тебя.',
      ],
      [
        'Сейчас то время превосходства,',
        'Но не позволено господством',
        'Всех приводить к нюансам дня.',
        'Есть только жизнь. Она одна.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 23),
    code: 'chto-rasskazat-sejchas-ne-znaju',
    content: [
      [
        'Что рассказать сейчас не знаю.',
        'Я как-то странно мир свой представляю.',
        'Все хорошо и есть прогресс,',
        'Но знаешь ты, что нет чудес.',
      ],
      [
        'С тобой все как-то очень ловко.',
        'С тобой вся жизнь резвится громко.',
        'Все что ты думаешь сейчас,',
        'Есть лишь легенда для всех нас.',
      ],
      [
        'А если ты такой достойный,',
        'Что ты при чтении свой, сторонний.',
        'Так пусть же вся та сторона',
        'Живет и даст прогресс в века.',
      ],
      [
        'Затем возглавишь ты собрание,',
        'В котором есть то обаяние.',
        'Почувствовав, что мир с тобой,',
        'Да будет Бог держать рукой…',
      ],
      [
        'Твою прекрасную легенду',
        'Для малышей через забвенно',
        'Идущие вперед года.',
        'И да ребята, здесь мечта…',
      ],
      [
        'Что каждый может догадаться',
        'В том общем образе, признаться,',
        'Да излагается сюжет…',
        'И есть три точки, будет свет!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 23),
    code: 'sejchas-podumal-a-vosmozhno',
    content: [
      [
        'Сейчас подумал, а возможно',
        'Есть разница поэтов сих веков.',
        'Один же публикуется нарочно,',
        'Другой же с толстой пачкой своих черновиков.',

      ],
      [
        'Здесь, знаете, в куплете рядом',
        'Летит же мысль все с тем же взглядом,',
        'Что нет в сети тех размышлений',
        'Для всех людей и без презрений.',
      ],
      [
        'Давайте вместе соберемся,',
        'И как-то с мыслью разберемся.',
        'А что вот если да создать',
        'Такой портал, где есть печать?',
      ],
      [
        'Печать есть только от поэта',
        'И создана она куплетом',
        'Для всех разумных вдоль веков,',
        'Ведь есть поэт и он таков.',
      ],
      [
        'Кто понял же про суть суждений,',
        'Желаю добрых впечатлений.',
        'Пусть будет мир же полон грез!',
        'Открыть дорогу! Быть всерьез!',
      ],
      [
        'Ведь то, что новое так лихо',
        'Стремится показать уж дивно,',
        'Что каждый может человек.',
        'Да быть поэтом знатным в век.',
      ],
      [
        'И социум к нему потянет',
        'Рукой доступной. Тарабанит',
        'Он слово всех своих стихов…',
        'И пусть же будет он таков!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 23),
    code: 'vot-chto-by-v-vecher-napisat',
    content: [
      [
        'Вот что бы в вечер написать?',
        'И подвести итог для дня…',
        'Ты знаешь, здесь вся благодать,',
        'Что вдруг написана и я.',
      ],
      [
        'Есть пара дерзкий приключений,',
        'Но нет же для мотива зрений,',
        'Чтобы увидеть красоту,',
        'Что обладаешь ты в быту.',
      ],
      [
        'Весь день прошел довольно тихо,',
        'Но создались начала дивно.',
        'Как результат - неделя грез…',
        'Да нет, ребята, я в серьез.',
      ],
      [
        'Все, что сказать мне нужно взглядом,',
        'Есть в том сознании людей,',
        'Что лишь воспитаны да просто ядом',
        'Всех пост-советских дочерей.',
      ],
      [
        'Я ошибаюсь очень мило.',
        'Прошу прощения игриво.',
        'Есть стих, есть строки и куплет.',
        'Желаю всем не знать я бед!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 27),
    code: 'privet-drusja-kto-v-etom-mire',
    content: [
      [
        'Привет, друзья! Кто в этом мире?',
        'Я знаю, что ответит каждый здесь.',
        'Все представляют, что на Земле, в эфире',
        'С тем образом, о чем мир весь.',
      ],
      [
        'И здесь ответ не каждого на свете.',
        'Здесь просто речь идет в куплете.',
        'Лишь только пара человек',
        'Все знают, как несётся век.',
      ],
      [
        'Я помню, встретил я подругу',
        'И в разговоре взял ее за руку.',
        'Мы были в диалоге для бытия',
        'И дико нравилась погода, и не зря!',
      ],
      [
        'Весь мир же, люди, вознесите',
        'Немного выше ваших всех побед.',
        'Затем вы ракурс верный отыщите',
        'И будьте славным тем героем вне тех бед…',
      ],
      [
        'Что происходят рядом с вами.',
        'Здесь мир роскошный со словами.',
        'Все объяснить желаю я,',
        'Но строк не хватит для бытия.',
      ],
      [
        'Вы поглядите же в окно, что рядом',
        'И я не думаю, что все лишь будет взглядом,',
        'Что охватил весь мир, где я.',
        'Желаю счастья только для тебя.',
      ],
      [
        'И счастье-то уже на встрече.',
        'Оно желает быть и плыть так вечно!..',
        'Что слез от счастья хватит лишь,',
        'Чтоб отойти в сторонку, в тишь…',
      ],
      [
        'И там, где люди только скромно',
        'Решают, как же быть в сторонке',
        'Возьми себя ты в руки для небес',
        'И сотвори свой дар чудес!',
      ],
      [
        'И в чудесах ты взглянешь тихо,',
        'Как говорил, пойдешь игриво',
        'За всеми только наблюдать',
        'И быть волшебным, мир искать.',
      ],
      [
        'В том поиске довольно дивно!',
        'В том мире только ты лишь лихо',
        'Проводишь сей эксперимент.',
        'Поверь, не существует всех легенд.',
      ],
      [
        'Легенда - ты, легенда рядом.',
        'Я ж говорю, пройдись ты взглядом',
        'Своим стремящимся лишь в даль.',
        'Ты есть, ты будешь. Ты - Грааль!',
      ],
      [
        'Но не пойми меня лишь просто.',
        'Я думаю, ты все ж удался ростом.',
        'Ты так высок, что небеса',
        'Так чувствуют тебя… Ты чудеса!',
      ],
      [
        'Но в мире же реальном счастье',
        'Живет там, где есть люди, человек.',
        'Так пусть с утра ты взглянешь на запястье',
        'И будешь понимать, что любишь свой ты трек.',
      ],
      [
        'В нем есть три слова столь чудесных,',
        'Что мир же обретает прелесть светских.',
        'Ах, как же я люблю тебя!',
        'Ах, жизнь! Несись туда, где счастье есть любя!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 28),
    code: 'ves-mir-nachnet-tak-sotrjasat-tebja',
    content: [
      [
        'Весь мир начнет так сотрясать тебя!',
        'И ты не будешь ждать предлога ждать себя',
        'В том поезде, что едет за ту грань судьбы,',
        'В которой же все люди так верны.',
      ],
      [
        'Верны тебе и только всем тем нравам,',
        'Что возглавляют твой весь ракурс и сюжет.',
        'Но вот найдется же один, кто будет ядом,',
        'И скажет он в концовке слово «Нет».',
      ],
      [
        'Ты подождешь и отойдешь в сторонку…',
        'Ты поразмыслишь на лавке для девчонки,',
        'Что подарила для тебя весь мир и бред,',
        'В котором ты опять нашел так мило свой сюжет.',
      ],
      [
        'И в кульминации, когда герой захлопнул',
        'Да за собою дверь и рухнул на земь он,',
        'Пусть будут чудеса, и люди разом охнут,',
        'Ведь тот герой, как оказалось был лишь слон.',
      ],
      [
        'Здесь нет сюжета, нет здесь здрава смысла.',
        'Ты не горюй, найди все волшебство в том коромысле!',
        'Налей воды и будь ты лишь собой,',
        'И люди в миг потянутся рукой.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 29),
    code: 'pro-obraz-smysly-kak-to-stranno-vse-poet',
    content: [
      [
        'Про образ, смыслы как-то странно все поёт',
        'Тот человек, что в трубку мне орёт.',
        'Эй ты, расслабься ты немного',
        'И будет мир вокруг тебя толковый.',
      ],
      [
        'Вот я не знаю, где все люди.',
        'Я лишь стихи читаю полно в груди,',
        'Вдохнув два облака времен,',
        'И будет весь мир восхищен…',
      ],
      [
        'Всем тем волшебным превосходством,',
        'Что в обществе есть только сходством',
        'Всех рассуждений, время есть.',
        'Как и писал: да лишь тебе вся блага честь!',
      ],
      [
        'Я вот не знаю, как же быть с тобою.',
        'Ты лишь играешься со мною.',
        'Так пусть же будет светлый день,',
        'И путь твой только лишь вперед! Не лень…',
      ],
      [
        'Сказать три слова для всесчастья.',
        'Не лень поведать весь сюжет.',
        'Так пусть же будет и ненастье',
        'В главе, где писан в книге бред.',
      ],
      [
        'Ты как прочтешь страницы книги,',
        'Так всё поймешь, и нет же здесь интриги.',
        'Все что вокруг есть та Земля,',
        'Да на которой мила жизнь твоя.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 29),
    code: 'podmel-umylsja-prichesalsja',
    content: [
      [
        'Подмёл, умылся, причесался',
        'И спел свой гимн, придумав часов',
        'Весь тот куплет, что тебя ждёт.',
        'Ах ты мечта! Вот кто тебя зовёт?',
      ],
      [
        'С мечтой не справишься, приятель.',
        'А вот с реальностью ты точно знатен.',
        'Ты знаешь, что такое весь прогресс,',
        'Но есть одно, что создаёт чудес.',
      ],
      [
        'И это только восхищение…',
        'Ты в миге быстром сновидения.',
        'Ты в том полете в те края,',
        'Где жизнь твоя идет не зря!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 30),
    code: 'poslednije-chasy-v-kvartire-staroj',
    content: [
      [
        'Последние часы в квартире старой,',
        'Той старой, где мой дух был ввысь',
        'Лишь в день, когда сыграл октавой',
        'Ту дружбу со словом “Улыбнись”.',
      ],
      [
        'И в тот момент, когда улыбка рядом',
        'Да пронеслась, бодря меня с утра,',
        'Тогда я понял жизнь и будь проклятым',
        'Весь тот былой же образ там где я…',
      ],
      [
        'Несусь по жизни так же смело,',
        'Как капитан, что в море вышел в день,',
        'Когда луна его весь путь узрела',
        'И бросила на флаг его цветную тень.',
      ],
      [
        'И в сказках данных, моментальных',
        'Прошу, читатель, будь собой,',
        'И собери ты смысл… Пропорционален',
        'Пусть будет весь мил облик твой.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 30),
    code: 'povstrechal-ja-byl-devchonku',
    content: [
      [
        'Повстречал я был девчонку,',
        'Что в кофейне вечерком',
        'Говорила про сторонку,',
        'Что я помнил днем потом.',
      ],
      [
        'Говорили мы с ней мило',
        'И не знали бед в тот час.',
        'Но затем судьба таила',
        'Разлучить весь мир для нас.',
      ],
      [
        'Я не знаю, где девчонка.',
        'Город знает лишь она.',
        'Точно верю, что вся гонка',
        'Была наша лишь судьба.',
      ],
      [
        'Я поспорю дважды в мире',
        'Не найдет меня она,',
        'Потому как в ходе, в ритме',
        'Не спросили имена.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 3, 30),
    code: 'tvoj-den-ne-mozhet-prevratitsa',
    content: [
      [
        'Твой день не может превратиться',
        'В нелепость, коли есть частица.',
        'Частица образа, где бред',
        'Есть слово действа. Люди, «Нееет»!',
      ],
      [
        'Я не хочу сказать проворно,',
        'Что действо ваше многословно.',
        'Я лишь хочу сказать, что бред',
        'В той голове, где есть ответ.',
      ],
      [
        'Ответ на все те мил вопросы,',
        'Что всем вокруг сдают, как взносы.',
        'Не думай, я здесь взгляд сменил.',
        'Я жил и голову я брил…',
      ],
      [
        'Как только армия, прислуга',
        'Пришла и было больно туго',
        'Всем рассказать, что есть же свет.',
        'И мне опять летело «Нееет»!',
      ],
      [
        'Я отошел, погладил кошку,',
        'Надел я памятную брошку.',
        'Я вышел глянул на толпу…',
        'Вот это сон! Да, я живу!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 4, 2),
    code: 'sizhu-na-berege-reki',
    content: [
      [
        'Сижу на береге реки',
        'И вижу образ я в дали.',
        'Тот образ в даль меня зовёт,',
        'Но я не знаю, как идти вперед.',
      ],
      [
        'Вот так же встретил я девчонку,',
        'Что привлекла весь свой сюжет',
        'К тому, что было подоплёкой',
        'Узнать, что есть легенды свет.',
      ],
      [
        'Присел на лавку и подумал,',
        'А где ты был, когда ты вздумал',
        'Ей рассказать искусства бред?..',
        'Сейчас лишь слышно слово «Нет».',
      ],
      [
        'Но кто-то лавку отодвинул,',
        'И руки вверх он опрокинул.',
        'То был же дядька просто так,',
        'Но я подумал: это знак.',
      ],
      [
        'В миг позвонил, сказал я фразу,',
        'Что была тем, кто знает сразу,',
        'Тем воплощением, где сны',
        'Лишь ночью снятся. Все равны.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 4, 4),
    code: 'poobshavshis-v-den-s-kollegoi',
    content: [
      [
        'Пообщавшись в день с коллегой,',
        'Тот день, что красный для всех нас,',
        'Ты понимаешь, что весь мир умелый,',
        'И вновь в душе играет джаз.',
      ],
      [
        'Никто не может и представить,',
        'Поверьте мне, что есть в тепле…',
        'Но без того умения править',
        'Живет же каждый на Земле.',
      ],
      [
        'Ох, это есть прекрасный смысл,',
        'Что б каждый верил лишь в себя',
        'Да так, что б смысла коромысло',
        'Вновь вспоминало по тебя.',
      ],
      [
        'Тебя! Я пальцем прямо тычу.',
        'Ты есть герой, открой глаза',
        'На мир вокруг, слови добычу',
        'И совершай ты гордо чудеса!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 4, 4),
    code: 'serjoznyj-den-bul-dialog-o-zhizni',
    content: [
      [
        'Серьезный день… Был диалог о жизни',
        'В том мире, что наследством все зовут.',
        'Зовут же лишь, вся повесть столь сюрпризна,',
        'Что люди учатся, и в даль умело все идут.',
      ],
      [
        'Тот диалог был о науке.',
        'Науке той, что цифрой все зовут.',
        'Лишь пара слов была в моем ноутбуке,',
        'И все они для сказа больно бьют.',
      ],
      [
        'Они верны в подаче счастья лишь на утро,',
        'Когда забыл ты в ночь всю памятную дрожь.',
        'Но в день тебя да встретят незабудки,',
        'И ты не можешь различить всю ложь…',
      ],
      [
        'Пусть будет так, как ты порой мечтаешь',
        'О незабудках милым, летним днём.',
        'И пусть вся жизнь гордится, как ты поступаешь,',
        'И пусть весь мир лишь думает о нем!',
      ],
      [
        'Его же образ расчудесный',
        'Не обладает он же родом, лишь стихóм',
        'Описан может быть прелестный',
        'Тот возглас встречи на потом.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 4, 5),
    code: 'ne-uspela-i-zadacha',
    content: [
      [
        'Не успела и задача',
        'Начать свой искренний прогресс,',
        'Как точка стала на запясте',
        'Остановить часов процесс.',
      ],
      [
        'Ой, люди странные повсюду.',
        'Стремится мир же лишь к уюту',
        'Всех в нем, что б жить, взрослеть и петь…',
        'На деле же способны все жалеть.',
      ],
      [
        'То состояние приходит,',
        'Когда кто рядом вдруг уходит.',
        'Поставил точку и забыл.',
        'Реку освоил. Классно жил…',
      ],
      [
        'Лишь пару дней. Но будь прекрасно',
        'Все настроение твое под час,',
        'Когда на улице ненастно,',
        'Но пусть в душе играет джаз!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 4, 5),
    code: 'pjatnitsa-est-den-dovolno-strannyj',
    content: [
      [
        'Пятница есть день довольно странный.',
        'Каждый человек живет в том счастье близ конца.',
        'Конца недели, да будь я не причастный',
        'К тому лишь образу, что бьется для глупца.',
      ],
      [
        'Глупец сегодня знает три затеи,',
        'И пятница - начало для него.',
        'Затем же будет вскоре воскресенье,',
        'Ведь про субботу он не знает ничего.',
      ],
      [
        'Весь этот мир, будь он, как дьявол же проклятый,',
        'Представлен лишь людьми своим лишь жестким взглядом.',
        'Да, я про день, что пятницей зовут,',
        'И ассоциации есть на всю же в городе толпу.',
      ],
      [
        'Толпа резвится и не знает, что ей делать.',
        'Она не злится, но все равно стремится веять',
        'Своей загадкой выходного ни о чем…',
        'Пусть люди же искусства скажут слово - “Не при чем”.',
      ],
      [
        'Они не знают, что ты там умеешь.',
        'Они лишь в образе, который в тот же миг',
        'Да преподносит радость, и ты сейчас так мило зреешь,',
        'Что есть Вселенная, и так же есть мотив.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 5),
    code: 'i-dont-know-whats-lady-siste',
    content: [
      [
        'I don’t know what’s lady, sister.',
        'I know who is the land minister.',
        'Say me some words, please be the quest!',
        'This world wishes be the pretty best!',
      ],
      [
        'The world is strange but we are stable.',
        'We live like country of a label.',
        'The written word is monkey day.',
        'I want to be! Just be not grey!',
      ],
      [
        'If any one can say a legend',
        'Just tries of him or her be agent',
        'Who wants to meet your happy time…',
        'Just say to him what’s yours what’s mine.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 4, 5),
    code: 'den-bystro-nachinaetsja-v-dvizhenii',
    content: [
      [
        'День быстро начинается в движении,',
        'И так прекрасно все, то приключение,',
        'Что мотивирует тебя к пурге',
        'В душе, и будь-то образ - бытие.',
      ],
      [
        'Ты так стараешься повсюду,',
        'Что б образ был лишь твой уютный,',
        'Но день идет и все как все…',
        'Я и не знаю, что же делать мне во сне.',
      ],
      [
        'Во сне же человек очнь мило',
        'В свои же грезы смотрит в даль.',
        'Затем идет с тем позитивом,',
        'Что не встречает всю печаль.',
      ],
      [
        'Ее и нет, скажу в секрете.',
        'Все признано собой в сюжете.',
        'Живите, люди, день за днём,',
        'И будет рай тобой польщен.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 4, 5),
    code: 'pri-vstreche-miloy-toj-babuli',
    content: [
      [
        'При встрече милой той бабули',
        'Все облака сезон раздули.',
        'Вся радуга, как будто ожила,',
        'И пусть же будет день дотла!',
      ],
      [
        'Вдруг небо нежно говорило,',
        'Что облака твои друзья,',
        'Но дождь полился так игриво,',
        'Что жизнь же стала классной, и не зря!',
      ],
      [
        'Под дождь пройтись есть что-то с чем-то!',
        'Под дождь пройтись есть та легенда,',
        'В которой здравый человек',
        'Желает в миг познать побед.',
      ],
      [
        'При том же действии очнь милом,',
        'Взглянул он в отражение для дня…',
        'Являлось все его изображением в мире диком,',
        'И вспомнил он любовь добрейшего огня…',
      ],
      [
        'В его же сердце разноликом,',
        'С душой бродяги, с мудрым ликом,',
        'Вся жизнь же мудреца есть свой сюжет.',
        'Запомни… Ты герой всех милых миг-легенд!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 4, 6),
    code: 'kto-mne-rasskazhet-chto-soznanije',
    content: [
      [
        'Кто мне расскажет, что сознание',
        'Все выставляет только в субъективе нас?',
        'А может быть тот миф, где ты в придании,',
        'Есть то, что и играет добрый джаз?',
      ],
      [
        'Все общество, с моей лишь точки зрения,',
        'Способно жить и думать, как прогресс.',
        'Для этого достаточно лишь только пения',
        'Тех песен, мол весь мир чудес.',
      ],
      [
        'Ведь чудеса творятся влево-вправо!',
        'Я не шучу. Я вижу точным взглядом,',
        'Как каждый день идет лишь только уж вперед',
        'И всех людей в один лишь ракурс, да, зовет.',
      ],
      [
        'Сей ракурс есть про мир и личность,',
        'Кто понимает о Вселенной, как раз-два.',
        'Вся информация есть в лавках, чтоб публичность',
        'Да обрела понятие без сладкого, лихого сна.',
      ],
      [
        'Вот если честно, непонятно…',
        'Вот как сей мир же можно не понять?',
        'Достаточно быть ликом с абстрактным, милым взглядом,',
        'И что же есть Вселенная с определением да знать.',
      ],
    ],
  },
  {
    title: 'Сундук',
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 4, 6),
    code: 'sunduk',
    content: [
      [
        'Начался день уж больно рано.',
        'Все вспомнилось и было ни о чем…',
        'Я чувствовал себя довольно странно',
        'И знал, что мой сундук при нем.',
      ],
      [
        'Как распознать, вот где он будет?',
        'Я лишь свой путь пытаюсь воссоздать.',
        'Приду к нему: ударит в груди,',
        'И буду я потом рыдать.',
      ],
      [
        'Я думал дважды и поднялся.',
        'День был чудесный в те же дни,',
        'Как девочке я гордо уж признался,',
        'Что веки лишь для нас даны.',
      ],
      [
        'Вот главное, что б дядя разноликий',
        'Не подошел же к ней и что-то не спросил.',
        'Я буду весь контакт держать больн дико…',
        'И вдруг подумал, как в прошлом разлюбил.',
      ],
      [
        'И снова начал я раздумье,',
        'Как получить сундук, что полон грез.',
        'Писал я книгу в том костюме,',
        'Что и привел меня до слез.',
      ],
      [
        'Я думал, думал: угадал я утром,',
        'Что нужно сделать - быстрым быть',
        'И получить сундук в том обществе премудром,',
        'В котором я и дальше все хотел уж больно жить.',
      ],
      [
        'Я позвонил Володе быстро,',
        'Спросил, не занят вечером ли он?',
        'Он вдруг ответил, что не видит смысла,',
        'Сундук при дяде, я прощен.',
      ],
      [
        'Так ты работаешь на дядю?',
        'Спросил я мигом для него.',
        'Он положил вдруг трубку. На запястье',
        'Часы уж полдень предсказали для всего.',
      ],
      [
        'Уж если же Володя даром',
        'Да подчинился просто так,',
        'Тогда я рухну же в канаву:',
        'Сундук же будет при делах!',
      ],
      [
        'Я вышел, кофе вдруг хмельное',
        'Меня вернуло в свет, где мы для дня',
        'Решения задачи, ведь такое',
        'Есть то сражение, где ничья.',
      ],
      [
        'Спросил прохожего в кофейне,',
        'Что думал он о философии веков?',
        'Вдруг пальцем тыкнул он в презрение',
        'И убежал… И был же он таков.',
      ],
      [
        'Вот день же как-то не случился,',
        'Я не решил пока задачу сундука.',
        'Я встал, немного огорчился,',
        'Но путь держал я вверх сполна.',
      ],
      [
        'И вот я вижу, дикий дядя',
        'Да с сундуком своим уже идет.',
        'Не видит он, что на запястье',
        'Есть три часа и день плывет.',
      ],
      [
        'Плывет на встречу разговору,',
        'Что нас тревожит, да, вдвоем,',
        'И мы придем, да, оба лишь к позору,',
        'Коль не всплывет рассказ о нем.',
      ],
      [
        'О разношерстном представлении',
        'Вещей, что сунул я в сундук.',
        'Весь мир окажется в прощении,',
        'Коль хоть один предмет издаст в нем стук.',
      ],
      [
        'Я приглянулся, был же дядя',
        'Да в капюшоне с ветерком.',
        'В глазах, забыл он быстро счастье,',
        'Весь тот сюжет был не при нем.',
      ],
      [
        'Я крикнул вдруг: “Эй парень милый!”',
        'Он обернулся, я в сторонку отошел,',
        'Затем я думал: “Сколько линий',
        'Лишь в той системе…”. Обречен.',
      ],
      [
        'Я отошел, зашел я сзади',
        'И стукнул дяде по плечу.',
        'Он обернулся: видел я во взгляде',
        'Никчемную, но взрослую тоску.',
      ],
      [
        'Я приподнял в руках же книгу,',
        'В которой был описан весь сюжет',
        'И передал ему живую ту интригу,',
        'Что б прозвучало слово “Нет”',
      ],
      [
        'Дядя, верно, вдруг подумал,',
        'Что я сундук вернуть хочу.',
        'Но он же верно перепутал,',
        'Ведь постучал я по плечу.',
      ],
      [
        'При этом стуке разноправном',
        'Висок чуть поседел же у меня.',
        'Мы в городе, что обладает нравом,',
        'И люди вдруг смотрели на меня.',
      ],
      [
        'Сказал я дяде: “Эй, Петрович,',
        'Да хватит же игрушки уж таскать.',
        'Давай же сядем на асфальт чудовищ',
        'И будем гордо, но молчать.”',
      ],
      [
        'Петрович чуть слезу прокинул,',
        'Затем сказал он голосом при всех:',
        'Я для детей хотел игрушек чуть подкинуть.',
        'Я и не думал о себе.',
      ],
      [
        'Пойми, Петрович, это игры взрослых:',
        'Здесь философия и бред.',
        'Вот если детям доступ всех безмозглых:',
        'Не будет в мире дальше всех побед.',
      ],
      [
        'Так ты же автор! В нашем свете',
        'Тебя да каждый знает, кроме диких впредь.',
        'Так смысла нет же в том куплете,',
        'Петрович, наш сундук смотреть.',
      ],
      [
        'Смотреть, что в мире происходит,',
        'Коль информация дана.',
        'Ты вон сундук же здесь волочишь,',
        'Но не дано судить меня.',
      ],
      [
        'Да, если честно, я не знаю,',
        'Зачем мне босс да поручил',
        'Сундук же взять, я полагаю',
        'Ты с ним же в сговоре… Твой чин?',
      ],
      [
        'Нет никого, кто здесь тебе поверит!',
        'Нет босса, нет меня же здесь сейчас.',
        'Есть сон. Он уникален и проверит',
        'Играет ль в душе твоей свой верный, дикий джаз!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 4, 7),
    code: 'vsio-chto-skazat-mogu-segodnja',
    content: [
      [
        'Все, что сказать могу сегодня,',
        'Есть тот же свет нелепый всем.',
        'Я прочитал за день заботно',
        'Цитату про горбатых в миг людей.',
      ],
      [
        'Я рифму не нашел сейчас в куплете,',
        'Я жаждал передать сюжет,',
        'Но жизнь же кроется в сочнейшем днем букете,',
        'Что бы услышать хоть сто раз “Да, нет”…',
      ],
      [
        'Ведь только в данном красноречии',
        'Да выражен весь смысл слов.',
        'Ты мне поверь, да встретишь ты сердечно',
        'Ведь ту-того, кто был таков.',
      ],
      [
        'И в этом мире очень сложно',
        'Всем рассказать про образ бытия.',
        'Ведь все идут куда-то срочно',
        'И за бывают про себя.',
      ],
      [
        'Но ты на следующее утро',
        'Взгляни глазами в тот букет.',
        'И пусть же день идет уютно.',
        'Тебя, да, любит весь наш свет!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 8),
    code: 'the-day-is-interesting-for-all',
    content: [
      [
        'The day is interesting for all',
        'Who can explain the world of us.',
        'Be strange but just do going on',
        'That is decision to be jazz.',
      ],
      [
        'The music, mean I, of the travel',
        'Yourself around our world.',
        'Be happiest person, do your level',
        'To keep the strong and round curled…',
      ],
      [
        'Society with lines of thinking',
        'Around music happy dance.',
        'The nature tries to be a linking',
        'Tomorrow meeting with a chance.',
      ],
      [
        'This chance is only one around.',
        'The chance tries give the clear mind.',
        'Let’s do the action pretty round',
        'To cycle moment, be not blind.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 8),
    code: 'what-i-do-that-s-simple-question',
    content: [
      [
        'What I do?.. That’s simple question.',
        'I do the knowledge’s situation.',
        'I just move mine in happy mind',
        'To be so pleasant, be just kind.',
      ],
      [
        'In world of fairy, happy trailers',
        'I kept the dance to be retailer:',
        'The product everyone’s of us',
        'As I’ve been mentioning is jazz.',
      ],
      [
        'Why’s music meaning journey’s label?',
        'That means that sound is an able.',
        'The able people of the world',
        'To be the strangers on the road.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 8),
    code: 'i-dont-know-who-is-like-there',
    content: [
      [
        'I don’t know who is like there.',
        'I just can keep to stay myself',
        'Not targeting the lady clever.',
        'I just to find the sense of shelf.',
      ],
      [
        'The books on this one are the music.',
        'I like the music, don’t be far.',
        'Are hearing you dance like gusic?',
        'The word from country where we are.',
      ],
      [
        'You know, you mean the level balance',
        'That all the people do a day,',
        'But they do not can do the reverse',
        'To understand the correct way.',
      ],
      [
        'That way consists of smile and sunny,',
        'The sunny world around us.',
        'Be smart the moment, pretty funny.',
        'The Universe is playing jazz!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 9),
    code: 'you-know-the-evening-s-just-a-seldom',
    content: [
      [
        'You know, the evening’s just a seldom',
        'Investigation of the mind.',
        'I don’t know what should be travel',
        'With somebody who’s really bright.',
      ],
      [
        'The world with art is train with station.',
        'That stop is showing an event.',
        'The culmination, as I mentioned,',
        'Is circle dream of legend. Blend…',
      ],
      [
        'The real state and funny movie',
        'That shows the window at your home',
        'Where’s some stranger, peek is curly,',
        'Just looking at the tab in Chrome.',
      ],
      [
        'Be real world and close the window.',
        'Your eyes’s displaying what you mean.',
        'There’s no such word for day with wisdom.',
        'You’ll take a book and be the dream.',
      ],
      [
        'What’s dream today and what’s the travel?',
        'What I just mean is single word',
        'Explaining beauty person’s level',
        'To conquer fast the people world!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 9),
    code: 'i-dont-know-what-ll-do-beginning',
    content: [
      [
        'I don’t know what’ll do beginning.',
        'I think that this is smart express',
        'That brings all us to some as sinning',
        'The get some like as Goddy bless.',
      ],
      [
        'The start is simple… Let’s imagine',
        'That train as mentioned with the stop.',
        'The stop is real some like margin',
        'To get the mind and say: I’ve got!',
      ],
      [
        'The getting story is a fairy.',
        'I can’t explain the sense of dance.',
        'But I just know that streets are airy',
        'And breeze brings nowadays’ the chance.',
      ],
      [
        'In this like poem but the real',
        'We always try to find yourself',
        'But world at time just moves ideal…',
        'The Universe as is, it is itself.',
      ],
      [
        'Can you explain what’s meaning strictly here?',
        'I know the answer and the train',
        'Continues moving trying hear',
        'What is the life best funny gain!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 10),
    code: 'the-world-is-sun-but-tree-grows-slow',
    content: [
      [
        'The world is sun but tree grows slow',
        'In life of person… No! Come on!',
        'Jump upper than you get your mind!',
        'Arrive to station that behind!',
      ],
      [
        'Your back is seldom in selection',
        'Of further way, it’s not attraction.',
        'There is a word that brings the hope.',
        'Do move and stop, do only rope.',
      ],
      [
        'This line around people round',
        'The main idea’s underground.',
        'Nobody speaks that what he means',
        'But Earth takes taste of all your beams.',
      ],
      [
        'I said that tree is pretty slowly',
        'Gives the advantage to come on…',
        'But sun is shining! We are closely',
        'To give this world the sense of all!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 10),
    code: 'the-day-of-undefined-with-mind',
    content: [
      [
        'The day of undefined with mind',
        'Allows all us to be so kind.',
        'So kind with social and the dream',
        'That helps us be the sudden beam.',
      ],
      [
        'This sunny axis with the legend',
        'Provides some like as I was mentioned',
        'With dialog beautiful as wind',
        'That blows to follow and to bind…',
      ],
      [
        'Society with rainbow wonder eyes on way',
        'To be the honest and to stay.',
        'The stop is only once a time.',
        'The following is curly line.',
      ],
      [
        'And line this happens once a living.',
        'The once I mention is a dreaming.',
        'The world’s full think around us!',
        'The nature brings the cool at once!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 10),
    code: 'i-dont-know-again-what-s-travel',
    content: [
      [
        'I don’t know again what’s travel',
        'Can give as nobody around us.',
        'I don’t see the strictly level',
        'Of the discussion dance like jazz.',
      ],
      [
        'Where is sound of the lyric?',
        'Where is movement of the dream?',
        'The words repeat but we are living',
        'As elfs on Earth with perfect gleam.',
      ],
      [
        'You know, you come to happy street one',
        'But sun so bright as you can see',
        'All people going up as funs gone.',
        'You can’t explain how to create the sea…',
      ],
      [
        'Of nature real, of the beauty',
        'That gives the own lesson’s bless.',
        'I do the social as a duty',
        'To keep the world with word “Success”.',
      ],
      [
        'That’s not the film about funny',
        'A relocation of the mind.',
        'That’s strong a def to live with sunny',
        'That one the street with people life.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 11),
    code: 'you-can-explain-the-stable-movement',
    content: [
      [
        'You can explain the stable movement',
        'In case you take the moment’s God.',
        'Who is this person? Just approvement',
        'That people live as thinking broad.',
      ],
      [
        'The thoughts try widely to decline',
        'The interaction during week.',
        'But how the heart become the line',
        'I can’t explain, I am some leak…',
      ],
      [
        'Of information, uni’s, common',
        'To be the human in the world.',
        'But people think that is just straw man',
        'That quite just speaking fully word.',
      ],
      [
        'When’m speaking me with people near',
        'The single person can explain',
        'What’s world believing with idea',
        'To strictly solving what’s the main.',
      ],
      [
        'But answer pretty independent',
        'Of all society around us.',
        'The line of life begins the travel',
        'Of beauty straight with word’s discuss.',
      ],
      [
        'And during dialog you can find',
        'The moment that was here, yes, behind.',
        'The tone of music round us…',
        'Let’s moment we replace to jazz!',
      ],
      [
        'The main idea’s kind exception',
        'Is trying find the navigation.',
        'No way! No troubles! We just live!',
        'I wish the moment of belief!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 12),
    code: 'the-undefined-strategy-s-people',
    content: [
      [
        'The undefined strategy’s people',
        'Come and provide the way of trance.',
        'You can’t believe into the living.',
        'You just are waiting some of chance.',
      ],
      [
        'But world is pretty independent.',
        'You’re own dreams, they are just kind.',
        'You stay as child with just a legend',
        'That you are stable and you’re right.',
      ],
      [
        'Why people all around planet',
        'Can not explain the sense of dance?',
        'I know, but own this like pomegranate',
        'Is only strike of Uni’s glance.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 12),
    code: 'i-like-your-music-that-s-a-wonder',
    content: [
      [
        'I like your music, that’s a wonder!',
        'I see the difference of us…',
        'I want just be like pretty kindly',
        'But you’re just playing best your jazz!',
      ],
      [
        'Hey people, are you still around,',
        'Around nature beauty now?',
        'I think that’s persons taking down',
        'While story moves the world. But how?',
      ],
      [
        'The story is of letters’ lyric',
        'To show what music can be done',
        'And sense is pretty life satiric',
        'With picture wide, with words “Come on!”',
      ],
      [
        'But we’re still into some like clear.',
        'That helps to us be some bit dear.',
        'I’m not translating these huge words…',
        'I love an art, the science and world!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 4, 13),
    code: 'v-den-kogda-obychnost-szadi',
    content: [
      [
        'В день, когда обычность сзади',
        'Подталкивать собралась да с утра,',
        'То день идет нелепый, глядя',
        'На твой сюжет. Своя игра…',
      ],
      [
        'Не глядя на презрение часом,',
        'Желает обеспечить красоту.',
        'Но мир наполнен злом с запасом,',
        'И ты не знаешь, кто в бреду.',
      ],
      [
        'Затем подумаешь немного,',
        'И все на месте для тебя.',
        'Ведь каждый думает сурово',
        'Лишь только без улыбки той с утра.',
      ],
      [
        'Ведь каждый прямо неприлично',
        'Свой строит добрый весь сюжет,',
        'Когда обычность вся типично',
        'Глаголет быстро слово «Нет»',
      ],
      [
        'Ты улыбнись, построй все книжки,',
        'В которых красок образ день за днём,',
        'И ты найдешь нелепые интрижки,',
        'Что б с той улыбкой знать о всем.',
      ],
      [
        'То знание не прячь в своей постели!',
        'Пусть люди будут рядом все',
        'В тот миг, когда ты в образе метели',
        'Расскажешь всю легенду на Земле!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 15),
    code: 'i-cant-explain-what-s-real-weeping',
    content: [
      [
        'I can’t explain what’s real weeping.',
        'I just am looking for the seeing',
        'The best approach to give a chance',
        'Which’s best one music and the dance.',
      ],
      [
        'I don’t know what’s kind with sound.',
        'I know the mentioned underground.',
        'I know who lives the time to time.',
        'The music is the real rhyme.',
      ],
      [
        'But now the music isn’t here.',
        'The music sounds with like gear.',
        'I don’t do chase of some goal,',
        'And I do not know what is role.',
      ],
      [
        'The role of human stable near',
        'Who can explain the move like dear.',
        'I can’t explain, I live just now.',
        'Who can define what is the “Wow”?',
      ],
      [
        'Nobody knows it… Yes, it’s stranger',
        'Who can explain the movement’s danger.',
        'The move is only once a time.',
        'Do not explain the words with “Hi”…',
      ],
      [
        'That can’t be only like today.',
        'The day is pretty kindly grey.',
        'I like the music but tonight',
        'I didn’t sleep, I was in flight.',
      ],
      [
        'The pilot absence was the picture',
        'To just explain who is the teacher.',
        'Let’s do it be just out of range.',
        'Move please just kind explain the change.',
      ],
      [
        'The poem pretty independent.',
        'This poem tries to match extended,',
        'Extended world that should be done.',
        'But I’m today a bit some down.',
      ],
      [
        'This down state I love as real.',
        'This state explains the life with deal.',
        'The first one person is the drive.',
        'The second is an art with life.',
      ],
      [
        'That’s not a blog, that’s just a fairy.',
        'Again the word repeats like airy,',
        'The airy poem of the drive',
        'But I wish you an art with life!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 16),
    code: 'you-know-i-ve-found-the idea',
    content: [
      [
        'You know, I\'ve found the idea',
        'How to explain the world with dear,',
        'The dear sense of common way',
        'But I have no way to explain.',
      ],
      [
        'The sense is pretty like a house.',
        'That sense includes the Jungle Claus.',
        'The mystery can’t find my friend',
        'But world just moves and done best great!',
      ],
      [
        'Do not try pay attention lazy.',
        'You eyes look like the my world crazy.',
        'This word provides the only sense:',
        'We need to do the voice, dance.',
      ],
      [
        'The music is around seeing',
        'Who is available for being.',
        'Do not forget what dialog is…',
        'The day is great, let’s do the bis!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 16),
    code: 'i-dont-know-how-ll-contact-you',
    content: [
      [
        'I don’t know how’ll contact you.',
        'The single way is try to view,',
        'To see the value of the mind',
        'And be just simple, pretty kind.',
      ],
      [
        'I hope that life of your huge travel',
        'Will be the movement of the label.',
        'The notion way into this one',
        'Is great solution of whole dones.',
      ],
      [
        'But what should be the done agreeable?',
        'You can just try to be a seeable.',
        'The world around pretty straight',
        'And nobody can sing the way.',
      ],
      [
        'This process just like as an orange.',
        'That’s bright and still have no the storage.',
        'The whole extent is not just gone',
        'But do remember what should done.',
      ],
      [
        'At last, in end I want just wish you',
        'To open legend of the issue.',
        'The question that is wide in soul.',
        'The single word… Let’s do! Come on!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 17),
    code: 'this-day-can-not-be-only-changes',
    content: [
      [
        'This day can not be only changes',
        'Of way that people do creating waiting’s',
        'Thoughts of world so fun bit round.',
        'But soul provides the highest sound.',
      ],
      [
        'I hope that music’s not repeating.',
        'I just want wish you dream like greeting.',
        'The meeting only once a time.',
        'I have this mentioned and arrived…',
      ],
      [
        'In some world pretty not so clear',
        'Where is no power of the gear.',
        'The strength is only this full world',
        'That should provide the peaceful word.',
      ],
      [
        'This word I can’t pronounce quickly now.',
        'The people are bit stranger’s “Wow”.',
        'Let’s help them just to be alive.',
        'I can explain how move self drive.',
      ],
      [
        'That explanation is creating',
        'As I was mentioned with the waiting',
        'The time to time like chance of dance.',
        'Hey storage, you has given sense!',
      ],
      [
        'But knowledge helps to me just only.',
        'The strange idea to be slowly.',
        'The world is one and we are dance…',
        'Hey people, that’s a funny glance!',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 17),
    code: 'i-dont-know-what-should-be-written',
    content: [
      [
        'I don’t know what should be written.',
        'I know just only one the rhythm.',
        'It likes a flashlight into world.',
        'It calls all us to be the word.',
      ],
      [
        'I am sorry for the strange repeating',
        'But I don’t find the sunny greeting.',
        'I just can look around us',
        'And I am playing soul with jazz.',
      ],
      [
        'Your song does not related looking',
        'At the some bridge that so fun booking',
        'The like where’re all people play',
        'But someone’s looking to display.',
      ],
      [
        'Hey, information is a living',
        'In single world that is the bringing',
        'That taking tones like don’t you need…',
        'The real life is nature seed.',
      ],
      [
        'The flower after rainy summer',
        'Will show who can say word for comer',
        'Into the real standing drive…',
        'Just want to wish the sun in life!',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 17),
    code: 'i-ve-never-thought-around-world',
    content: [
      [
        'I’ve never thought around world.',
        'The word again as life is curled.',
        'The cycle can not be release.',
        'I don’t want to do a lease.',
      ],
      [
        'Hey world, hey people near!',
        'That’s strange but we do love as real.',
        'The only person whom we love',
        'Is only yourself, itself god.',
      ],
      [
        'I don’t real understanding',
        'What is the round that extending',
        'The couple thoughts that live as once.',
        'But we do real… No else dance.',
      ],
      [
        'I should repeat, that’s not blog strictly.',
        'This is a life that runs so quickly.',
        'I don’t wait for someone word.',
        'The only one is real world!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 17),
    code: 'i-ve-never-thought-around-world',
    content: [
      [
        'Who can say the words: I’ve done?',
        'What you’ve done? He-hey! Come on!',
        'The single point you have touch',
        'Is born of yourself just do clutch.',
      ],
      [
        'The some one entity of action',
        'Is straight you need this cool attraction.',
        'You can not say that world is round…',
        'What you have done? He-hey! Come on!',
      ],
      [
        'But your expectancy like pretty',
        'The independent with the pity.',
        'The pity glance with no one chance.',
        'Just be a strong. The fairy tells…',
      ],
      [
        'The story where’re you around',
        'And you can not do underground.',
        'The single way to sing the song…',
        'What you have done? He-hey! Come on!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 18),
    code: 'i-dont-forget-what-should-be-done',
    content: [
      [
        'I don’t forget what should be done.',
        'That’s pretty strange but happy one',
        'Provides the sense of soul in dream.',
        'I don’t forget what do you mean.',
      ],
      [
        'The world around is description',
        'That everyone is stable fiction.',
        'The world around do the step',
        'And everyone’s while useful help.',
      ],
      [
        'The action is for self but only',
        'There is a movement pretty slowly.',
        'The music round keeps the dot',
        'But thanks for great full no of word.',
      ],
      [
        'The silence keeps the travel legend.',
        'It helps to be just full attraction',
        'But soul goes on and we’re a life.',
        'That’s not an error. We do rife.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 18),
    code: 'i-m-justly-free-but-do-not-have',
    content: [
      [
        'I’m justly free but do not have',
        'The apple that rides born of help.',
        'The main idea of the dream',
        'Is to explain what do you mean…',
      ],
      [
        'I can’t to do this, you’re not here.',
        'I don’t know how come with clear',
        'The understanding of the world',
        'That helps provide the words to solve.',
      ],
      [
        'The best solution, let’s to do this,',
        'Is revolution with hard strong hiss.',
        'I am just trying do the sound',
        'As I can’t see the rainy cloud.',
      ],
      [
        'The rain grows people so fast great!',
        'It helps to us do not afraid.',
        'Do not afraid what’s real life…',
        'I wish you sun while rainy rife!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 18),
    code: 'let-s-do-the-real-no-else-dance',
    content: [
      [
        'Let’s do the real: no else dance.',
        'There was the strange but happy chance.',
        'That’s only my chance to be free',
        'Of all ideas. Grow the tree',
        'That any human of the planet',
        'Can touch, become the sense as granite.',
        'The stone in England looks true-false',
        'But in this country sounds close',
        'The movement of the breaking singing',
        'Where’s just mention of beginning.',
        'That’s start of dream but we alive…',
        'Let’s do the real caring life.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 19),
    code: 'i-think-you-know-what-i-have-mentioned',
    content: [
      [
        'I think you know what I have mentioned',
        'When I was telling peaceful legend.',
        'The strictly sense lives round “Cheers”',
        'And we try catch the mouse cheese.',
      ],
      [
        'That explanation is regarding',
        'The story telling by the subject.',
        'The goal is just to keep away',
        'But do the common life of way.',
      ],
      [
        'The line of movement is so wide',
        'That people turn at crazy pride.',
        'Each one tries touch the deeply heart',
        'But nobody can show the chart.',
      ],
      [
        'There is no data for projection.',
        'There is just feeling, situation',
        'Of different high level worlds.',
        'Be silent please and keep the words…',
      ],
      [
        'That will pronounce the extension',
        'Of way that really attraction.',
        'Attach the file to memo please…',
        'I wish you world of just a peace!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 19),
    code: 'the-day-is-starting-as-weeks-end',
    content: [
      [
        'The day is starting as week’s end',
        'So pretty lovely, that is great!',
        'But how do touch the knowledge sense?',
        'I know, hey people, do just dance.',
      ],
      [
        'The music is a drinking lowly.',
        'It is providing movements slowly.',
        'The song’s while music is a sun.',
        'Hey DJ, let’s do people on!',
      ],
      [
        'The day is starting but the real',
        'Is moving with the quick a deal:',
        'Computer, science from one of side',
        'But art is living, yes, behind!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 19),
    code: 'i-dont-know-how-can-ll-provide',
    content: [
      [
        'I don’t know how can’ll provide',
        'The sense of living as a wild.',
        'The world is strange but nature’s done…',
        'That’s great idea! Do the fun!',
      ],
      [
        'I don’t know how touch the legend',
        'As we all live like stranger’s age end.',
        'This age comes on and still presents',
        'The real and fantastic trends!',
      ],
      [
        'But I don’t know what is the long break.',
        'I don’t know who is a fine skate',
        'That mountains rides and keeps the trick',
        'Which you exiting while burn wick.',
      ],
      [
        'The rocket flights as bird with tweeting.',
        'The other waits the seldom meeting.',
        'I don’t know how can’ll provide',
        'The sun for meeting while we ride.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 19),
    code: 'i-dont-know-how-can-ll-provide',
    content: [
      [
        'I don’t know how can’ll provide',
        'The sense of living as a wild.',
        'The world is strange but nature’s done…',
        'That’s great idea! Do the fun!',
      ],
      [
        'I don’t know how touch the legend',
        'As we all live like stranger’s age end.',
        'This age comes on and still presents',
        'The real and fantastic trends!',
      ],
      [
        'But I don’t know what is the long break.',
        'I don’t know who is a fine skate',
        'That mountains rides and keeps the trick',
        'Which you exiting while burn wick.',
      ],
      [
        'The rocket flights as bird with tweeting.',
        'The other waits the seldom meeting.',
        'I don’t know how can’ll provide',
        'The sun for meeting while we ride.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 20),
    code: 'the-day-consists-of-people-legends',
    content: [
      [
        'The day consists of people, legends.',
        'The day is full of strictly mentions.',
        'All people round define sense.',
        'What is the global movement pace?',
      ],
      [
        'The philosophy’s underground',
        'Does not provide of pace’s mount.',
        'The all society breaks thoughts',
        'To do the movement of the world.',
      ],
      [
        'Why people live as strangers’ value',
        'To investigate the overvalue,',
        'Just trying be as silent one.',
        'How can we speak like people gone?',
      ],
      [
        'The main idea of the human',
        'Is living with a mind like lumen.',
        'The light but darkness’s round chase.',
        'The main conclusion’s only chance.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 20),
    code: 'people-re-walking-down-the-street',
    content: [
      [
        'People’re walking down the street',
        'To look for the seldom just a meet',
        'Of the refactoring of sense',
        'That should provide the souly dance.',
      ],
      [
        'The streets are clear to explain',
        'What does the sound strictly main.',
        'The tones of life around help',
        'To investigate what is the great.',
      ],
      [
        'But people are dependent strictly',
        'Of what they mention into meaning.',
        'The own dance provides the change',
        'That is around to be sense.',
      ],
      [
        'That is dependency like beauty.',
        'This fact plays life’s amount duties.',
        'Try do not touch the others’ dance',
        'And life like storm will be the sense.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 20),
    code: 'nobody-helps-each-other-easy',
    content: [
      [
        'Nobody helps each other easy',
        'Like everyone is really busy.',
        'The world is business - this like sense',
        'That every person keeps like glance.',
      ],
      [
        'That is the problem of the country',
        'Where I’ve loved the streets so kindly.',
        'All people were time of dreams',
        'But sudden real gave the miss.',
      ],
      [
        'The miss around is description',
        'As world is business but the fiction',
        'Of this one thought is you’re a life.',
        'Do not please touch my own drive.',
      ],
      [
        'In case you haven’t any thoughts',
        'How to provide the world of knots.',
        'Do not attention pay to dot',
        'That this kind poem ends so hot.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 20),
    code: 'i-know-the-problem-let-s-do-wait',
    content: [
      [
        'I know the problem. Let’s do wait,',
        'The wait of interest’s open gate.',
        'The main idea of this phrase:',
        'The world is round! People blaze!',
      ],
      [
        'The fire’s into heart so brightly',
        'That nobody can look for nightly,',
        'The nightly dance into some club',
        'Because it is not strictly hub.',
      ],
      [
        'The true hub people of the feature',
        'Is comfort place where just duty',
        'Helps to provide the dream of science.',
        'The legends come with tea and dance!',
      ],
      [
        'Then people’s strangers do the label',
        'Where some picture is so able,',
        'Such able that some kind of thoughts',
        'Hit to all people do “Come on!”',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 21),
    code: 'the-world-s-idea-of-creation',
    content: [
      [
        'The world’s idea of creation',
        'Is just the state’s investigation',
        'With further movement of the trip',
        'That is so perfect… I should sleep.',
      ],
      [
        'The dream’s providing clever story',
        'Where I am do not just worry',
        'About going into mind',
        'Where some people so much kind.',
      ],
      [
        'All people into this just fairy',
        'So clever and can try a cherry',
        'With taste of beautiful whole world.',
        'I like this dream, I’ve just one got!',
      ],
      [
        'This dream I saw not only once.',
        'That was repeating as a dance.',
        'All people are the single one…',
        'The fairy tries to do go on.',
      ],
      [
        'But the environment of real',
        'Just opens eyes, I see one deal:',
        'The bridge is built just only once…',
        'Do you do step to future chance?',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 22),
    code: 'how-can-explain-the-child-s-pronounce',
    content: [
      [
        'How can explain? That’s child pronounce.',
        'What do you say? That’s only mouse',
        'That can not speak by all the way',
        'But sun is up and lives today.',
      ],
      [
        'We all the living just translation',
        'That is for child an explanation.',
        'The whole of dance is only chance',
        'To get the clear movie glance.',
      ],
      [
        'The poetry like life of fairy',
        'That’s just explaining what is mainly.',
        'But people can not find the sun',
        'When weather does a pretty down.',
      ],
      [
        'But moon at night is saying strictly',
        'That human is the god just weekly.',
        'Not monthly, daily or the year:',
        'The god is music of the gear.',
      ],
      [
        'That gear is human pretty sudden',
        'When life is thread that leads to gotten,',
        'The gotten life: the cycle done!',
        'That word, no worries, will be gone.',
      ],
      [
        'And way of word is just a legend',
        'With gotten life that pretty strange one.',
        'I wish a kindly wide just mind',
        'To recognise that life’s all right…',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 22),
    code: 'the-best-idea-of-the-world',
    content: [
      [
        'The best idea of the world',
        'Is much the simple just a word.',
        'The word is you and no else sense.',
        'We everyone is great full dance.',
      ],
      [
        'Why some of people do not mind',
        'Into the world were the birds so bright?',
        'I’m really do not know that',
        'And while the life is music set.',
      ],
      [
        'All people that around progress',
        'Do not keep mind into the god bless.',
        'No god, hey guys, the real is',
        'But everyone as god in jeans.',
      ],
      [
        'The only movement of the mind',
        'Is simple and to be just kind.',
        'The whole this system is around',
        'And that’s not mentioned underground.',
      ],
      [
        'That’s like reality with sun',
        'Where some people do “Come on!”',
        'The others one while doing dance',
        'Do not feel something as the stress.',
      ],
      [
        'The main idea of selection',
        'Is straightly movement to the section',
        'Where all people are alive',
        'And nature is providing drive.',
      ],
      [
        'That’s just a fairy, not the blog.',
        'I have been mentioned that one slog,',
        'That slog but funny life of dance.',
        'I know just you. You know the sense.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 22),
    code: 'that-s-complex-when-the-human-should-resolve',
    content: [
      [
        'That’s complex when the human should resolve',
        'The problems while the world’s so quickly going on.',
        'But movement of the planet doesn’t stop',
        'Your imagination to be squat.',
      ],
      [
        'The stocky world is not idea',
        'For presentation of the stria',
        'That helps all people to be run',
        'While others can not do “Come on!”',
      ],
      [
        'The pleasant mention in description',
        'Is clever stable just a fiction.',
        'It is providing stable word',
        'That everyone can touch as spot.',
      ],
      [
        'While blot is showing the production',
        'Of nature’s dream with some induction',
        'You can stop ground and call art',
        'To be so pleasant kindly heart.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 22),
    code: 'the-world-became-the-strange-one-fest',
    content: [
      [
        'The world became the strange one fest',
        'Where I don’t know the quest.',
        'The single item I can do',
        'Is thinking like I am a crew.',
      ],
      [
        'No one except is single legend',
        'About world which strictly mentions',
        'The couple days before the start',
        'Of just creating of the part.',
      ],
      [
        'The part of theory like story',
        'Where some people don’t worry',
        'About broken steps of life…',
        'But there is my lovely drive.',
      ],
      [
        'It real beauty and full happy.',
        'It brings the real with the sappy',
        'Promotion to each one of time.',
        'Let’s do it drive, I love your rhyme!',
      ],
      [
        'That’s one of problem of the author',
        'To write as smoking papierosy.',
        'That’s joke, I hope each one in time',
        'Can see the fun in time of rhyme.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 22),
    code: 'who-is-the-travel-of-the-picture',
    content: [
      [
        'Who is the travel of the picture',
        'That representing global teacher?',
        'I know that answer but the step',
        'A people should provide as stack.',
      ],
      [
        'The first like element of view',
        'Is the discussion like the true',
        'Goes all around to the chess',
        'Of picture’s beauty to be bless.',
      ],
      [
        'Another element of structure',
        'Is the like spurt to common culture.',
        'The main idea of this step',
        'Is the avoiding in stack.',
      ],
      [
        'The third I’ve mentioned in theory',
        'That should be diff for trajectory.',
        'The seldom diff is new of mean',
        'Is new like pretty great full gin.',
      ],
      [
        'That wonderful a progress of all people',
        'Is pretty magic of beginning,',
        'The start of world where’s all done…',
        'I’m just repeating: Go! Come on!',
      ],
      [
        'But you are tired from description',
        'Where the world is definition.',
        'I don’t know how to explain',
        'That only moment does again.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 23),
    code: 'i-am-the-author-i-m-poet',
    content: [
      [
        'I am the author, I’m “Поэт”.',
        'You don’t know this word, just get',
        'The common sense of all that’s done.',
        'I’m just repeating… But all’s gone.',
      ],
      [
        'The gone is planet of the thinking',
        'Where some people are the linking',
        'An each one person planet Earth',
        'Do not do any more the stress.',
      ],
      [
        'The single issue of this feeling',
        'Is the condition of the meaning:',
        'The whole idea is a day',
        'When people don’t see the world in grey.',
      ],
      [
        'As a result I’ve met today',
        'The dream has been just leading to explain',
        'That people also do the drive',
        'In case the world does lovely life.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 24),
    code: 'i-dont-know-what-does-it-mean',
    content: [
      [
        'I don’t know what does it mean',
        'When people’re saying with the spin.',
        'I don’t know what is your smile…',
        'Is it a real or it’s just a style?',
      ],
      [
        'The style is perfect, no one issue',
        'That helps to solve the main one wish you.',
        'I wish you only keep your mind',
        'To be the strongest, highly right.',
      ],
      [
        'You’re right the first step’ve done already',
        'And world’s “Come on” is sounding ready',
        'To be the strategy of life',
        'Where just lives the full one drive.',
      ],
      [
        'This drive includes the real world',
        'With I’ve been mentioning the word.',
        'The single word is only step',
        'To just create the sense of “Yeap”!',
      ],
      [
        'This sense’s continuing just weekly.',
        'We’re never looking at beginning.',
        'The whole my words are written right.',
        'I wish you be just fully flight!',
      ],
      [
        'And this full flying of the real',
        'Do not to be as some one deal.',
        'Let it just be the flight of you',
        'To live so wonderful… You do.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 24),
    code: 'i-ve-found-range-that-s-strange-one-mention',
    content: [
      [
        'I’ve found range! That’s strange one mention',
        'That people do all own direction',
        'As all around not them one…',
        'Eh people, who do know that’s done?',
      ],
      [
        'You can not do of any chance',
        'To just include the own dance.',
        'The only chance is people get',
        'The movement of the life as set.',
      ],
      [
        'The music is a real picture',
        'Where’s the movement is as teacher.',
        'The other tone your only dance',
        'Is that you’ve done with own chance.',
      ],
      [
        'The chance is minute on the planet',
        'That is the movement of the granite.',
        'The mass of science is just kept down.',
        'You are alive and do “Come on”…',
      ],
      [
        'Like music mentioned with a pretty',
        'The science that lives as birthday’s sweety.',
        'Hey people, who is our DJ now?',
        'No music, oops. Let’s do! Come on!',
      ],
      [
        'And step by step we can explain',
        'What is the life, what is the main.',
        'But steps’re around and we’ve gone.',
        'The question was. The answer’s done.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 24),
    code: 'my-door-is-closed-no-one-s-beginning',
    content: [
      [
        'My door is closed, no one’s beginning...',
        'I think that hoping is the thinking',
        'That all reality is done',
        'But I’m repeating just «Come on!»',
      ],
      [
        'The door with master is a legend',
        'That can’t provide the sun’s arranging.',
        'The only think that it provides',
        'The house where you have all rights.',
      ],
      [
        'And these one rights are only slowly',
        'Can keep the sense of words with lowly,',
        'The lowly dance of self one done.',
        'I’m just repeating: Wow! Come on!',
      ],
      [
        'The door is opening so slowly',
        'That key has straight direction only.',
        'But some of inner element',
        'Has been so broken. What it’s meant?',
      ],
      [
        'That is the issue of the building.',
        'That’s not a legend of the meeting.',
        'The door is closed and no one chance',
        'To bring with phone some great full dance.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 24),
    code: 'the-day-can-start-as-pretty-quickly',
    content: [
      [
        'The day can start as pretty quickly',
        'The investigation what is strictly.',
        'Your own idea is a step',
        'To keep advance for day’s just end.',
      ],
      [
        'The main idea can to solve you',
        'As clever man who does’t want to,',
        'To get discussion of the world',
        'But that idea’s seldom work.',
      ],
      [
        'The other case to keep your mood',
        'As the discussion who’s just prude.',
        'You don’t know who is like so',
        'And you’re just moving to go on.',
      ],
      [
        'But in the case of some one people',
        'Do work - that’s pretty smart like steeple',
        'That has been built to do progress!',
        'What can I wish? But nothing… Guess.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 24),
    code: 'the-people-round-are-like-range',
    content: [
      [
        'The people round are like range,',
        'The strange provider of the age,',
        'The age of science and no ones know',
        'What is the random way “Come on”',
      ],
      [
        'That’s pretty strange just situation',
        'When people’re not just round station,',
        'The station of the the random train.',
        'Who can explain what’s gone today?',
      ],
      [
        'The single gone of way exception',
        'Is someone quickly declaration',
        'That every one of us is step',
        'To get self further to be great.',
      ],
      [
        'I don’t know the people’s reason',
        'But I know that this’ll be the season',
        'Of anyone who can not speak',
        'Regarding world around and do quick…',
      ],
      [
        'The quick of natural like jingle',
        'That every one is just a single,',
        'The single branch where code source',
        'Of nature’s bringing human sauce.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 26),
    code: 'hey-listen-wonder-one-milady',
    content: [
      [
        'Hey, listen wonder brave one lady!',
        'Can we just speak today? Are’yo ready?',
        'Do you have mind of open sense',
        'To just update an own glance?',
      ],
      [
        'That’s cool idea, I am sorry,',
        'I don’t have another method. Only…',
        'The only thing that should be done',
        'Is simple world’s just track “Come on!”',
      ],
      [
        'Who are you lady? Do you know?',
        'I think that’s other mention none!',
        'Just one you know the wordless dance',
        'As you are wearing rare dress.',
      ],
      [
        'I can’t explain the nature world',
        'Without natural some word…',
        'In case you’re able to just speak',
        'Your chance today to be a chic!',
      ],
      [
        'But there is a case you can forget',
        'That this one action is just bet…',
        'The bet one through the whole one life.',
        'Your choice. Do just what is alive!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 30),
    code: 'nikogda-zdes-nevozmozhno',
    content: [
      [
        'Никогда здесь невозможно',
        'Всё высказать и быть в первой.',
        'И люди знают смысл, возможно…',
        'Но ты стоишь перед о мной.',
      ],
      [
        'Всех слов же нет для выражения',
        'Понятий образа для лет,',
        'В которых весел, в отражении,',
        'Играет на басу куплет.',
      ],
      [
        'И в тех годах, когда упреки',
        'И лика своего и не найдут.',
        'А если все дела да не совсем так плохи,',
        'Тогда и образ рассуждений упрекнут.',
      ],
      [
        'Здесь для понятия манеры',
        'Есть только вся та сфера,',
        'Что в сердце жизнь встречает вспять.',
        'Но я хочу болтать, плясать.',
      ],
      [
        'И это раз же только в жизни,',
        'Когда один встречает вой?',
        'Но здесь ты не причем, не кисни.',
        'Я образ описал лишь свой…',
      ],
      [
        'В загадке повести для звука.',
        'Есть только социум времен,',
        'Внеклассно только все за угол',
        'Измерить радиус. Польщен…',
      ],
      [
        'Наш педагог планеты,',
        'Кого зовут Вселенною за всем,',
        'Что вдруг из ничего в конфеты,',
        'Поэзию, науку просто так за чем?',
      ],
      [
        'И вдруг глагол хранит молчание,',
        'А словом “Чем” да вдруг и выражен куплет…',
        'Так Настоящее в том ракурсе создания',
        'Рисует всю картину, как сюжет.',
      ],
      [
        'Он не в пространстве, в мире бесконечных.',
        'Он в том же времени для вечных',
        'Из смысла литеры до речи.',
        'Начало было. Все конечно.',
      ],
      [
        'И весь конец лишь в том начале',
        'Сил для создания себя…',
        'Тебя во сне увидел на причале.',
        'И всё движенье есть волна.',
      ],
      [
        'А быть конкретным, есть стремление',
        'И я же суть своих времен.',
        'Компьютер, браузер, вдруг уведомление',
        'К тому же поиску, что ты внедрен.',
      ],
      [
        'Ты есть в создании повсюду',
        'Всего, что видит твой же глаз.',
        'И люди светят в той причуде',
        'Своей энергией… Подарок? Шанс!',
      ],
      [
        'Для кульминации сюжета,',
        'Где ты не знаешь я о чём,',
        'Скажу лишь кратко: нет приметы,',
        'Есть только то, что обо всём.',
      ],
      [
        'И это всё есть вся Наука.',
        'Строим с буквы да большой сейчас,',
        'И завтра, неделя… Нет, не скука,',
        'Когда в душе играет джаз.',
      ],
      [
        'А смысл музыки стратегий,',
        'Есть передача всех затей',
        'Систематизацией процессов… Гений!',
        'Вот где ты если изобрел? Успей…',
      ],
      [
        'Как всякий диалог о всех причудах,',
        'А все… Как люди жаждет всё в уютах.',
        'Весь мир, как грезы. Жизнь моя,',
        'А что в реальности - своя.',
      ],
      [
        'That is the poetry, I’ve mentioned.',
        'The nicely choice to sign a section',
        'Into the book of dreams for mind.',
        'The world is real. We are kind.',
      ],
      [
        'I like the poetry as sense',
        'To just explain what is the dance',
        'That is the solving of the mind.',
        'I am repeating we are kind.',
      ],
      [
        'The other’s one a perfect lady.',
        'I can’t explain who is one ready.',
        'I don’t explain the nature world',
        'But I to speak such loudly want.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 31),
    code: 'na-russkom-tak-pisat-stihi-zhe-prosto',
    content: [
      [
        'На русском так писать стихи же просто,',
        'Как в азбуке не видно всех рисунков бытия.',
        'На всей земле, да, в переглядке невысоким ростом',
        'Идет создание мечты, и это всё Земля.',
      ],
      [
        'Поверхность так разноигрива,',
        'Что все в сторонку, шаг на криво,',
        'На криво ровно посмотреть',
        'И стих в рассказ переузреть.',
      ],
      [
        'Рассказ в просторах есть загадка.',
        'Она не ловится с оглядкой.',
        'Ей жить лишь только во красе.',
        'Поверхность общества - нигде.',
      ],
      [
        'И если здесь подумать дважды,',
        'То угадаешь ты однажды',
        'О том, что все есть на Земле,',
        'Кто восхищает жизнь да во красе.',
      ],
      [
        'Загадка просто без разлуки',
        'Всего вокруг же общества легенд.',
        'Не выдержит и не помрет от скуки.',
        'Бери с собой всемирный цвет.',
      ],
      [
        'С размахом рук такою лентой',
        'Мир только вверх умеет жить,',
        'И образ у мечты порой заветный,',
        'Что б всё узнать, живых поить…',
      ],
      [
        'Той водородною водою',
        'От слова род, что на Земле.',
        'Ведь аш два о содержит волю',
        'Устроить формулу и мне.',
      ],
      [
        'И при разгадке формулы для века',
        'В ней будет всем один ответ,',
        'Что жизнь идет для радостного смеха.',
        'Стихи писать на русском в честь легенд.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 4, 31),
    code: 'vot-stishechek-napisal',
    content: [
      [
        'Вот стишечек написал…',
        'Сам себя не ожидал!',
        'Все же образы затей',
        'Выражены за знаком всех частей.',
      ],
      [
        'Наука мой сериал суждений.',
        'Есть образ личных лишь стратегий.',
        'Все в образе, когда причал',
        'Узнал про всё и повстречал…',
      ],
      [
        'Свои разгадки для причуды,',
        'Что б было всё реально круто',
        'Для танца, чтобы с ветерком',
        'Узреть тот шаг за в даль платком.',
      ],
      [
        'Как воины на войну собрались дерзко',
        'И превратили все в ущелье, в детство',
        'Тех рассуждений бытия,',
        'Что и для жизни есть ничья.',
      ],
      [
        'Ведь ничего же существует',
        'В том облике, Вселенная. Та uni.',
        'Я лишь не знаю кто о ком,',
        'Но стих для рифмы с ветерком.',
      ],
      [
        'И смысл всех слов - одно суждение.',
        'Вселенный путь всех рассуждений',
        'В единый ракурс, создан. Всех,',
        'Кто знает, кто, да, человек.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 1),
    code: 'no-s-so-no',
    content: [
      [
        'No’s so no.',
        'No problem. You that know.',
        'The single issue of a day',
        'Is watching how are you away?',
      ],
      [
        'All people round are the party',
        'As we don’t know who is a smarty,',
        'The smarty person to keep down,',
        'To show the legend of that’s now.',
      ],
      [
        'No one can just describe the story',
        'As we got up but really slowly',
        'We do the action of the mind',
        'But’re looking stable and just kind.',
      ],
      [
        'No problem. I’m repeating twice.',
        'The world is round we are chance',
        'But no one know who is do fled',
        'To read the poem clean by head.',
      ],
      [
        'But you are seldom like a greeting',
        'That just displays the challenge’s meeting.',
        'No one, it’s clear, you’ve explained',
        'That little chance is really failed...',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 1),
    code: 'we-dont-exist-the-single-way',
    content: [
      [
        'We don’t exist. The single way',
        'Is going further just today.',
        'The whole one life is such express',
        'That I don’t see the smarty dance.',
      ],
      [
        'My dance has been appeared sudden',
        'That was the legend like the wonder',
        'Of list one issues just like quest',
        'To be the honest, likely best.',
      ],
      [
        'But here is the beautiful fun nature',
        'With no case of the like denature.',
        'With no like lives the whole one scene.',
        'The poem likely the run of seem.',
      ],
      [
        'The form of seeming’s just location',
        'As real world of dance, like nation.',
        'The whole one people into dance',
        'Do not decline the knowledge stress.',
      ],
      [
        'You know, the real fun location',
        'Consists of science like explanation.',
        'The whole ideas is the world.',
        'You know about saying what.',
      ],
      [
        'An items really dependent.',
        'They draw the summarise ascended.',
        'Endeavour runs like uni sleeps',
        'To see the real solving keep.',
      ],
      [
        'The literature’s, again I’m repeating.',
        'The real world of just a meeting',
        'By independent way of sense',
        'Just to discuss described one dress.',
      ],
      [
        'There is the single one solution.',
        'Protected real revolution.',
        'The real world lives with the days',
        'When people’s run of clever dance.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 2),
    code: 'you-ve-won-i-m-not-a-some-approach',
    content: [
      [
        'You’ve won, I’m not a some approach',
        'To just keep self like into watch.',
        'I don’t know how’re fine just you…',
        'My life is broken, I live true.',
      ],
      [
        'The seldom mention of selection',
        'Is clever fun’s just integration',
        'Into the world of happy dream.',
        'I know that you that know what’s mean.',
      ],
      [
        'But is it real or solution',
        'Of fairy tail for evolution?',
        'Ah, yes you know regarding that…',
        'The best one fairy at the end!',
      ],
      [
        'But if the case is living near',
        'Let’s do the mountain of the gear',
        'To start the life with just a talk',
        'That real world’s between the walk!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 5),
    code: 'hey-people-do-you-know-',
    ad: {
      link: 'https://www.instagram.com/la_dart',
      content: <AdLaDart />
    },
    content: [
      [
        'Hey people, do you know',
        'Who is an art the happiest role?',
        'I can present one person’s life',
        'That is an art with widely drive!',
      ],
      [
        'The sense of picture is like able',
        'To just provide the sense’s stable,',
        'The strictly stable mind’s one dance',
        'As the details are perfect glance.',
      ],
      [
        'An object oval or the label…',
        'Can do you know of pretty stable',
        'An education of your dream',
        'To keep some link as lunch one bean?',
      ],
      [
        'The rest around these one colours',
        'Can grow the picture of a flowers',
        'To bring win happy life of art,',
        'To give life’s entity for heart!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 5),
    code: 'i-cant-explain-you-only-know',
    content: [
      [
        'I can’t explain. You only know',
        'What is the main one reason’s stop.',
        'The movement lives like couple’f days.',
        'You can attract what are the ways?',
      ],
      [
        'The main activity had written',
        'Is what is kindly shortly smitten.',
        'That is the sense of just a day',
        'But you are clear, no one stay…',
      ],
      [
        'Regarding this one stop of beauty.',
        'I can repeat as some like duty:',
        'The clever legend is one move',
        'To side of knowledge to approve.',
      ],
      [
        'Approve idea of the mention',
        'Who is the real one attention.',
        'The real one is only you.',
        'Do not attention pay… You do.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 5),
    code: 'it-s-pretty-complex-to-suggest',
    content: [
      [
        'It’s pretty complex to suggest',
        'The life with no one quietly quest.',
        'The life is going but your walk',
        'Is like endeavour to have talk.',
      ],
      [
        'The most idea of the present',
        'Is to include the sound pleasant,',
        'To just create the life of dance',
        'But I don’t know where is a glance.',
      ],
      [
        'The life is stable but solution',
        'Keeps own self in constitution',
        'That’s written for a couple’s nice.',
        'The way is sudden, do it twice!',
      ],
      [
        'The second goal is your agreement',
        'That world consists of disagreement.',
        'The first one you are mind of world',
        'The second is the empty kindly word…',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 5),
    code: 'the-day-is-smile-one-thanks-you-re-great',
    content: [
      [
        'The day is smile one! Thanks, you’re great!',
        'No one idea to just make agreement… Let',
        'It be just what is seldom really but known',
        'As like the fairy’s self “C’mon”!',
      ],
      [
        'I don’t know how’s day of greeting',
        'With no one person of the meeting',
        'Into the kitchen to just tell',
        'What is the round world like bell…',
      ],
      [
        'That rings on every one condition.',
        'But maybe there is like mission?',
        'The best one choice is shoo-ten head.',
        'I love the fairy, that’s you’ve said!',
      ],
      [
        'That sound like self one construction',
        'Be able no way to production.',
        'A world is shorten but the dreams',
        'Are all around… Goddy! Jesus!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 6),
    code: 'i-dont-know-what-do-you-mean',
    content: [
      [
        'I don’t know what do you mean',
        'In case no answer, you are clean.',
        'Is it a chance to find just you',
        'Or all my dream just round too?',
      ],
      [
        'How can the message into service',
        'Decline ability of senses?',
        'How can we do a step to wood',
        'Where a life such pretty good?',
      ],
      [
        'Are you agree with some one action',
        'That it had brought the strictly section',
        'Into the life?.. I’m just myself.',
        'I don’t know regarding life yourself.',
      ],
      [
        'But I can not believe today',
        'That life is finished like a day,',
        'A day when’ve met you into hall',
        'That is the legend for that’s all.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 6),
    code: 'a-day-is-pretty-fine-attraction',
    content: [
      [
        'A day is pretty fine attraction',
        'Like world as sense of interaction',
        'With people different as sun…',
        'Can you be ready? Yeah! C’mon!',
      ],
      [
        'In case your life is huge one action',
        'Do not forget investigation',
        'Of trees that grow like looking’s step',
        'To life where some seeds are prep.',
      ],
      [
        'The preparation is a real.',
        'That’s no one sense into the deal.',
        'I know that science keeps own hand',
        'On book with strange like social land.',
      ],
      [
        'And this one land will be attractive',
        'Regarding sense that’s highly active.',
        'All people do what life tries give.',
        'We are so natural. We live.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 7),
    code: 'one-time-a-person-with-an-exp-one',
    content: [
      [
        'One time a person with an exp one',
        'Decided take a part in travel’s done.',
        'A done activity is work',
        'Where can listen people, talk.',
      ],
      [
        'A company is new one’f city',
        'That is the other, that’s a pity.',
        'But people round are such fun',
        'That soul presents a word “C’mon”.',
      ],
      [
        'Discussion’s every day are strictly',
        'What is the love of day like thickly,',
        'The huge one talks are every day',
        'And day is going as a “Hey”!',
      ],
      [
        'One time a philo talking started',
        'Regarding such express of hearted.',
        'All people round as a life',
        'Gives so emotions as own rife.',
      ],
      [
        'A person listen to a stories',
        'But it such funny with no worries.',
        'A people do a life like tale',
        'Or just can not to say a way?',
      ],
      [
        'Then human started a description',
        'What is a world like definition.',
        'No one can understand these thoughts',
        'But cool one lady brought some dots.',
      ],
      [
        'These dots gave people some emotion',
        'To keep themselves as big one ocean',
        'That listen to a human words',
        'But no one struggled own worlds.',
      ],
      [
        'Then people leave a kitchen sudden.',
        'There was a moment that is wonder.',
        'One lady asked a question then...',
        'This world’s built couple’s happy zen.',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 7),
    code: 'i-ve-met-a-person-not-so-far',
    content: [
      [
        'I’ve met a person not so far',
        'Who has just mentioned “Do! C’mon!”.',
        'His story was regarding wood',
        'Where has been a calm one should.',
      ],
      [
        'Trees were so like as a columns',
        'Into the table that is bonus',
        'To have direction for the mind,',
        'To keep the wood like pretty kind.',
      ],
      [
        'The columns gave an information',
        'That you and me at situation',
        'When we as people like a tree',
        'Want to discuss that’s all agree.',
      ],
      [
        'The further way was near anthill',
        'As was like looking of a high trill.',
        'The music was of moving guys',
        'They never keep seemed sweetie pies.',
      ],
      [
        'So such abstraction was idea',
        'To solve the mountain of the deal.',
        'A deal that this guy has done.',
        'A person is repeating day’s “C’mon!”',
      ],
      [
        'In closest look this one abstraction',
        'Became a real one construction',
        'Into this dream of every ant,',
        'Was like a builder of the plant.',
      ],
      [
        'This plant is anthill with solution',
        'To bring all people’s revolution.',
        'The such abstract is every day',
        'But closest eye is, yeah, today!',
      ],
      [
        'To look it closer, - mentioned person,',
        'We can resolve the hidden sense of,',
        'The sense of dialog of one guy.',
        'I don’t explain… Repeat it! Bye!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 7),
    code: 'a-person-came-to-local-zoo',
    content: [
      [
        'A person came to local zoo',
        'And saw a lot of animals in crew.',
        'An each one brute is closed in cell',
        'But felt as much one pretty well.',
      ],
      [
        'A human started to explain',
        'What is the brute’s one body’s brain.',
        'No one of team does not repeat',
        'That explanation just a bit…',
      ],
      [
        'Invalid as a real city',
        'Can build a person not so bitty.',
        'A zoo is explanation of the world',
        'Where an animal does not a word.',
      ],
      [
        'The sense of mention human heard',
        'But there was nothing like a bread',
        'For thinking such like closed by door.',
        'A person stayed on clean one floor.',
      ],
      [
        'The floor of team one definition',
        'That every step should be a mission.',
        'The real city is around',
        'But zoo with animals is ground.',
      ],
      [
        'Let’s stay such stable as this brute!',
        'Just sudden human screamed so good.',
        'A team had thoughts about way',
        'They should to solve a ticket’s day.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 7),
    code: 'the-first-one-stable-like-idea',
    content: [
      [
        'The first one stable like idea',
        'Is just to speak as people clear.',
        'The clean one sense of life is quick',
        'And we have no one moment’s kick.',
      ],
      [
        'A moment such one sudden perfect',
        'That human can not do life decked',
        'With any one detail of mind',
        'As moment is already do behind.',
      ],
      [
        'Do not touch memory with pleasant',
        'Regarding life that is such present.',
        'A nowadays is real time',
        'When sense of words again behind.',
      ],
      [
        'I am sorry for the words like absent…',
        'No one can know what is a present.',
        'No one but what is doing guy?',
        'That is the legend that’s behind.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 8),
    code: 'one-time-a-bear-s-walking-wood',
    content: [
      [
        'One time a bear’s walking wood',
        'And this one animal has stood',
        'On ground that is pretty fine,',
        'And always felt this guy great drive.',
      ],
      [
        'Then bear saw at wood like stranger',
        'And there is hive on branch like venture.',
        'Let’s take this hive on lunch one fest:',
        'A tea with honey will be best!',
      ],
      [
        'A bear started to climb on',
        'As there were no “C’mon”.',
        'And when an animal has reached,',
        'A bees took hive with flight’s impeached.',
      ],
      [
        'Ok, - said bear with the looking',
        'To other oak’s one branch just booking',
        'Its own flight to other side.',
        'This is like tale one to be guide.',
      ],
      [
        'At moment when a bear touched new branch',
        'A bees again tried to present their stanch.',
        'A hive was other’s oak again.',
        'A bear tried self explain…',
      ],
      [
        'Then thought appeared sudden wonder.',
        'Can I just ask bees to be under,',
        'Be under one of strength around?',
        'I just need talk with tea, it’s hard…',
      ],
      [
        'Hey bees, can you just help in act one',
        'To have a talk with interlocutor.',
        'I need a honey to have tea,',
        'You know, to talk around of the dream.',
      ],
      [
        'And where your dialog into wood',
        'Could some ‘locutor understood?',
        'I know one place near festival',
        'That has provided been a mind.',
      ],
      [
        'There is a public coffee’f jungle',
        'And honey will be done as real struggle',
        'To have a tea on Saturday',
        'At twelve o’clock… That’s cool one day!',
      ],
      [
        'When bear has pronounces wish',
        'A bees have winked like fairy swish.',
        'Then all the wood just started life',
        'To just prepare jug of hive.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 9),
    code: 'that-was-a-time-when-daniel',
    content: [
      [
        'That was a time when Daniel',
        'Just wanted feel as spaniel:',
        'Just started waiting for the food',
        'That makes society much good.',
      ],
      [
        'A dog is only future’s feeling.',
        'A dog is only straightly thinking',
        'What’s start of stand election’s way',
        'So near and that it will be today.',
      ],
      [
        'Politic had an education',
        'Composing different selection',
        'As like direction of a science',
        'But he just did that is, yeah, twice.',
      ],
      [
        'He liked to bring some like dependents',
        'To just create a mind’s descendants.',
        'That method wanted to provide',
        'Solution’s social best mind.',
      ],
      [
        'The most important in election',
        'Were actions to compose the section.',
        'The section: culture, science and kind,',
        'A kind election for not blind.',
      ],
      [
        'Diplomacy has been created',
        'And no one waited who’s selected.',
        'No one just knew about news',
        'But some of troubles were, Jesus!',
      ],
      [
        'The main mistake of Daniel',
        'Was as included culture’s way,',
        'Was too much close to life of crowd:',
        'Some people wanted Dany’s blood.',
      ],
      [
        'That was a country of a native',
        'But changed ability to dative.',
        'The great idea of reforms',
        'Was like a bullet of dream wars.',
      ],
      [
        'A man declared some like story',
        'To just describe a sense of holy.',
        'A holiday that should be done',
        'To stop selection, to be gone.',
      ],
      [
        'Nobody could to understand',
        'What Daniel kept into self.',
        'Selection was a zero - null.',
        'No one elector. Hey! C’mon!',
      ],
      [
        'That’s good idea to explain, -',
        'Once Dany said while lector’s day,',
        'Nobody that’s a current state.',
        'A dream is living, needs to wait.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 10),
    code: 'to-be-a-real-independent',
    content: [
      [
        'To be a real, independent',
        'That’s no one need as some extended,',
        'Extended world of uni’s mind',
        'Just need to be such pretty kind.',
      ],
      [
        'This world as I can see while walking',
        'Is stable but there are some joking',
        'As kidding while you do a change',
        'To people who explain the range.',
      ],
      [
        'This range is wide as so huge standard',
        'That you can not catch this extended,',
        'Extended world of uni’s mind',
        'As real life so pretty kind.',
      ],
      [
        'That’s movie as the real movement',
        'Depends on what is like improvement,',
        'Increasing knowledge every day',
        'While living happy just today.',
      ],
      [
        'It’s not enough to live in out',
        'Of tale that’s like a game Fallout.',
        'I just remember that this game',
        'Provided movement as like day…',
      ],
      [
        'With some like plan of yet tomorrow',
        'To keep decided, be has born of,',
        'Created to provide a life',
        'With huge amount self one drive.',
      ],
      [
        'That’s egoistic… No one keeps it.',
        'That’s strange but people clever thinking',
        'About self one done approach',
        'That helps to live with looking watch.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 14),
    code: 'forgotten-themes-are-so-dependent',
    content: [
      [
        'Forgotten themes are so dependent',
        'On world that is such funny like a present.',
        'A meeting people every day',
        'Means strictly mention of a play.',
      ],
      [
        'A playing is around seeing',
        'Into the product that’s beginning.',
        'A start is done but need some dance',
        'As music is not only a provider stress’s.',
      ],
      [
        'While any dialog with a people',
        'You can describe what is a dreaming',
        'As every person planet’s Earth',
        'Waits for the legend’s round dance.',
      ],
      [
        'Who don’t like to move its body?',
        'It is the stable world with hidden party.',
        'Today you’re business ‘rorrow chance',
        'To give around such progress.',
      ],
      [
        'Touch sample into deep one pocket.',
        'Some time will come there is a rocket',
        'That’s flighting further to be help',
        'For everyone as we do yelp…',
      ],
      [
        'When meet some clever people',
        'Who open minded and so clear.',
        'Thanks everyone who’s move itself.',
        'That’ll be a legend with no end!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 18),
    code: 'a-poem-has-been-written-partly',
    content: [
      [
        'A poem has been written partly…',
        'I know that’s planet for the growth.',
        'The Universe is greeting us so loudly',
        'That I can not do anyone as boss.',
      ],
      [
        'A boss is pretty strange one party',
        'When you just know what is your loud request.',
        'But people do an action seldom hearty',
        'As everyone knows what’s the world like best.',
      ],
      [
        'I don’t know what’s written there…',
        'I just try do a progress like non stop',
        'As Universe tells some like word - endeavour.',
        'Can you compose the definition? Alle-hop!',
      ],
      [
        'Do like a strange one clever buddy',
        'That your like mind around for the dreams',
        'And then, a couple years’m thinking - study',
        'Will give non stable some one streams.',
      ],
      [
        'And everyone, who catched this legend,',
        'Can not return in world that people built,',
        'As one or two like defs of funny stranger',
        'Seem like a travel into picture when you sleep.',
      ],
      [
        'But real world is def default’s one.',
        'No words! You know, that’s funny dance',
        'While everyone can take at hands “C’mon! Done!”',
        'To present what is human real sense.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'ru-RU',
    date: new Date(2023, 5, 19),
    code: 'prosnulsja-ponjal-chudo-mira',
    content: [
      [
        'Проснулся - понял чудо мира',
        'И распознал того кумира,',
        'Что кто-то рядом говорил.',
        'Прошла минута… Сон забыл.',
      ],
      [
        'Затем поднялся, отряхнулся.',
        'Прошелся, миру улыбнулся.',
        'Сел в кресло, двери приоткрыл',
        'И вот опять я сон забыл.',
      ],
      [
        'И память без культуры взгляда',
        'Всем выражает то, что и не надо:',
        'Культурный марш для всех веков.',
        'Сон был с кумиром. Был таков.',
      ],
      [
        'Но в том пространстве между нами',
        'Лежит понятие, распознано веками.',
        'Субъект, объект - ты лишь один.',
        'Весь мир танцует. Ты лишь был…',
      ],
      [
        'Был кем-то вроде интриганта,',
        'Кто знает, что всем в день лишь надо.',
        'Кумир есть образ, есть сюжет.',
        'Но что не делай - все лишь бред.',
      ],
      [
        'Бред свой, ни в чем не различимый.',
        'Бред всего мира изречивый.',
        'Все люди просто говорят',
        'Все то, что скажет твой же взгляд.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 20),
    code: 'this-is-a-dream-you-can-not-stop-it',
    content: [
      [
        'This is a dream, you can not stop it',
        'While life of your one meaning fought, read…',
        'The reading is a book of way',
        'That has been written, done today.',
      ],
      [
        'Such many people are around',
        'That you can not stop underground.',
        'The book is written, you do day.',
        'But what’s the reason? Can explain?',
      ],
      [
        'One reason you do very loudly',
        'That everyone can catch this proudly.',
        'No secrets, please, just do a flight',
        'And scream that life is real light.',
      ],
      [
        'That’s joke… No’s any definition.',
        'Just want to know, is that a mission',
        'To speak around whole the world',
        'But reading slowly one of word?',
      ],
      [
        'The word is reason, that is sound.',
        'The word is sense to be not crowd.',
        'The word is love’s exquisite fun!',
        'That’s proudly reveal of C’mon!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 20),
    code: 'morning-came-and-world-is-fun',
    content: [
      [
        'Morning came and world is hum!',
        'There are some words like “Do! C’mon!”',
        'No one idea what is stay.',
        'No human knows what is a play.',
      ],
      [
        'A game is life with some one real',
        'An independent movie’s deal.',
        'A life like game touches sense of step',
        'That is the next to build a camp.',
      ],
      [
        'A forest round camp like seems too strictly.',
        'Do anyone can do a task such quickly',
        'As that one bear with a tea',
        'To add a honey? Be like π.',
      ],
      [
        'Into that forest no one jokes have been',
        'To just explain what is a sense I mean.',
        'That should be read as party, growth',
        'On front of eyes of people both.',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 20),
    code: 'the-other-side-of-travel-meaning',
    content: [
      [
        'The other side of travel meaning',
        'Is just self mind to be a greening.',
        'An empty one of senses dreams,',
        'I think, that like a story seems!',
      ],
      [
        'The story is with culmination.',
        'I don’t correctly know the mention.',
        'The story as a world with beems',
        'To grow a tree like oak for dreams.',
      ],
      [
        'You know, the second line of story',
        'Tries to keep down, don’t worry,',
        'Tries to catch up the travel dance',
        'But real world provides the sense.',
      ],
      [
        'And into this one only reason',
        'Feel just yourself as summer season.',
        'Let’s sun do growth of your just mind',
        'Like all one people such are tined!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 23),
    code: 'a-strange-but-definite-laws-people',
    content: [
      [
        'A strange but definite law’s people',
        'Try to catch up that is a single.',
        'A legend draws the scene of us',
        'To be a future, to be trust.',
      ],
      [
        'While everyone tries speak a deal',
        'No one can hope that world is real.',
        'All whole around just a tail',
        'To be dependent. No’s explain.',
      ],
      [
        'Strong sense of value with fairy',
        'Translates the world into some rainy,',
        'A storm of mind with no one feet',
        'Of the solution to be bit.',
      ],
      [
        'A bit a smallest digest item.',
        'Can you control this own random?',
        'Just one per second keep yourself',
        'Into the world of uni’s self.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 25),
    code: 'hey-people-are-you-ready-dancing',
    twitter: {
      id: '1671575214537289728',
    },
    content: [
      [
        'Hey people, are you ready dancing?',
        'That’s the idea to be real one advancing.',
        'No one can stop your own kind',
        'Of real nature that’s behind.',
      ],
      [
        'Let’s keep the sense into the paper',
        'That will be published soon, whenever…',
        'Let’s do a joke for whole this world.',
        'I don’t’ explain “C’mon” like word!',
      ],
      [
        'It has the magic of the deal',
        'To be extended while you’re real.',
        'C’mon all people! Do a dance!',
        'That’s first one step of legend’s glance!',
      ],
      [
        'On Sunday evening do your party loudly!',
        'There’ll be radio with show so proudly,',
        'Will just display what are the sounds',
        'To keep your self in mind of cloud’s.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 25),
    code: 'the-strange-idea-of-the-world',
    content: [
      [
        'The strange idea of the world',
        'Gives motivation be like forward.',
        'The highest level of the sense',
        'Brings into life the struggle fest.',
      ],
      [
        'We can not do the single party.',
        'We can just only like this smarty.',
        'Your like’s endeavour to be kind',
        'As people do not touch a mind.',
      ],
      [
        'Behind the sense we can bring down',
        'An apple that is whole around.',
        'Take this one taste while you do jump',
        'Into the world where you’re a lump.',
      ],
      [
        'That’s rude but sounds as idea',
        'To keep all people with the deal.',
        'The economic goes away',
        'As real world is build as way.',
      ],
      [
        'A way to just complete one mention',
        'As we all people are dimension.',
        'An area of us lives round.',
        'Do your voice level pretty loud…',
      ],
      [
        'To speak with everyone as level',
        'That everyone will catch as travel',
        'Around word with single sense',
        'That we are happy?! Do a dance!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 5, 26),
    code: 'i-can-not-drink-the-special-water',
    twitter: {
      id: '1673346653527453704',
    },
    content: [
      [
        'I can not drink the special water',
        'That gives a sense to be like blotter.',
        'That’s fun, you know, but world’s alive',
        'And music sounds like “Get five!”',
      ],
      [
        'Do not accept your body knowledge',
        'In case you are a stranger’s college.',
        'I don’t know who is you are?',
        'But world and music is alive!',
      ],
      [
        'Do not accept that’s rude, I’m sorry.',
        'But that’s the truth! Bye… Don’t worry!',
        'Into the real world of dance',
        'All people try to catch enhance.',
      ],
      [
        'That chance provides the real statement',
        'Into a huge philo restatement',
        'Of all the world around us.',
        'I don’t think that’s world’s discuss.',
      ],
      [
        'But meeting people real diff one',
        'You know what’s speaking like a deal.',
        'To get conclusion - I just write',
        'A poem like about keeping kite!',
      ],
      [
        'This airliner do mirrors placement',
        'About Universe such fun like basement',
        'Into some people do a dance',
        'While looking at the world’s enhance!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 1),
    code: 'let-s-do-a-party-hey-mc',
    content: [
      [
        'Let’s do a party! Hey MC!',
        'We’re such one strong to learn, to be!',
        'We every day require dance',
        'As this one planet not’s a stress.',
      ],
      [
        'Around world that’ve met before me',
        'A music sounds just to stop free,',
        'A free discussion river’s dream',
        'But sun is shining! Here’s a beam!',
      ],
      [
        'That beam completes a way of thinking',
        'That everybody tries to bring in,',
        'Into the sense of college age.',
        'We smart but nature pretty strange…',
      ],
      [
        'That strange discussion’s round singing',
        'A world between like must’s beginning.',
        'The start of people who are sound',
        'With value executive proud!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 2),
    code: 'a-day-s-beginning-pretty-quickly',
    content: [
      [
        'A day’s beginning pretty quickly',
        'As no one sense at morning strictly.',
        'Your mind’s around many dreams',
        'But sun is shining! Catch all beams!',
      ],
      [
        'A sun is such one independent',
        'That world’s endeavouring like statement.',
        'The statement is declared ones',
        'And people’re dancing doing runs.',
      ],
      [
        'That chance is only for brave kingdom.',
        'A planet Earth’s helmet wisdom.',
        'All people round know that sense',
        'But words are absent! That is stress…',
      ],
      [
        'That every day with sun at morning',
        'Tries to believe that world is slowing',
        'To be such hurry to address',
        'Subjective view in single sense.',
      ],
      [
        'But words are not enough for people…',
        'Do action! Hey, c’mon! Your steeple',
        'Is going higher as your sense',
        'Is unforgettable fly glance…',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 3),
    code: 'you-are-the-similar-but-worry',
    content: [
      [
        'You are the similar but worry',
        'About world existing. I am sorry.',
        'A Universe’ve created us',
        'And soul my’s playing funny jazz!',
      ],
      [
        'A music is the perfect structure',
        'That gives all people some restructure.',
        'I mean just clearing of a mind',
        'To be so pretty. I am kind.',
      ],
      [
        'You can not say a couple mentions',
        'Given to me as like adventures.',
        'A traveling is simple way’f',
        'Composing mention every day.',
      ],
      [
        'I love a music, I am sorry.',
        'This world keeps hand on magic story.',
        'This fairy tale around us.',
        'I wish your soul a real jazz.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 8),
    code: 'no-poem-no-the-steam-of-ledgends',
    content: [
      [
        'No poem - no the steam of legends.',
        'No poem and all people like a strangers.',
        'We don’t know who knows the sense',
        'Of whole the planet taking dance.',
      ],
      [
        'But dance is pretty independent',
        'That follows dreams to be extended,',
        'An obvious teen helps people think',
        'But no one helps to itself drink.',
      ],
      [
        'The dance with drink is such one wonder.',
        'It helps to feel external under,',
        'Behind the normal sense of life',
        'And all one people at this drive.',
      ],
      [
        'But no one knows what is decline',
        'Because the companies are wine.',
        'Do not accept destructive way',
        'And live with love of world today!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 10),
    code: 'a-wonder-s-day-and-you-are-hear',
    content: [
      [
        'A wonder’s day and you are hear',
        'Into the fairy with the jokes such near.',
        'All people know who is you are',
        'But this one knowledge real “Wow”!',
      ],
      [
        'One gent was trying to explain',
        'These jokes that are such risen’le day.',
        'But real worlds don’t help with words',
        'As you are such a cat with birds.',
      ],
      [
        'That game all people near quickly',
        'Transform to some one moment strictly.',
        'All whole your bird’s dream every day’s',
        'Composing of the clever, wonder ways!',
      ],
      [
        'Just don’t forget regarding cat',
        'Who is as one of legends sprat.',
        'It lives in ocean of a way',
        'That you’re correct discussed today.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 11),
    code: 'in-state-when-people-are-keep-down',
    content: [
      [
        'In state when people are keep down,',
        'You know, that world is playing noun',
        'Into the game where love lives strange',
        'But you are waiting to the word’s line change.',
      ],
      [
        'The movement of the happy streaming',
        'Is change of nature to be living.',
        'A planet Earth is one of sense',
        'But change is waiting for the hence.',
      ],
      [
        'The movement of the happy streaming',
        'Is change of nature to be living.',
        'A planet Earth is one of sense',
        'But change is waiting for the hence.',
      ],
      [
        'This night is one of keep down legends',
        'As people just at night like rangers',
        'That from the fairy came to wait',
        'The sudden beam to be just great!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 12),
    code: 'you-listen-me-i-know-it-feel-it',
    content: [
      [
        'You listen me! I know it, feel it.',
        'You know how just to be as charge’s bit.',
        'You are terrific one in world.',
        'Why don’t you keep legend? What?',
      ],
      [
        'I am agree that day is sudden.',
        'I am agree that we should down.',
        'But that’ve discussed with notes of month',
        'And yes, you know, that’s playing jazz.',
      ],
      [
        'How to explain this rare one just sound?',
        'It’s not a magic, I know now it.',
        'The music’s playing once a life.',
        'That is the rare one deep dive!',
      ],
      [
        'I love it that’s a native world.',
        'I love it but I’ve missed a word…',
        'The sound was regarding cloud',
        'That is the speaking such much loud!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 13),
    code: 'the-world-is-strange-that-s-not-a-reason',
    content: [
      [
        'The world is strange. That’s not a reason',
        'To live like one dependent season.',
        'That time of summer such one great',
        'That soul is calling self to sate.',
      ],
      [
        'An entity that gives a power',
        'Is nothing that you know that hour.',
        'The world is strange but people live',
        'And they are trying something give.',
      ],
      [
        'That’s joke as everybody’s real',
        'Is going on as some one deal.',
        'I don’t know why’s world is strange',
        'But people are such likely change…',
      ],
      [
        'When you are speaking as a real',
        'With no one motivation’s deal.',
        'That day just everyone’s with sense',
        'To keep its own perfect glance.',
      ],
      [
        'That’s beautiful for slowly moving',
        'But no one legend will be movie.',
        'The films of life are sun such bright',
        'As you can touch this hot one drive.',
      ],
      [
        'Then step, please, further and explain',
        'What is about now your brain.',
        'Then keep this notes till your great fest',
        'When people round are life’s guest!',
      ]
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 15),
    code: 'in-case-you-re-feeling-some-one-stress',
    content: [
      [
        'In case you’re feeling some one stress',
        'Do not forget again address',
        'Where all one people do a world,',
        'Where dance and keeping are the word.',
      ],
      [
        'That word again I’ve changed for linking',
        'To an ability straight widely thinking.',
        'The sense of psycho is a game',
        'Where we can not do full explain.',
      ],
      [
        'The real word is psyche real.',
        'That’s not a game, that’s kindly deal',
        'To speak as nature’ve given dance',
        'For twenty age with jokey’s glance.',
      ],
      [
        'I know, you know but there’s deal',
        'To speak or choose a movement’s real.',
        'The real world is coming now.',
        'The single word is “Do! C’mon!”',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 17),
    code: 'in-case-you-need-to-do-get-up',
    content: [
      [
        'In case you need to do get up',
        'You’re really just rare punk.',
        'There is no words to say today',
        'As you’ve selected not a way.',
      ],
      [
        'Your movement is the Goddy blesses',
        'As you don’t know whom are successes.',
        'That’s travel into near swap',
        'To say just single word: “Please buddy, stop!”',
      ],
      [
        'You don’t know what is about',
        'Your stop should be to go just out.',
        'You’re trying sweet but sour cream',
        'Is not providing brightly beam.',
      ],
      [
        'You’re speaking, yeah, a lot for jungles',
        'As looking for a step so bungles.',
        'Keep it yourself. It is. It’s world.',
        'No looking’s for just single word.',
      ],
      [
        'That’s word is you! Hey, do you know',
        'Who is a person for “C’mon”?',
        'It’s me. It’s you. Try to explain',
        'Whom is a legend, by the way…',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 18),
    code: 'you-can-not-do-the-single-way',
    content: [
      [
        'You can not do the single way',
        'That you such want to me explain.',
        'Do your one party every day',
        'And people’ll think that you’re away…',
      ],
      [
        'Nobody wants to just discuss',
        'What is around: great full dance.',
        'In bar just every life’s in home',
        'That calls me out… “Hey! C’mon!”',
      ],
      [
        'Behind the bar with alco dreaming',
        'Exists a person such one dealing.',
        'The deal into worlds of sense',
        'And keeping its into the dance.',
      ],
      [
        'This dance is fun… But what’s a real?',
        'Can you explain where should peel?',
        'You’re needing self decline the drink',
        'Composing natural life link.',
      ],
      [
        'Clink on the button! Click, hey buddy!',
        'You’ll see the perfect into study.',
        'The lesson is just your one life.',
        'Be clear person… You are rife!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 18),
    code: 'in-case-the-issue-s-just-for-you',
    content: [
      [
        'In case the issue’s just for you',
        'Do not accept the stranger’s flu.',
        'It can be serious but dance',
        'Can fly into the sudden glance.',
      ],
      [
        'That’s not an illness, that’s idea',
        'To be a movement with a deal.',
        'That deal’s only once a life.',
        'I’m just repeating: that is drive!',
      ],
      [
        'But people’re pretty independent',
        'And can not see world that’s extended.',
        'This whole one area of dreams',
        'Once drops a sun with clear beams!',
      ],
      [
        'Then you’ll sit down and’ll explain',
        'What is the difference today.',
        'Keep your just mind in pocket while',
        'You do the progress that’s behind.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 18),
    code: 'hey-people-are-the-trains-such-quick',
    content: [
      [
        'Hey people! Are the trains such quick',
        'That you can not do own trick?',
        'Relax, please be as everyone',
        'And world around’ll say “C’mon!”',
      ],
      [
        'That’s joke… In case you’re as one everybody',
        'You can not do your own party.',
        'What is the reason people’s voice',
        'When someone really with choice?',
      ],
      [
        'That choice is real but between',
        'Some ladies that so fun like queen!',
        'You should just select single way',
        'But you are feeling self away…',
      ],
      [
        'But at the morning when world’s down',
        'You can make hug that is around,',
        'Around whole your world about God…',
        'I wish you, lady, be such proud!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 26),
    code: 'what-can-you-feel-to-keep-eleven',
    content: [
      [
        'What can you feel to keep eleven',
        'Regarding dream where world is stable?',
        'I think that nose takes smoke of round',
        'That is eleven. Underground.',
      ],
      [
        'Why’s numerous decision quickly',
        'Retrieves the mind to be and sing it?',
        'The step by step your number now',
        'Creates the world with word “C’mon!”',
      ],
      [
        'And you feel math’s neural one meaning.',
        'You can not do what is a linking',
        'Between a people gender’s sense.',
        'You like to think. That’s your just dance.',
      ],
      [
        'So wish you lady from a Europe',
        'To be such natural to move it,',
        'To move a Universe with clever mind.',
        'All our books are such refind!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 25),
    code: 'i-like-your-eyes-they-are-such-rare',
    content: [
      [
        'I like your eyes! They are such rare',
        'That main one sense is while whenever…',
        'A dream of highly levels thoughts',
        'Is walking through a people both.',
      ],
      [
        'The explanation is such fairy',
        'That world is going some like airy,',
        'The airy mountain of the wood',
        'Where all people’ve understood.',
      ],
      [
        'That excellent solution’s real',
        'That one approach’s like you are feeling.',
        'The most emotion of your look',
        'Is you are kind! Give me’n book…',
      ],
      [
        'To note some people’s just extension',
        'That you and me are like a station',
        'Where a couple like a trains',
        'Try to catch up its own ways.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 6, 28),
    code: 'i-ve-met-some-people-dreaming',
    content: [
      [
        'I’ve met some people dreaming',
        'Into the street behind of us.',
        'That sense lived as a living',
        'And played a funny, lucky jazz.',
      ],
      [
        'All people round where from the sphere',
        'That’s digital’s a progress of the world.',
        'And anyone’s full step of “Wow!” career',
        'Was into country of the different light word.',
      ],
      [
        'This word is progress, to be honest quickly.',
        'That dance of movement keeps at sense, at jazz.',
        'I’ve mentioned couple dreams such pretty thickly',
        'That city is regarding play’s act as…',
      ],
      [
        'The newest context to provide small talking',
        'At eight o’clock with no more schema of meet ups.',
        'I wish you people be such fun and joking',
        'To love science’s life and art’s default dance.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 7, 1),
    code: 'somehow-me-and-one-lady-s-soul-of-mind',
    content: [
      [
        'Somehow me and one lady’s soul of mind',
        'Created a huge picture that’s all right.',
        'The week around’s not a dream.',
        'The meeting’s near… Sun with beam!',
      ],
      [
        'That beam creates the funny legend',
        'Where all people’re such a strange one.',
        'No one around can to do',
        'A progress that I caught for you.',
      ],
      [
        'That progress is about artist',
        'That just behind as like the smartest.',
        'That’s not just me. That’s all this world',
        'That plays a music’s afterword.',
      ],
      [
        'This word is strange and pretty rare.',
        'That word is love to be like air',
        'That every one loves each one’s step',
        'To wish each other grateful prep.',
      ],
      [
        'That preparation for the people',
        'Gives chance for age to be a real.',
        'The real world’s around us.',
        'The music’s playing with a bass.',
      ],
      [
        'Don’t stop please, don’t do the changes.',
        'That is event to help exchanges,',
        'Exchange of thoughts of each one dream',
        'With art and science’s such beauty theme.',
      ],
      [
        'And this one legend is alive.',
        'This one express requires drive.',
        'The own drive for years’f life.',
        'I wish you look for movement’s rife!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 7, 4),
    code: 'i-dont-know-how-to-explain',
    content: [
      [
        'I don’t know how to explain',
        'What is around for the way.',
        'The way is single and my life',
        'Tries to keep down own rife.',
      ],
      [
        'That’s pretty strange, I don’t think',
        'About nature that around as a link.',
        'This link with click for couple days',
        'And people round are away.',
      ],
      [
        'I can not feel, I mean, solution.',
        'I can just drink the world’s confusion.',
        'I don’t know where’s my chance.',
        'I know what is my low one dance.',
      ],
      [
        'The written text is just a joke.',
        'I hope that legend goes like folk.',
        'An animal sees each one dream',
        'And sun is shining, people’re beam!',
      ],
      [
        'I don’t know who is the first.',
        'I know that all behind is fest.',
        'The fest is going and my eyes',
        'Send just to you a capi price.',
      ],
      [
        'The capi is just business value.',
        'It is a world, you know, I see you.',
        'I have no one the struggle quest',
        'But I wish you the legend’s west.',
      ],
      [
        'That part of world is just around',
        'But I live now in underground.',
        'The music, poem just translates',
        'The whole idea be like mates.',
      ],
      [
        'Who is one, second can’ou explain?',
        'That is a sound, that’s a way.',
        'The single way of thinking’s cloud…',
        'Be, lady, crazy with huge proud!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 7, 4),
    code: 'there-was-a-time-of-ages',
    content: [
      [
        'There was a time of such fun ages',
        'That it can not meet with a strangers.',
        'No one can switch to own role',
        'With no one smart and deep cool bowl.',
      ],
      [
        'This pot is often full of stories',
        'About world around. Don’t worry…',
        'No one can be such clear in,',
        'Into the science you know to win.',
      ],
      [
        'The competition is for holy',
        'The only fest where’re people slowly.',
        'No one again can catch it sense',
        'As you are greatfull, do your dance!',
      ],
      [
        'Your dance is so high with the word',
        'For ages doing it like world.',
        'You’re looking it for every day',
        'And this one legend is today!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 7, 4),
    code: 'who-can-explain',
    content: [
      [
        'Who can explain',
        'What is a way?',
        'I don’t know where’s today.',
      ],
      [
        'That’s match around:',
        'Underground.',
        'That’s dream to be a guy of proud.',
      ],
      [
        'That dream is living',
        'Just a day of thinking.',
        'Then morning and just nothing’s meaning.',
      ],
      [
        'The other’s day',
        'Again a way.',
        'I met you now, that is today.',
      ],
      [
        'Then me and you',
        'Are keeping crew.',
        'That is a legend of a true.',
      ],
      [
        'But no one knows',
        'When we are close',
        'And art with sun such further goes.',
      ],
      [
        'Keep it just living.',
        'We are thinking.',
        'This world lives and the knowledge’s giving…',
      ],
      [
        'This part of science',
        'Of great alliance.',
        'It comes a week with compliance.',
      ],
      [
        'Then you and me’re',
        'Just going’s dream.',
        'That is a legend for the team.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 7, 15),
    code: 'give-me-some-money-for-a-bear',
    content: [
      [
        'Give me some money for a beer, -',
        'Someone pronounced as like gin.',
        'But honestly, I have just word',
        'That’s no one sense in this drink world.',
      ],
      [
        'He traveled further to explain',
        'Itself what is a way today.',
        'But my way’s far from this one fool.',
        'I’m doing action that’s fun, cool.',
      ],
      [
        'This job’s name is an understanding:',
        'What does a world around give a plenty?',
        'No one is nothing at the world',
        'While you’ve not said the hugest word.',
      ],
      [
        'This word is yours an act of living',
        'For real features with a dreaming.',
        'All dreams are separated of an act.',
        'That’s name is physical that’s cracked.',
      ],
      [
        'I can not do pronunciation now.',
        'All world’s just living as “C’mon!”',
        'C’mon this guy for beer dance.',
        'No one has universe’s dance.',
      ],
      [
        'That dance is keeping in subjective,',
        'The single way that is attractive,',
        'It is alluring just for you.',
        'How to explain… Just do! Just do!',
      ],
      [
        'And action of the book of life',
        'Will be a fuel of your drive.',
        'All legends’re ready. Be itself.',
        'Look at the mirror, find a shelf.',
      ],
      [
        'Make step for this one huge idea',
        'And be an awesome, be a real,',
        'Be real human with a wish.',
        'For people round do a swish…',
      ],
      [
        'To start its own great one drive.',
        'Do not do asking beer’s kind.',
        'Forget these words, it’s just a dream',
        'That people round are sun’s beam!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 8, 11),
    code: 'i-came-to-bar-and-asked-for-juice',
    content: [
      [
        'I came to bar and asked for juice,',
        'A lemon one. I don’t lose',
        'A universe with kindly dreams',
        'Like film’s endeavour to be peace.',
      ],
      [
        'A lady pretty slowly could explain',
        'That this one juice from bottle drain.',
        'My wish was drink of nature one',
        'But she was trying me be down.',
      ],
      [
        'To be an honest any travel:',
        'A bar’s where people not a stable,',
        'The stable meaning of the world',
        'As anyone do it like blot.',
      ],
      [
        'A blot on heart to just be simple',
        'As anyone with face as stifle.',
        'No one can understand a life',
        'As world is full of business right.',
      ],
      [
        'I am much more then you my baby, -',
        'I hear near with explaining.',
        'Conclusion’s word was “Give me dance!”',
        'But she was drunk, there no one chance.',
      ],
      [
        'Hey mister, are you full of nothing',
        'To just explain that lady’s dancing?',
        'Her dance in mind do not rich her.',
        'A mister’s clever. Asked again.',
      ],
      [
        'Then was a party pretty near',
        'And he was walking with a dear,',
        'A dear bottle of a juice.',
        'A lemon was a world’s one Zeus!',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 8, 16),
    code: 'the-single-world-behind-my-dreams',
    content: [
      [
        'The single world behind my dreams',
        'And people look at you like peace.',
        'A peace around but your dance',
        'I don’t see, that’s lowest chance.',
      ],
      [
        'Behind the world you look fun, crazy!',
        'You don’t do your job such lazy.',
        'Such lazy guy that’s near you.',
        'You know, I think just once next to.',
      ],
      [
        'I don’t know is it a real',
        'That every day is just a deal?',
        'Delaying waiting is a quest',
        'Where people round are the best.',
      ],
      [
        'All dreams about you and rest',
        'Come to your home to be the best.',
        'You know all best keeps your eyes hear,',
        'At place that is such fun and near.',
      ],
      [
        'I don’t know who’re you but sense:',
        'All that one poetry like quest',
        'About single letter’s dream…',
        'Key to the door is yours sun’s beam.',
      ],
    ],
  },
  {
    title: void 0,
    uid: uuidv4(),
    locale: 'en-AU',
    date: new Date(2023, 9, 10),
    code: 'do-you-know-enlightenment',
    content: [
      [
        'Do you know enlightenment?',
        'That’s a nothing with a trend.',
        'In case your thoughts are strictly dream:',
        'You are enlightenment… What’s you mean?',
      ],
      [
        'You are a child who sees youtube days.',
        'I think you do not like a real clever dance.',
        'This one agreement just for you',
        'As you can not imagine true.',
      ],
      [
        'That’s real fun like understanding',
        'How people earn a money standing',
        'Just like an elephant on scene',
        'And just again has own dream.',
      ],
      [
        'That dream is separating social meaning',
        'In some one case like who has dreaming.',
        'Like someone knows that you do not',
        'But that one words are real joke.',
      ],
      [
        'The main one problem to explain',
        'That each one person has a way.',
        'That way should be done, solved a day',
        'When this one person can explain…',
      ],
      [
        'It’s own one just like idea.',
        'The other word are just a dreaming.',
        'That no one sense to listen them',
        'As that one way just yours explain.',
      ],
      [
        'Can you me understand right now',
        'That your one life is word “C’mon”?',
        'The other people don’t your life.',
        'You are the single thought. All right…',
      ],
      [
        'That’s real tiny explanation',
        'That everyone can do as action,',
        'The one activity of life',
        'Be only one with own drive.',
      ],
      [
        'Do not except collective, dreaming.',
        'You only one know that one living.',
        'The other person in your life',
        'Is only love to be a drive.',
      ],
    ],
  },
  // {
  //   title: void 0,
  //   uid: uuidv4(),
  //   locale: 'en-AU',
  //   date: new Date(2023, 4, 21),
  //   code: 'i-was-in-bar-and-ve-made-mistake',
  //   content: [
  //     [
  //       'I was in bar and’ve made mistake.',
  //       'I just pronounced my locale',
  //       'From what one country I have come',
  //       'And people started load drum.',
  //     ],
  //     [
  //       'The main idea of the progress',
  //       'That every person is a child',
  //       'Who listen everyone with nonsense',
  //       'To understand who is a wild.',
  //     ],
  //     [
  //       'But into country when I’m current',
  //       'The way of life just do create',
  //       'So many people underground,',
  //       'Just think that I am not upgrade.',
  //     ],
  //     [
  //       'The problem is the culture singing',
  //       'That years ago came to the field.',
  //       'I can just know that I can thinking',
  //       'As child that mentioned that one yield.',
  //     ],
  //     [
  //       'The yield was one the presentation',
  //       'That is the truth for everyone.',
  //       'I hope that people with creation',
  //       'Will not aggressive be so on.',
  //     ],
  //   ],
  // },
  // @note: Do I need to publish this? The sense can be complex or the strictly undefined as nothing.
  // {
  //   title: void 0,
  //   uid: uuidv4(),
  //   locale: 'en-AU',
  //   date: new Date(2023, 4, 18),
  //   code: 'today-is-day-when-kitchen-visit',
  //   content: [
  //     [
  //       'Today is day when kitchen visit',
  //       'Presented people some of defs.',
  //       'The main solution of revisit',
  //       'Was just appointment of stress.',
  //     ],
  //     [
  //       'The weight that is some defs for people',
  //       'Must be confirmed just some around',
  //       'To be like tone in world so real',
  //       'With person who can stay on ground.',
  //     ],
  //     [
  //       'These people pretty seldom lockage',
  //       'To close the sense of world for us.',
  //       'I hope this knowledge “What is problem?”',
  //       'Can help them just to live at once.',
  //     ],
  //     [
  //       'The problem is the strictly speaking...',
  //       'The problem is the task be solved.',
  //       'To be - the main a verb of leaking',
  //       'The information… But that’s ok.',
  //     ],
  //   ],
  // },

  // {
  //   title: void 0,
  //   uid: uuidv4(),
  //   locale: 'en-AU',
  //   date: new Date(2023, 4, 18),
  //   code: 'this-day-is-independent',
  //   content: [
  //     [
  //       'This day is independent.',
  //       'The most of case is self extended',
  //       'A living, happy, sunny face',
  //       'That has been guessed in quickly dance.',
  //     ],
  //     [
  //       'The dance is action of your body.',
  //       'There’s no words to drive self Goddy.',
  //       'This entity is thought the worlds.',
  //       'I don’t need the stranger’s word.',
  //     ],
  //     [
  //       'The day is real and dependent',
  //       'Of life that by yourself extended.',
  //       'The other rhyme just you create.',
  //       'The world takes place as Uni’s gate.',
  //     ],
  //   ],
  // },

  // {
  //   title: void 0,
  //   uid: uuidv4(),
  //   locale: 'ru-RU',
  //   date: new Date(2022, 9, 14),
  //   code: 'privet-privet-kak-mir-povsudu',
  //   content: [
  //     [
  //       'Привет… Привет! Как мир повсюду?',
  //       'Как образ солнечных чудес?',
  //       'Не образовываются ль те причуды,',
  //       'В тех образах внегласных стиль небес?',
  //     ],
  //     [
  //       'Как стиль сюда-то приволокся?',
  //       'Опять вопрос? Поведай мне прогресс,',
  //       'Во имя властного уюта стиля брось ка',
  //       'Добра ведь знаешь ты каких небес?',
  //     ],
  //     [
  //       'Ты вот в повтор-то почему так быстро',
  //       'В миг перешел и ляпнул что-то мило для волны',
  //       'Тех предисловий древнего же коромысла',
  //       'Что есть аналог всех нормалей полноты?',
  //     ],
  //     [
  //       'Вот, милый, ярко выражаешь',
  //       'То, что каждый как ты так обожаешь',
  //       'Все мысли в общем бытие',
  //       'Есть я и ты, то настоящее в ручье',
  //     ],
  //     [
  //       'Что так же срочно пролетает,',
  //       'Во всех же облаках восхода солнца',
  //       'Ведь срочность дерзко уважает',
  //       'Все то стремление вперед ворвется',
  //     ],
  //     [
  //       'Во все себя… Так мило стихом',
  //       'Глагольствовать о рассуждении небес…',
  //       'Вопрос понятен был, но заключение мигом',
  //       'Есть я и ты, и настоящее, прогресс.',
  //     ],
  //   ],
  // },
];