import React from 'react';
import { Box, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { theme } from 'src/styles/theme';
import { AdLaDart } from '../../ad/la-dart';

const useStyles = makeStyles({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: '40%',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: '24px',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 50px)',
    },
  },
})

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {
  open: boolean;
  handleClose: (callback: () => void) => void;
  body: React.ReactElement;
};

export const ModalContent: React.FC<Props> = ({ open, handleClose, body }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="Share Etc. poetry content"
      aria-describedby="Etc. poetry content sharing"
    >
      <Box className={classes.box}>
        <AdLaDart />
      </Box>
    </Modal>
  );
}