import React from 'react';

import { PoemProvider } from 'src/context/PoemContext';
import { ModalProvider } from 'src/context/ModalContext';

import { Header } from './header';
import { Layout } from './layout';

import { PoemSection } from '../poem';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export const Application: React.FC<Props> = () => {
  return (
    <ModalProvider>
      <PoemProvider>
        <Layout>
          <Header />
          <PoemSection />
        </Layout>
      </PoemProvider>
    </ModalProvider>
  );
}