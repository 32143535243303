import React, { ChangeEvent, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, TextField } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { eq, get, noop } from 'lodash';

import { PoemContext } from 'src/context/PoemContext';
import { EMPTY_STRING } from 'src/utils/common';
import { theme } from 'src/styles/theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    marginTop: '5px',
    paddingRight: '20px',
  },
  hidden: {
    display: 'none !important',
  },
  input: {
    color: theme.palette.primary.main,
  }
})

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

let localSearchTimeout = setTimeout(noop, 1);

export const Search: React.FC<Props> = () => {
  const classes = useStyles();

  const [localSearch, setLocalSearch] = useState<string>(EMPTY_STRING);

  const { search, setSearch, setLoading } = useContext(PoemContext);

  return (
    <div className={classes.root}>
      <TextField
        fullWidth
        label="Any..."
        variant="standard"
        value={localSearch}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setLoading(true);
          setLocalSearch(get(event, 'target.value', EMPTY_STRING)); // @note: DEFAULT_SEARCH_VALUE
          clearTimeout(localSearchTimeout);
          localSearchTimeout = setTimeout(() => {
            setSearch(localSearch);
            setLoading(false);
          }, 1000);
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              className={eq(search, EMPTY_STRING) ? classes.hidden : void 0}
              size={'small'}
              color={'primary'}
              aria-label={'clear search'}
              onClick={() => {
                setSearch(EMPTY_STRING);
                setLocalSearch(EMPTY_STRING);
              }}
            >
              <Clear fontSize={'small'} color={'primary'} />
            </IconButton>
          ),
          className: classes.input,
        }}
      />
    </div>
  );
};
